import React from 'react'
import styled from 'styled-components'
import { Col, Row, Table, Select, Button, Icon, Avatar, Tabs, Badge, Popover, Form } from 'antd'
import DateRange from '../DateRange'
import gql from '../../api/gql'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { setOrdersFilter } from '../../actions'
import Chart from 'react-google-charts'

const Container = styled.div`
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #ff9100;
  border-color: #ff9100;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked{
    color: #fff;
    background: #ff9100;
    border-color: #ff9100;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
  color: #fff;
  background: #ff9100;
  border-color: #ff9100;
}
.ant-radio-button-wrapper:hover {
  color: #ff9100;
}
.p-filter{
      display: flex;
      justify-content: flex-start;
}
`
const { Option } = Select
const _targetBlank = {
  target: '_blank',
  rel: 'noopener noreferrer'
}
class Dashboard extends React.Component {
  state = {
    loading: false,
    sites: [],
    users: [],
    selectSite: null,
    // listNews: [],
    // totalNews: 0,
    filter: {
      user_id: null
    },
    orderFilter: {
      offset: 0,
      limit: 5,
      aggs_by: 'status',
      ...this.props.filter,
    },
    dateRange: {
      from: null,
      to: null
    },
    topProduct: 'product_id',
    topProductList: [],
    topProductTypeList: [],
    orders: [],
    aggs: [],
    totalOrder: 0,
    page: 1,
    statistics: [],
    dataChart: [],
    aggFilter: 'month',
    otherProduct: 0,
  }

  onChangeSeller = async (id) => {
    this.setState({ selectSite: null })
    if (id !== "all") {
      await this.setState({
        filter: {
          ...this.state.filter,
          user_id: id
        },
      })
    } else {
      delete this.state.filter.user_id
      await this.setState({
        filter: this.state.filter,
      })
    }
    this.getSites()
    this.getOrders()
  }

  onChangeDate = async (value) => {
    const { currentUser } = this.props
    if (!currentUser) return null
    const isAdmin = currentUser.roles.find(role => role.name === "Administrator")
    const between = Math.abs(moment(value.from).diff(moment(value.to))/86400000)
    if(between<15){this.setState({aggFilter: 'day'})} else {
      if(between<61){this.setState({aggFilter: 'week'})} else this.setState({aggFilter: 'month'})}
      if(!value.from){this.setState({aggFilter: 'month'})}
      await this.setState({ dateRange: value, 
        topProductList: [], 
        topProductTypeList: [],
        statistics: [],
        dataChart: [],
      })
      this.getOrders()
      if(isAdmin) {
        this.getStatistics()
      }
  }
  onChangeSite = async (id) => {
    if (id !== 'all') {
      await this.setState({ selectSite: [id] })
    } else {
      await this.setState({ selectSite: null })
    }
    this.getOrders()
  }
  componentDidMount() {
    this.getSites()
    // this.getNews()
    this.getOrders()
    const { currentUser } = this.props
    if (!currentUser) return null
    const isAdmin = currentUser.roles.find(role => role.name === "Administrator")
    if(isAdmin) {
      this.getUsers()
      this.getStatistics()
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        page: 1,
        orderFilter: { ...this.props.filter, offset: 0, limit: 5, aggs_by: 'status' }
      }, () => this.getOrders())
    }
    if(prevProps.currentSite !== this.props.currentSite && this.props.currentUser.roles.find(role => ['Seller'].includes(role.name))){
      this.setState({
        selectSite: [this.props.currentSite.id]
      }, () => this.getOrders())
    }
  }
  getStatistics() {
    const { dataChart, aggFilter } = this.state
    let other = 0
    const query = `query adminStats($filter: StasFilter){
      adminStats(filter: $filter)
    }`
    this.setState({ loading: true })
    gql.request(query,{filter: {
      from: this.state.dateRange.from?moment.utc(this.state.dateRange.from).toISOString() : null,
      to: this.state.dateRange.to?moment.utc(this.state.dateRange.to).toISOString(): null,
      agg_by: aggFilter
    }})
      .then(res => {
        const items = res.adminStats.aggregations.period.buckets.map(data => {
          const statistics = {
            date: data.key_as_string,
            revenue: data.revenue.value,
            profit: data.profit.value,
            topProduct: data.top_products.buckets,
            topProductType: data.top_product_types.product_types.buckets
          }
          other= other + data.top_products.sum_other_doc_count
          return statistics
        })
        this.setState({
          otherProduct: other,
          statistics: items,
          loading: false
        }, () => {
          let itemChart = []
          this.state.statistics.forEach(el => {
            let item = [moment(el.date).format(aggFilter==='month'?"MM YYYY" : 'DD MM YYYY'), el.revenue, el.profit]
            itemChart.push(item)
          })
          this.setState({ dataChart: [...dataChart, ...itemChart] })
          const type = _.flattenDeep(this.state.statistics.map(el => el.topProductType))
          this.getTopProductType(type)
          let a = _.flattenDeep(this.state.statistics.map(el => el.topProduct))
          this.getTopProduct(a)
        })
      })
  }
  getSites() {
    const { currentUser } = this.props
    if (!currentUser) return null
    const seller = currentUser.roles.find(role => role.name === "Seller")
    const query = `query sites($filter: SiteFilter){
      sites(filter: $filter){
        hits{
          id
          title
        }
      }
    }`
    this.setState({ loading: true })
    gql.request(query, {
      filter:
        seller ? {
          ...this.setState.filter,
          user_id: this.props.currentUser.id
        } : { ...this.state.filter }
    }).then(res => {
      this.setState({ loading: false, sites: res.sites.hits || [] })
    })
  }
  getOrders() {
    const { filter, orderFilter, selectSite, dateRange } = this.state
    const query = `query orders($filter: OrderFilter){
      orders(filter: $filter){
        count
        hits{
          id,
          origin_id,
          price,
          quantity,
          created_at
          status
          customer{
            first_name
            last_name
            email
            phone
          }
          supplier{
            id
            last_name
            first_name
          }
          billing{
            id
            address1
            address2
            city
            state
            state_code
            zip
            country
            country_code
          }
          shipping{
            id
            address1
            address2
            city
            state
            state_code
            zip
            country
            country_code
          }
          product{
            id
            title
          }
          variant{
            id
            product_type_id
            design_status
            product_type{
              suppliers{
                id
                first_name
                last_name
              }
            }
          }
        },
        aggs{
          key
          doc_count
        }
      }
    }`
    this.setState({ loading: true })
    gql.request(query, {
      filter: {
        ...orderFilter,
        seller_ids: !filter.user_id ? null : [filter.user_id],
        site_ids: selectSite ? selectSite : this.props.currentSite ? [this.props.currentSite.id] : null,
        from: dateRange.from,
        to: dateRange.to
      }
    })
      .then(res => {
        this.setState({
          orders: res.orders.hits || [],
          aggs: res.orders.aggs || [],
          totalOrder: res.orders.count,
          loading: false
        })
      })
  }
  // getNews() {
  //   const query = `query news($filter: NewsFilter){
  //     news(filter:$filter){
  //       count
  //       hits{
  //         id
  //         title
  //         content
  //         status
  //         notification
  //         send_mail
  //         creater{
  //           id
  //           first_name
  //           last_name
  //           email
  //           address
  //           level{
  //             id
  //             name
  //             order_total
  //             discount
  //           }
  //         }
  //       }
  //     }
  //   }`
  //   this.setState({ loading: true })
  //   gql.request(query, {
  //     filter: { status: true, limit: 3, offset: this.state.listNews.length }
  //   }).then(res => this.setState({ totalNews: res.news.count, listNews: [...this.state.listNews, ...res.news.hits], loading: false })
  //   )
  // }
  getUsers() {
    const query = `query users($filter:UserFilter!){
      users(filter:$filter){
        hits{
          id
          first_name
          last_name
          roles{
            id
            name
          }
        }
      }
    }`
    this.setState({ loading: true })
    gql.request(query, {
      filter: {
        role: "Seller",
        status: true,
        limit: 10000
      }
    }).then(res => {
      this.setState({
        users: res.users.hits || [],
        loading: false
      })
    })
  }
  getTopProduct = (product) => {
    const query = `query productByID($id: Int!){
      productByID(id: $id){
        id
        origin_id
        sku
        title
        permalink
        images
        created_at
        site{
          title
        }
        variants{
          sku
          design_status
          product_type_id
          print_files{
            key
            title
            width
            height
            file_id
          }
        }
      }
    }`
    let middle = Object.keys(_.groupBy(product, 'key')).map( el => {
      if(_.groupBy(product, 'key')[el].length>1){
        let doc_count = 0
        _.groupBy(product, 'key')[el].map(e => doc_count+=e.doc_count)
        return {key: el, doc_count: doc_count}
      } else {
        return {key: el, doc_count: _.groupBy(product, 'key')[el][0].doc_count}
      }
    })
    const ids = middle.map(el => el.key)
    ids.forEach(el => {
      gql.request(query, { id: el })
        .then(res => {
          const check = middle.find(e => e.key === el)
          const top = [...this.state.topProductList, { ...res.productByID, total: check.doc_count }].sort(function(a,b){return b.total - a.total})
          
          this.setState({ topProductList: top })
        })
    })
    
  }
  getTopProductType = (type) => {
    let middle = Object.keys(_.groupBy(type, 'key')).map( el => {
      if(_.groupBy(type, 'key')[el].length>1){
        let doc_count = 0
        _.groupBy(type, 'key')[el].map(e => doc_count+=e.doc_count)
        return {key: el, doc_count: doc_count}
      } else {
        return {key: el, doc_count: _.groupBy(type, 'key')[el][0].doc_count}
      }
    })
    const ids = middle.map(el => el.key)
    const query = `query productTypeByIds($ids: [Int!]!){
      productTypeByIds(ids: $ids){
        id
        sku
        title
        images
        categories{
          id, title
        }
        attributes{
          name
        }
        suppliers{
          first_name last_name id
        }
      }
    }`
    this.setState({ loading: true })
    gql.request(query, { ids: ids })
      .then(res => {
        const top = res.productTypeByIds.map(el => {
          const check = middle.find(e => +e.key === +el.id)
          return {...el, total: check.doc_count}
        }).sort(function(a,b){return b.total-a.total}).slice(0,10)
        this.setState({ topProductTypeList: top, loading: false })
      })
  }
  // onLoadMore = () => {
  //   this.getNews()
  // }
  onChangeTop = async (e) => {
    this.setState({ topProduct: e.target.value })
  }
  render() {
    const { topProductList, topProductTypeList, orderFilter, sites, users, orders, aggs, totalOrder, page, loading, dataChart } = this.state
    const { currentUser } = this.props
    if(!currentUser) return null
    const productColumns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: 300,
        render: (title, { id, permalink, images, sku, variants }) =>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar icon="picture" src={images ? images[0] : null} shape="square" size={60} style={{ marginRight: '10px' }} />
            <div>
              {title} <a href={permalink} {..._targetBlank}><Icon type="eye"></Icon></a><br />
              {variants && <em style={{ color: '#8D8D8D' }}>{variants[0].sku}</em>}
            </div>
          </div>
      },
      {
        title: 'Created Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (created_at) => moment(created_at).format('MMM DD, YYYY'),
        width: 180,
      },
      {
        title: 'Total Order',
        dataIndex: 'total',
        key: 'total',
        width: 150,
      }
    ]
    const typeColumns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: 200,
      },
      {
        title: 'SKU',
        key: 'sku',
        dataIndex: 'sku',
        width: 100,
      },
      {
        title: 'Suppliers',
        dataIndex: 'suppliers',
        key: 'suppliers',
        width: 140,
        render: (supplier) => supplier.map(supplier => `${supplier.first_name} ${supplier.last_name}`).join(', ')
      },
      {
        title: 'Categories',
        dataIndex: 'categories',
        key: 'categories',
        width: 150,
        render: (categories) => categories.map(cat => cat.title).join(', ')
      },
      {
        title: 'Total Order',
        dataIndex: 'total',
        key: 'total',
        width: 120,
      },
    ]
    const orderColumns = [
      {
        title: 'Origin ID',
        dataIndex: 'origin_id',
        key: 'origin_id',
        width: 100,
      },
      {
        title: 'Product',
        dataIndex: 'product',
        key: 'product',
        width: 200,
        render: (p, { variant, id }) => p ? p.title : null
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (p) => `$${p}`,
        width: 100,
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'center',
        width: 100,
      },
      currentUser.roles.find(role => ["Administrator", "Supporter"].includes(role.name)) ?
        {
          title: 'Suppliers',
          key: 'suppilers',
          width: 180,
          render: (_, order) => {
            if (!order.supplier) {
              return <p>No supplier assigned</p>
            }
            else {
              return <p>{order.supplier.first_name}{' '}{order.supplier.last_name}</p>
            }
          }
        } : {},
      {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 120,
        render: (created_at) => moment(created_at).format('MMM DD, YYYY')
      },
    ]
    const orderStatus = [
      {
        key: 'pending',
        title: 'Pending',
        color: '#f94',
      },
      {
        key: 'pending_design',
        title: 'Pending Designs',
        color: '#f94',
      },
      {
        key: 'processing',
        title: 'Processing',
        color: '#f94',
      },
      {
        key: 'ready',
        title: 'Ready',
        color: 'blue',
      },
      {
        key: 'await',
        title: 'Await',
        color: 'blue',
      },
      {
        key: 'in_production',
        title: 'In Production',
        color: 'blue',
      },
      {
        key: 'fulfilled',
        title: 'Fulfilled',
        color: '#52c41a'
      },
      {
        key: 'refunded',
        title: 'Refunded',
        color: '#ff5500'
      },
      {
        key: 'cancelled',
        title: 'Cancelled',
        color: '#ff5500'
      },
      {
        key: 'error',
        title: 'Error',
        color: '#ff5500'
      },
    ]

    // const loadMore =
    //   !this.state.loading && totalNews > listNews.length ? (
    //     <div
    //       style={{
    //         textAlign: 'center',
    //         marginTop: 12,
    //         height: 32,
    //         lineHeight: '32px',
    //       }}
    //     >
    //       <Button onClick={() => this.onLoadMore()}>loading more</Button>
    //     </div>
    //   ) : null;
    const pagination = {
      pageSize: 5,
      total: totalOrder,
      current: page,
      showTotal: (totalOrder, range) => `${range} of ${totalOrder}`,
      onChange: (page, pageSize) => {
        this.setState({
          page: page,
          orderFilter: {
            ...orderFilter,
            offset: (page - 1) * pageSize
          }
        }, () => this.getOrders())
      }
    }
    let pieChart = [['Product', 'Orders']]
    let otherTotal = 0
    topProductList.forEach((el, index) => {
      if(index<10){
      let item = [el.title, el.total]
      pieChart.push(item)} else {
        otherTotal+= el.total
      }
    })
    pieChart.push(['Other Products', this.state.otherProduct+otherTotal])
    const product = _.sum(productColumns.map((c) => c.width));
    const order = _.sum(orderColumns.map((c) => c.width));
    const type = _.sum(typeColumns.map((c) => c.width));
    const isAdmin = currentUser.roles.find(role => role.name === "Administrator")
    return (
      <Container>
        <Row className="p-filter" style={{ marginBottom: "10px" }}>
          {isAdmin ? 
            <Popover
            trigger="click"
            placement="bottomLeft"
            content={
              <Form>
                <Form.Item>
                  {!currentUser.roles.map(role=>role.name).includes('Seller') &&
                    <Select optionFilterProp="children" showSearch defaultValue="all" style={{ width: 180}} onChange={this.onChangeSeller}>
                      <Option value="all">All Sellers</Option>
                      {users.map(user => (
                        <Option value={user.id} key={user.id}>{user.first_name} {user.last_name}</Option>
                      ))}
                    </Select>
                  } 
              </Form.Item>
              <Form.Item>
              <Select onChange={this.onChangeSite} showSearch value={this.state.selectSite ? this.state.selectSite : 'all'} defaultValue={"Site"} style={{ width: 180  }}>
                <Option value="all">All Sites</Option>
                {sites.map(site => (
                  <Option value={site.id} key={site.id}>{site.title}</Option>
                ))}
              </Select>
              </Form.Item>
              </Form>
            }
            >
              <Button style={{ marginRight:5}}>Filter <Icon type="filter"/></Button>
            </Popover>
            : null
          }
          <DateRange  defaultValue='all' onChange={this.onChangeDate}></DateRange>
        </Row>
        <Row  gutter={10}>
          <Col style={{msOverflowX:"scroll"}} md={14}>
          <Chart
            height='400px'
            chartType="ComboChart"
            loader={<div>Loading Chart</div>}
            data={
              dataChart.length>0? 
              [['Month', 
                `Revenue ($${dataChart.reduce((a,b) => a+b[1],0)})`, 
                `Profit ($${dataChart.reduce((a,b) => a+b[2],0)})`
              ],...dataChart]:
              [['Month', 'Revenue', 'Profit'],['0',0,0]]
            }
            options={{
              title: 'Revenue/Profit',
              vAxis: { title: 'Dollar ($)' },
              hAxis: { title: this.state.aggFilter.charAt(0).toUpperCase() + this.state.aggFilter.slice(1) },
              series: {
                0: { color: 'rgb(0, 143, 251)', curveType: 'function' },
                1: { color: 'rgb(0, 227, 150)', curveType: 'function' }
              },
              chartArea: { width: '69%', left: 100 },
            }}
            rootProps={{ 'data-testid': '1' }}
          />
          </Col>
          <Col md={10}>
          <Chart
            height={'400px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={pieChart}
            options={{
              title: 'Top 10 Best Selling Products',
              pieHole: 0.4,
            }}
            rootProps={{ 'data-testid': '3' }}/>
            </Col>
        </Row>
        <div style={{ marginTop: 20 }}>
            <Tabs
              onChange={(status) => this.props.setOrdersFilter({ ...orderFilter, status: status === 'all' ? null : [status] })}
              style={{ marginBottom: 0 }} activeKey={orderFilter.status ? orderFilter.status[0] : 'all'}>
              <Tabs.TabPane tab={<Badge count={_.sum(aggs.map(agg => agg.doc_count))} overflowCount={9999999} style={{ background: '#fff', color: '#000', border: '1px solid #f5f5f5', right: -20 }}>All</Badge>} key="all"></Tabs.TabPane>
              {orderStatus.forEach(s => { if (!s.hide) { return <Tabs.TabPane key={s.key} tab={<Badge count={_.sum(aggs.filter(agg => agg.key === s.key).map(a => a.doc_count))} overflowCount={9999999} style={{ backgroundColor: s.color }}>{s.title}</Badge>} /> } })}
            </Tabs>
            <Table rowKey={record => record.id} loading={loading} scroll={{x:order}} columns={orderColumns} dataSource={orders} pagination={pagination} />
        </div>
        <div style={{ marginTop: '20px' }}>
          <Tabs
            animated={false}
            defaultActiveKey={this.state.tab}
            onChange={(e) => {
              this.setState({ tab: e });
            }}
          >
            <Tabs.TabPane tab="Top Order Product" key="order">
              <Table rowKey={(_,index)=>index} scroll={{x:product}} columns={productColumns} dataSource={topProductList} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Top Product Type" key="product">
            <Table rowKey={(_,index)=>index} scroll={{x:type}} columns={typeColumns} dataSource={topProductTypeList} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Container>
        )
      }
    }
    export default connect(
  state => ({
    filter: state.orders.filter,
    currentSite: state.app.currentSite
  }),{setOrdersFilter}
        )(Dashboard)