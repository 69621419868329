import React from 'react'
import { CLOUDFRONT_URL } from '../config'

export default class S3Image extends React.Component {
  state = {
    src: null,
    alt: '',
  }

  render() {
    const { src, alt } = this.props
    return <img alt={alt} {...this.props} src={`${CLOUDFRONT_URL}/${src}`} />
  }
}
