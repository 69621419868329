import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { setOrdersFilter, setOrderId } from '../../actions'
import { Button, Drawer, Tabs, Badge, message, Modal,Icon,Menu, Dropdown } from 'antd'
import Table from '../Table'
import moment from 'moment'
import gql from '../../api/gql'
import ButtonGroup from 'antd/lib/button/button-group'
import CustomerInfo from '../orders/CustomerInfo'
import Comments from '../orders/Comments'
import UploadDesigns from '../products/UploadDesigns'
import _ from 'lodash'
import SupplierOrderFilter from './SupplierOrderFilter'
import SupplierOrderToolbar from '../orders/SupplierOrderToolbar'
import { ORDER_FIELD } from '../admin/Orders'
import AddTracking from '../orders/AddTracking'
import styled from 'styled-components'
import UploadCustomDesigns from '../products/UploadCustomDesigns';
import SelectAssignSupplier from '../orders/SelectAssignSupplier'


const Container = styled.div`
  .supplier-order{
    display: flex;
    justify-content: space-between;
  }
  .supplier-filter-order{
    display: flex;
    align-items: center;
    width: -webkit-fill-available;
  }
  .supplier-toolbar-order{
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 1200px) {
    .supplier-order{
      display: flex;
      flex-direction: column-reverse;
    }
  }
`

const PER_PAGE = 20
class Orders extends Component {
  state = {
    orders: [],
    aggs: [],
    selectedOrder: null,
    total: 0,
    filter: {
      ...this.props.filter,
      offset: 0,
      limit: PER_PAGE,
      aggs_by: 'status',
    },
    page: 1,
    loading: false,
    showUpdateCustomer: false,
    showOrderComments: false,
    showUploadDesign: false,
    selectCarrier: null,
    tracking_code: '',
    carrier_id: null,
    showAddTracking: false,
    addingTracking: false,
    disabledAddTracking: false
  }

  componentDidMount() {
    this.loadOrders()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        page: 1,
        filter: { ...this.props.filter, offset: 0, limit: PER_PAGE, aggs_by: 'status' }
      }, () => this.loadOrders())
    }
  }

  loadOrders() {
    const query = `query orders($filter: OrderFilter){
      orders(filter: $filter){
        count
        hits{
          ${ORDER_FIELD}
        },
        aggs{
          key
          doc_count
        }
      }
    }`
    this.setState({ loading: true })
    gql.request(query, {
      filter: this.state.filter
    }).then(res => {
      this.setState({
        orders: res.orders.hits || [],
        aggs: res.orders.aggs || [],
        total: res.orders.count,
        loading: false
      })
    })
  }

  statusFilter = (status) => {
    const { setOrdersFilter } = this.props
    setOrdersFilter({ ...this.state.filter, status: (status === 'all' ? ["ready", "in_production", "fulfilled", "refunded"] : [status]) })
  }

  pauseOrResumeOrder = (id, status) => {
    const name = status === "pending" ? 'resumeOrder' : 'pauseOrder'
    const mutation = `mutation ${name}($order_id: Int!){
      ${name}(order_id: $order_id){
        ${ORDER_FIELD}
      }
    }`
    this.setState({ loading: true })
    gql.request(mutation, { order_id: id })
      .then(res => {
        message["success"](`Order has been ${status === "pending" ? "resumed " : "paused"}`)
        const orders = this.state.orders.map(el => {
          if (el.id === id) {
            if (name === "pauseOrder") {
              return res.pauseOrder
            } else {
              return res.resumeOrder
            }
          } else return el
        })
        this.setState({ orders, loading: false })
      })
      .catch(err => {
        message["error"](_.get(err, "[0].message"))
      })
  }

  cancelOrder = id => {
    const mutation = `mutation cancelOrder($order_id: Int!){
      cancelOrder(order_id: $order_id)
    }`
    gql.request(mutation, { order_id: id })
      .then(res => {
        message["success"](`Order has been cancel`)
        this.loadOrders()
      })
      .catch(err => {
        message["error"](_.get(err, "[0].message"))
      })
  }

  assignOrderToSupplier = (id) => (value) => {
    const mutation = `mutation AssignOrderSupplier($order_id: Int!, $supplier_id: Int!){
      AssignOrderSupplier(order_id: $order_id, supplier_id: $supplier_id){
        ${ORDER_FIELD}
      }
    }`
    this.setState({ loading: true })
    gql.request(mutation, { order_id: +id, supplier_id: +value }).then((res) => {
      const orders = this.state.orders.map(el => {
        if (el.id === id) {
          return res.AssignOrderSupplier
        } else return el
      })
      message["success"]('Assign supplier successfully')
      this.setState({ loading: false, orders: orders })
    })
  }

  render() {
    const { orders, aggs, total, filter, loading, page, selectedOrder, showUpdateCustomer, showOrderComments, showUploadDesign, showAddTracking, addingTracking, disabledAddTracking } = this.state
    const columns = [
      {
        title: 'ID / Origin ID',
        dataIndex: 'id',
        width: 150,
        render: (id, order) =>
          <div>
            <p>{`N${order.id.padStart(6, "0")}`}</p>
            <p style={{ marginBottom: 0, fontStyle: 'italic' }}>{`${order.origin_id}`}</p>
          </div>
      },
      {
        title: 'Product / Variant',
        dataIndex: 'product',
        width: 350,
        key: 'product',
        render: (id, order) =>
          <div>
            {/*<p>{`${order.product.title}`}</p>*/}
            {/*<div>{`${order.product.title}`}  {order.name_product_custom != "" && <span style={{color:"#ff9101"}}> | {`${order.name_product_custom}`}</span>}</div>*/}
            { order.product_custom == true && <div> <span style={{color:"#ff9101"}}>Personalized</span> {order.name_product_custom != "" && <span> | {`${order.name_product_custom}`}</span>}</div>}
            { order.product_custom == false && <div>{`${order.product.title}`}</div>}
            <div>{order.variant.product_type_variant.attributes && order.variant.product_type_variant.attributes.map((a, index) => {
              return (
                <Fragment key={`${id}-${index}`}>
                  <p style={{ marginBottom: 0, fontStyle: 'italic' }}><b>{a.slug}</b>: {a.option} {" "}</p>
                </Fragment>
              )
            })}</div>
          </div>
      },
      {
        title: 'QTY / PRICE',
        dataIndex: 'price',
        key: 'price',
        width: 150,
        render: (id, order) =>
          <div>
            <p>{`${order.quantity} x $${order.price}`}</p>
            <p style={{ marginBottom: 0, fontStyle: 'italic' }}>{moment(order.created_at).format('MMM DD, YYYY')}</p>
          </div>
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        key: 'customer',
        width: 250,
        render: (id, order) =>
          <div>
            <p>{`${order.customer.first_name} ${order.customer.last_name}`}</p>
            <p style={{ marginBottom: 0, fontStyle: 'italic' }}>{order.customer.email}</p>
          </div>
      },
      {
        title: 'Seller',
        dataIndex: 'seller',
        key: 'site',
        width: 150,
        render: (id, order) =>
          <div>
            <p>{`${order.site && order.site.user ? order.site.user.first_name : ''} ${order.site && order.site.user ? order.site.user.last_name : ''}`}</p>
            <p style={{ marginBottom: 0, fontStyle: 'italic' }}>{order.site.title}</p>
          </div>
      },
      {
        title: 'Actions',
        width: 240,
        dataIndex: 'id',
        key: 'actions',
        align: 'center',
        render: (id, order) => <div>
          <ButtonGroup >
            <Button style={{ width: "50%" }} type="info" onClick={e => {
              e.preventDefault()
              this.setState({
                showUpdateCustomer: true,
                selectedOrder: order
              })
            }}>Info</Button>
            {order.variant.design_status ? <Button style={{ width: "50%" }} type="primary" ghost onClick={e => {
              e.preventDefault()
              this.setState({
                showUploadDesign: true,
                selectedOrder: order
              })
            }}>Design</Button> : <Button style={{ width: "50%" }} type="danger" ghost onClick={e => {
              e.preventDefault()
              this.setState({
                showUploadDesign: true,
                selectedOrder: order
              })
            }}>Upload</Button>}
          </ButtonGroup>
        </div>
      },
      {
        title: 'Tracking code',
        width: 200,
        dataIndex: 'tracking',
        align: 'center',
        fixed: 'right',
        render: (tracking, order) => {
          if (tracking) {
            let trackingUrl = order.carrier ? order.carrier.url :""
            if (trackingUrl){
              trackingUrl = trackingUrl.replace("TRACKING_NUMBER", tracking.tracking_code)
            }
            return (
              <Fragment>
                <a href = {trackingUrl} target="_blank">{tracking.tracking_code} {" "}</a>
                <span>
                  <Button type="link" style={{ padding: 0 }} onClick={e => {
                    e.preventDefault()
                    this.setState({
                      carrier_id: order.carrier ? order.carrier.id : 0,
                      tracking_code: tracking.tracking_code,
                      showAddTracking: true,
                      selectedOrder: order
                    })
                  }} >Edit</Button></span>
              </Fragment>
            )
          } else {
            return (
              <Fragment>
                {order.status === 'in_production' && <Button type="link" style={{ padding: 0 }} onClick={e => {
                  e.preventDefault()
                  this.setState({
                    showAddTracking: true,
                    selectedOrder: order
                  })
                }}>Add Tracking</Button>}
              </Fragment>
            )
          }
        }
      },
      {
        title: 'Status',
        width: 120,
        dataIndex: 'status',
        align: 'center',
        fixed: 'right',
        render: (status) => {
          const item = orderStatus.find(el => [status].includes(el.key))
          return item && <span style={{ color: `${item.color}` }}>{item.title}</span>
        }
      },
    ]
    const orderStatus =
      [
        {
          key: 'ready',
          title: 'Ready',
          color: 'blue',
        },
        {
          key: 'in_production',
          title: 'In Production',
          color: 'blue',
        },
        {
          key: 'fulfilled',
          title: 'Fulfilled',
          color: '#52c41a'
        }
      ]
    const tableWidth = _.sum(columns.map(c => c.width))
    const pagination = {
      pageSize: filter.limit,
      total: total,
      current: page,
      showTotal: (total, range) => `${range} of ${total}`,
      onChange: (page, pageSize) => {
        this.setState({
          page: page,
          filter: {
            ...filter,
            offset: (page - 1) * pageSize
          }
        }, () => this.loadOrders())
      }
    }
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.props.setOrderId(selectedRowKeys)
      },
    }
    return (
      <Container>
        <div className="supplier-order" >
          <div className="supplier-filter-order">
            <SupplierOrderFilter {...this.props} />
          </div>
          <div className="supplier-toolbar-order">
            <SupplierOrderToolbar onChange={() => this.loadOrders()} onDownload={() => { this.loadOrders() }} />
          </div>
        </div>
        <Tabs onChange={this.statusFilter} style={{ marginBottom: 0 }} activeKey={filter.status && filter.status.length > 1 ? "all" : filter.status[0]}>
          <Tabs.TabPane tab={<Badge overflowCount={9999999} count={_.sum(aggs.filter(agg => ["ready", "in_production", "fulfilled", "refunded"].includes(agg.key)).map(el => el.doc_count))} style={{ background: '#fff', color: '#000', border: '1px solid #f5f5f5', right: -20 }}>All</Badge>} key="all"></Tabs.TabPane>
          {orderStatus.map(s => { if (!s.hide) { return <Tabs.TabPane key={s.key} tab={<Badge overflowCount={9999999} count={_.sum(aggs.filter(agg => agg.key === s.key).map(a => a.doc_count))} style={{ backgroundColor: s.color }}>{s.title}</Badge>} /> } })}
        </Tabs>
        <Table scroll={{ x: tableWidth }} rowSelection={rowSelection} columns={columns} dataSource={orders} pagination={pagination} rowKey={({ id }) => id} loading={loading} />
        <Drawer
          title={`Update customer info #${selectedOrder ? selectedOrder.origin_id : ''}`}
          onClose={() => this.setState({
            showUpdateCustomer: false,
            selectedOrder: null
          })}
          width="500px"
          visible={showUpdateCustomer}>
          {selectedOrder && <CustomerInfo onClose={() => this.setState({
            showUpdateCustomer: false,
            selectedOrder: null
          })} onUpdate={() => this.setState({ showUpdateCustomer: false, selectedOrder: null }, () => this.loadOrders())} order={selectedOrder} />}
        </Drawer>
        <Drawer
          title={`Comments #${selectedOrder ? selectedOrder.origin_id : ''}`}
          onClose={() => this.setState({
            showOrderComments: false,
            selectedOrder: null
          })}
          width="500px"
          visible={showOrderComments}>
          {selectedOrder && <Comments order_id={selectedOrder.id} />}
        </Drawer>
        <Drawer
          width="800px"
          title="Upload Designs"
          onClose={e => this.setState({ selectedOrder: null, showUploadDesign: false })}
          visible={showUploadDesign}
        >
          {selectedOrder && selectedOrder.product.is_custom_design !== true && <UploadDesigns
            product_id={selectedOrder.product.id}
            order={selectedOrder}
            variant_type_id={selectedOrder.variant.product_type_variant.id}
            onClose={e => this.setState({ selectedOrder: null, showUploadDesign: false }, () => this.loadOrders())} />}
          {selectedOrder && selectedOrder.product.is_custom_design === true && <UploadCustomDesigns
            product_id={selectedOrder.product.id}
            variant_type_id={selectedOrder.variant.product_type_variant.id}
            order={selectedOrder}
            onClose={e => this.setState({ selectedOrder: null, showUploadDesign: false }, () => this.loadOrders())} />}
          {/* {selectedOrder && <UploadDesigns
            product_id={selectedOrder.product.id}
            product_type_id={selectedOrder.variant.product_type_id}
            onClose={e => this.setState({ selectedOrder: null, showUploadDesign: false })} />} */}
        </Drawer>
        {/**Add/Edit tracking code*/}
        <Modal
          title="Add/Edit tracking code"
          visible={showAddTracking}
          destroyOnClose={true}
          okButtonProps={{ form: 'add-tracking', key: 'submit', htmlType: 'submit', disabled: disabledAddTracking }}
          onCancel={e => this.setState({ showAddTracking: false })}
          confirmLoading={addingTracking}
          onOk={e => this.setState({ addingTracking: true })}
        >
          {
            selectedOrder && <AddTracking onChangeDisabledAddTracking={(v) => this.setState({disabledAddTracking: v})} onUnLoading={() => this.setState({addingTracking: false})} order={selectedOrder} onClose={() => this.setState({ showAddTracking: false, addingTracking: false }, () => this.loadOrders())} />
          }
        </Modal>
      </Container>
    )
  }
}

export default connect(
  state => ({
    filter: state.orders.filter
  }), { setOrdersFilter, setOrderId }
)(Orders)