import React from 'react'
import { Row, Form, Col, Input, Card, Button, notification, Skeleton, Divider, InputNumber, Radio } from 'antd'
import Wysiwyg from '../../components/wysiwyg'
import ProductTypeAttributes from '../../components/admin/ProductTypeAttributes'
import gql from '../../api/gql'
import _ from 'lodash'
import { history } from '../../history'
import CategoriesSelect from '../../components/product_type/CategoriesSelect'
import Images from '../../components/product_type/Images'
import ItemProduct from './itemProduct'
import SupplierSelect from '../../components/supplier/SupplierSelect'
import { emitter } from '../../routes'
import CarrierSelect from '../../components/carrier/CarrierSelect'
import styled from 'styled-components'

const CarrierFormItem = styled.div`
.ant-form-item-control{
  line-height: 0px;
}
`


class ProductTypeForm extends React.Component {
  state = {
    productType: null,
    attributes: [],
    loading: false,
    showModal: false,
    items: [],
    onShow: false,
    data: "",
    visible: false,
    carrier: [],
    defaultCarrier: null,
  }

  onFocus = () => {
    this.setState({ onShow: true })
  }

  addItem = () => {
    const { items } = this.state;
    this.setState({
      items: [...items, this.state.data],
      data: ""
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    const { id } = this.props.match.params
    if (id) {
      const query = `query productTypeById{productTypeById(id: ${id}){
        id, title, description, sku, mockup
        categories{
          id
        }
        attributes{
          name, slug, options
        }
        print_files{
          key, title, mockup, width, height, note, variant_ids
        }
        product_type_carriers{
          carrier_id
          price
          default
          carrier{
            name
          }
        }
        suppliers{
          id
        }
        images
      }
    }`
      gql.request(query).then(res => {
        const initCarrier = res.productTypeById.product_type_carriers.map(el => {
          return{value: el.carrier_id, children: el.carrier.name, default: el.default, price: el.price}
        })
        const initDefault = res.productTypeById.product_type_carriers.find(el => el.default)
        this.setState({
          productType: res.productTypeById || {},
          attributes: res.productTypeById.attributes || [],
          carrier: initCarrier || [],
          defaultCarrier: (initDefault && initDefault.carrier_id) || null
        })
      })
    }


    this.subcriber = emitter.addListener('saveProductType', () => {
      this.saveProductType()
    })
  }

  componentWillUnmount() {
    if (this.subcriber) {
      this.subcriber.remove()
    }
  }

  saveProductType = e => {
    if (e)
      e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const carrier = []
        values.product_type_carrriers.forEach( el => {
          let car = {
            carrier_id: el,
            price: values.shippingcost[el],
            default: el === this.state.defaultCarrier
          }
          carrier.push(car)
        })
        values.product_type_carrriers = carrier
        delete values.shippingcost
        const { id } = this.props.match.params
        var query = `mutation createProductType($input: NewProductType!){
          createProductType(input: $input){
            id
          }
        }`
        if (id) {
          query = `mutation updateProductType($input: NewProductType!){
            updateProductType(id: ${id}, input: $input){
              id
            }
          }`
        }
        this.setState({ loading: true })
        gql.request(query, {
          input: values
        }).then(res => {
          notification['success']({
            message: 'Product type has been ' + (id ? 'updated' : 'created')
          })
          this.setState({ loading: false })
          history.goBack()
        }).catch(err => {
          notification['error']({
            message: 'ERROR',
            description: _.get(err, '[0].message')
          })
          this.setState({ loading: false })
        })
      }
    })
  }
  showModal = (action) => {
    this.setState({
      showModal: action,
    });
  };

  onAddAttr = (formData) => {
    const { attributes } = this.state
    this.setState({
      attributes: [...attributes, formData]
    })
  }

  onShow = (name, slug) => this.setState({ showModal: true, name, slug });

  render() {
    const { productType, attributes, loading } = this.state
    const { getFieldDecorator } = this.props.form
    const { id } = this.props.match.params
    if (id && !productType) return <Skeleton />
    return (
      <Form onSubmit={this.saveProductType}>
        <Row gutter={20}>
          <Col span={24} lg={16}>
            <Card title="Detail" style={{ marginBottom: 20 }}>
              <Form.Item label="Title">
                {getFieldDecorator('title', {
                  rules: [{ required: true, message: "Please input title", }],
                  initialValue: productType ? productType.title : ''
                })(
                  <Input />
                )}
              </Form.Item>
              <Form.Item label="SKU">
                {getFieldDecorator('sku', {
                  rules: [{ required: true, message: "Please input sku" }],
                  initialValue: productType ? productType.sku : ''
                })(
                  <Input />
                )}
              </Form.Item>
              <Form.Item label="Mockup Link">
                {getFieldDecorator('mockup', {
                  //rules: [{ required: true, message: "Please input sku" }],
                  initialValue: productType ? productType.mockup : ''
                })(
                  <Input />
                )}
              </Form.Item>
              <Form.Item label="Description">
                {getFieldDecorator('description', {
                  initialValue: productType ? productType.description : ''
                })(
                  <Wysiwyg />
                )}
              </Form.Item>
            </Card>
            <Card title="Attributes" style={{ marginBottom: 20 }}>
              <Form.Item>
                {getFieldDecorator('attributes', {
                  initialValue: attributes
                })(<ProductTypeAttributes values={this.state.attributes} />)}
              </Form.Item>

              <ItemProduct onSubmit={this.onAddAttr} onCancel={() => this.showModal(false)} showModal={this.state.showModal} />
            </Card>
            {/* <Card title="Print Files" style={{ marginBottom: 20 }}>
              <Form.Item>
                {getFieldDecorator('print_files', {
                  initialValue: productType ? (productType.print_files || []) : []
                })(<ProductTypePrintFiles />)}
              </Form.Item>
            </Card> */}
          </Col>
          <Col span={24} lg={8}>
            <Card title="Suppliers" style={{ marginBottom: 20 }}>
              <Form.Item>
                {getFieldDecorator('suppliers', {
                  roles: [{ required: true }],
                  initialValue: productType ? productType.suppliers.map(sup => sup.id) : []
                })(
                  <SupplierSelect />
                )}
              </Form.Item>
            </Card>
            <Card title="Shipping Carries" style={{ marginBottom: 20}}>
              <Form.Item>
                {getFieldDecorator('product_type_carrriers', {
                  roles:[{required: true}],
                  initialValue: productType? productType.product_type_carriers.map(car => car.carrier_id ): []
                })(
                  <CarrierSelect onChange={(_,option) => {
                    let carrier = option.map(el => el.props)
                    let a = carrier.map(el => {
                      let item = this.state.carrier.find(e => e.value === el.value)
                      if(item){
                        return item
                      } else { return el}
                    })
                    let defaultItem = a.find(el => el.default) 
                    this.setState({carrier: a, defaultCarrier: defaultItem && defaultItem !== undefined ? defaultItem.value : ( a.length > 0 && a[0].value)})
                  }}/>
                )}
              </Form.Item> 
              <Radio.Group style={{width: '100%'}} value={this.state.defaultCarrier? this.state.defaultCarrier: 0} onChange={(e) => this.setState({defaultCarrier: e.target.value})}>
              {this.state.carrier.map((el,index) =>
              <Radio key={index} value={el.value} style={{display: 'block',height: '30px',lineHeight: '30px',marginBottom: 5}}>
                <div style={{display: 'inline-block', width: '90%'}}>
                <Row gutter={16}>
                  <Col span={16}>
                  {el.children}:
                  </Col>
                  <Col style={{textAlign: 'right', paddingRight: 0}}>
                  <CarrierFormItem>
                  <Form.Item>
                    {getFieldDecorator(`shippingcost[${el.value}]`,{
                      initialValue: this.state.carrier&&this.state.carrier[index].price? this.state.carrier[index].price :0
                    })(
                  <InputNumber 
                  min = {0}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}/>)}
                  </Form.Item>
                  </CarrierFormItem>
                  </Col>
                </Row>
                </div>
                </Radio>)}
                </Radio.Group>
            </Card>
            <Card title="Categories" style={{ marginBottom: 20 }}>
              <Form.Item>
                {getFieldDecorator('categories', {
                  initialValue: productType ? productType.categories.map(cat => cat.id) : []
                })(
                  <CategoriesSelect />
                )}
              </Form.Item>
            </Card>
            <Card title="Images">
              <Form.Item>
                {getFieldDecorator('images', {
                  initialValue: productType ? productType.images : []
                })(<Images />)}
              </Form.Item>
            </Card>
          </Col>

          <Col span={24}>
            <Button type="primary" loading={loading} icon="save" htmlType="submit">Save</Button>
            <Divider type="vertical" />
            <Button icon="undo" onClick={() => history.goBack()}>Cancel</Button>
          </Col>
        </Row>
      </Form>
    )
  }
}


export default Form.create({ name: 'product-type-form' })(ProductTypeForm)