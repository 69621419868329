import React from 'react'
import { connect } from 'react-redux'
import { Select, message, Button, Input, Popover, Icon, Form } from 'antd'
import gql from '../../api/gql'
import _ from 'lodash'
import styled from 'styled-components'

const Container = styled.div`
  width:100%;
`
class ProductsFilter extends React.Component {
  constructor(props) {
    super(props)
    var state = {
      fetching: false,
      sites: [],
      productTypes: [],
      filter: {
        search: "",
        site_ids: null,
        product_type_ids: null,
      },
      productTypeFilter: {
        status: true,
        limit:1000
      },
    }
    const { currentUser, currentSite } = props
    if (currentUser.roles.map(role => role.name).includes('Seller')) {
      state.filter.site_ids = currentSite? [currentSite.id] : []
    }
    this.state = state
  }

  componentDidMount() {
    this.fetchSites()
    this.loadProductType()
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentSite !== prevProps.currentSite) {
      this.setState({
        filter: { ...this.state.filter, site_ids: [this.props.currentSite.id] }
      }, () => this.applyFilter())
    }
  }

  loadProductType() {
    const query = `query productTypes($filter: ProductTypeFilter){
      productTypes(filter: $filter){
        hits{
          id 
          title
        }
      }
    }`
    gql.request(query, { filter: this.state.productTypeFilter }).then(res => {
      this.setState({ productTypes: res.productTypes.hits || [] })
    })
      .catch(err => {
        message["error"](_.get(err, "[0].message"));
      })
  }

  fetchSites() {
    const query = `query sites{sites{hits{id,title}}}`
    gql.request(query).then(res => {
      this.setState({ sites: res.sites.hits || [] })
    }).catch(err => {
      message["error"](_.get(err, "[0].message"));
    })
  }

  applyFilter = () => {
    const { onChange } = this.props
    if (typeof onChange === 'function') {
      onChange(this.state.filter)
    }
  }

  handleChange(key, value) {
    if (_.isArray(value)) {
      value = value.filter(item => item !== null)
    }
    const { filter } = this.state
    filter[key] = value
    this.setState({ filter: { ...filter } })
    this.applyFilter()
  }
  resetFilter=()=>{
    const {onChange} = this.props;
    onChange({
        search: "",
        site_ids: null,
        product_type_ids: null,
    })
  }
  render() {
    const { sites, productTypes, filter } = this.state
    const { currentUser } = this.props
    if (!currentUser) return null
    return (
      <Container>
          <Input.Search
          style={{marginBottom: 10 }}
          placeholder="Search..." 
          onChange={e => this.handleChange('search', e.target.value)}
          addonBefore={
            <Popover
            trigger="click"
            placement="bottomLeft"
            content={
              <Form>
                <Form.Item>
                {!currentUser.roles.map(role => role.name).includes('Seller') &&
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Site"
                    defaultValue={filter.site_ids ? filter.site_ids :null}
                    onChange={v => {this.handleChange('site_ids', [v]); this.applyFilter()}}
                    style={{ width: 250, marginRight: 10, marginBottom: 10 }}>
                    <Select.Option value={null}>All Sites</Select.Option>
                    {sites.map(s => <Select.Option key={s.id} value={s.id}>{s.title}</Select.Option>)}
                  </Select>
                }
                </Form.Item>
                <Form.Item>
                <Select
                  showSearch
                  optionFilterProp="children"
                  defaultValue={filter.product_type_ids ?filter.product_type_ids : null}
                  placeholder="Product types" style={{ width: 250 }} onChange={v => this.handleChange('product_type_ids', [v])}>
                  <Select.Option value={null}>All Product Types</Select.Option>
                  {productTypes.map(type =>
                    <Select.Option key={type.id} value={type.id}>{type.title}</Select.Option>)
                  }
                </Select>
                </Form.Item>
                <Form.Item>
                <Button onClick={v=>{v.preventDefault() ;
                  this.resetFilter()}}>
                    Reset Filter
                </Button>
                </Form.Item>
              </Form>
            }>
              Filter <Icon type="filter"/>
            </Popover>
          }
          />
      </Container>
    )
  }
}

export default connect(
  state => ({
    currentSite: state.app.currentSite,
    currentUser: state.app.currentUser
  })
)(ProductsFilter)