import React from 'react'
import { Select, Row, Col, Form } from 'antd'

class ProductTypeAttributeSelector extends React.Component {
  state = {
    variant: [],
    selectedProductTypeVariant: []
  }
  onSubmit = e => {
    e.preventDefault()
    const { productType } = this.props
    const attributes = productType.attributes
    const productTypeVariants = productType.product_type_variants
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const attrs = Object.keys(values).map((el, i)=>{
          let item = {
            name: attributes[i].name, 
            slug: attributes[i].slug, 
            option: values[el]
          }
          return item
        })
        const selectedVariant = productTypeVariants.filter((v)=> v.product_type_id === productType.id && JSON.stringify(v.attributes) === JSON.stringify(attrs))
        if(selectedVariant && selectedVariant.length>0){
          this.props.onSubmit(selectedVariant[0].id)
        }
      }
    })
  }
  render() {
    const { productType } = this.props
    const attributes = productType.attributes
    console.log("productType", productType)
    const { getFieldDecorator } = this.props.form
    return (
      <div className="attribute">
        <Form id="attribute-variant" onSubmit={this.onSubmit}>
          {attributes && attributes.map((attr, i) => {
            return (
              <Row gutter={24} style={{ marginBottom: 15 }} key={i}>
                <Col className="gutter-row" span={8} style={{ textAlign: "right" }}>
                  <div style={{ lineHeight: "32px" }}>{attr.name}</div>
                </Col>
                <Col className="gutter-row" span={16}>
                  <Form.Item>
                    {getFieldDecorator(`${attr.slug}_option`, {
                      rules: [{
                        required: true,
                        message: `Please select ${attr.slug}`
                      }]
                    })(
                      <Select style={{ width: 200 }} placeholder={`Please select ${attr.slug}`}>
                        {
                          attr.options && attr.options.map((option, index) => (
                            <Select.Option key={index} value={option}>{option}</Select.Option>
                          ))
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )
          })}
        </Form>
      </div>
    );
  }
}

export default Form.create({ name: "attribute-variant" })(ProductTypeAttributeSelector);