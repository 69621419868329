import React, { Component } from 'react';
import { Form, notification, Input, Skeleton, DatePicker, Button, Checkbox, Card } from 'antd';
import gql from '../../api/gql';
import { history } from '../../history'
import _ from 'lodash'
import moment from 'moment'

class UserDetail extends Component {
  state = {
    users: null,
    loading: false,
    roles: []
  }

  componentDidMount() {
    this.getIdUser();
  }
  getIdUser() {
    this.setState({ loading: false })
    const { id } = this.props
    if (id) {
      const query = `query userById{userById(id:${id}){
            id 
            last_name
            first_name
            email
            pass
            phone
            birthday
            address
            roles{
              id
              name
            }
            avatar{
              id
              url
            }
            is_subscription
          }
      }`;
      this.setState({ loading: true })
      gql.request(query, {
      }).then(res => {
        this.setState({ users: res.userById || {} });
      })
      const query_role = `query roles{roles{id name}}`

      gql.request(query_role).then((res) => {
        this.setState({
          // roles: res.roles
          roles: res.roles ?
            res.roles.filter((r) => r.name !== 'Authenticated') :
            [],
        })
      })
    }
  }
  onSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {

      if (!err) {
        values.birthday = values.birthday && moment(values.birthday).format()
        const mutation = `mutation updateUser($id: ID! $input: UpdateUser!){
            updateUser(input: $input, id: $id){
              id
              first_name
              last_name
              email
            }
          }`
        this.setState({ loading: true })
        gql.request(mutation, {
          input: values,
          id: this.props.id
        }).then(res => {
          notification['success']({
            message: 'User has been updated'
          })
          this.setState({ loading: false })
          history.push('/admin/users-manager')
        }).catch(err => {
          notification['error']({
            message: _.get(err, '[0].message')
          })
          this.setState({ loading: false })
        })
      }
    })
  }
  render() {
    const { id, form} = this.props
    const { users } = this.state
    const { getFieldDecorator } = form
    if (id && !users) return <Skeleton />
    return (
      <Form onSubmit={this.onSubmit}>
        <Card title="Detail" style={{ marginBottom: 20}}>
          <Form.Item label="First name">
            {
              getFieldDecorator('first_name', {
                initialValue: users.first_name,
                rules: [{
                  required: true,
                  message: "Please input first name"
                }]
              })(<Input />)
            }
          </Form.Item>
          <Form.Item label="Last name">
            {
              getFieldDecorator('last_name', {
                initialValue: users.last_name,
                rules: [{
                  required: true,
                  message: "Please input last name"
                }]
              })(<Input />)
            }
          </Form.Item>
          <Form.Item label="Email">
            {
              getFieldDecorator('email', {
                initialValue: users.email,
                rules: [{
                  required: true,
                  message: "Please input email"
                }]
              })(<Input />)
            }
          </Form.Item>
          <Form.Item label="Birthday">
            {getFieldDecorator('birthday', {
              initialValue: users.birthday && moment(users.birthday)
            })(
              (<DatePicker style={{ width: 300 }} />)
            )}
          </Form.Item>
          <Form.Item label="Address">
            {getFieldDecorator('address', {
              initialValue: users.address,
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item label="Phone">
            {getFieldDecorator('phone', {
              initialValue: users.phone,
              rules: [{
                required: true,
                message: "Please input phone"
              }]
            })(
              <Input />
            )}
          </Form.Item>
          <Form.Item label="Subscription">
            {getFieldDecorator('is_subscription', {
              valuePropName: 'checked',
              initialValue: users.is_subscription
            })(
              <Checkbox>Receive updates about new products and email marketing</Checkbox>
            )}
          </Form.Item>
          <Form.Item label="Roles">
            {
              getFieldDecorator('role', {
                rules: [{ required: true, message: "Please choose user roles" }],
                initialValue: users.roles.map(role => role.name)
              })(<Checkbox.Group
                options={this.state.roles.filter(r => r.id).map(role => { return { label: role.name, value: role.name } })} />)
            }
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" >Save</Button>{"  "}
            <Button onClick={() => { history.push('/admin/users-manager') }} >Cancel</Button>
          </Form.Item>

        </Card>
      </Form>
    );
  }
}

export default Form.create({ name: 'form' })(UserDetail);