import React from 'react'
import styled from 'styled-components'
import { Row, Col, Icon, Progress } from 'antd'
import Upload from './Upload'
import _ from 'lodash'

const Container = styled.div`
  .ant-col > div{
    position: relative;
    height: 0;
    padding-bottom: 100%;
    >div{
      position: absolute;
      width: 100%;
      border: ${props => props.selected ? '2px dashed blue' : '1px solid #f5f5f5'};
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      z-index:1;
      &.image-tools{
        z-index:2;
        opacity: 0;
        background-color: rgba(0,0,0,0.25);
        color: #fff;
        &:hover{
          opacity: 1;
        }
      }
    }
  }
  img{
    max-width: 100%;
	}
.ant-row-flex > div {
	overflow: hidden;
}
`

export default class UploadImages extends React.Component{
  state = {
    loading: false,
    percent: 0,
    value: this.props.value || [],
    seletedImage: null,
  }

  componentDidUpdate(prevProps){
    if (prevProps.value !== this.props.value){
      this.setState({value: this.props.value})
    }
  }

  onProcess = e => {
    this.setState({
      loading: true,
      percent: Math.round(e.loaded/e.total * 100)
    })
  }

  onUpload = image => {
    const {value} = this.state
    value.push(image)
    this.setState({value, loading: false}, this.handleOnChange)
  }

  handleOnChange = () => {
    const {onChange} = this.props
    if (typeof onChange === 'function'){
      onChange(this.state.value)
    }
  }

  render () {
    const {loading, percent, value, seletedImage} = this.state
    const {selectMode} = this.props || false
    return (
      <Container>
        <Row gutter={5} type="flex">
          {value.map((image, index) => <Col span={12} sm={8} md={6} key={(image.key || image.url)}>
            <div onClick={e => {
              if (!selectMode) {
                return true
              }
              e.preventDefault()
              this.setState({seletedImage: image}, () => {
                const {onSelect} = this.props
                if (typeof onSelect === 'function') {
                  onSelect(this.state.seletedImage)
                }
              })
              }} 
              style={{border: seletedImage === image ? '2px dashed blue' : '1px solid #f5f5f5'}}>
              <div>
                <img src={image.url} alt=""/>
              </div>
              {!selectMode && <div className="image-tools">
                <Icon type="delete" style={{fontSize:16, color: "red"}} onClick={(e) => {
                  e.preventDefault()
                  const {value} = this.state
                  value.splice(index, 1)
                  this.setState({value}, this.handleOnChange)
                }}/>
              </div>}
            </div>
          </Col>)}
          <Col span={12} sm={8} md={6}>
            <div>
              <div>
                <Upload onUpload={this.onUpload} onProcess={this.onProcess} fileList={false} disabled={loading} {..._.pickBy(this.props, (value, key) => ['multiple', 'accept', 'fileList'].indexOf(key) !== -1)}>
									{loading ? <Progress type="circle" percent={percent} width={40} /> : <Icon type="plus" style={{fontSize:18, cursor:'pointer', padding: '100px'}}/>}
									<div className="drag-photo"></div>
                </Upload>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}