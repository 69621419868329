import React, {Component} from 'react'
import UsersList from '../../components/admin/UsersList'

class UsersManagerPage extends Component{
  render() {
    return (
      <div>
        <UsersList currentUser={this.props.currentUser}/>
      </div>
    )
  }
}

export default UsersManagerPage