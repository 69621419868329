import React, { Component } from 'react'
import styled from 'styled-components'
import { Table, Button, Icon, Drawer, message, Select, Col, Row, Divider, Popconfirm } from 'antd'
import AddCarrier from './AddCarrier'
import gql from '../../api/gql'
import _ from 'lodash'
import UpdateCarrier from './UpdateCarrier'
import {connect} from 'react-redux'

const Container = styled.div``

const { Option } = Select

class ListCarrier extends Component {
  state = {
    carriers: [],
    createCarriers: false,
    showUpdate: false,
    loading: false,
    status: "active",
    filter: {
      status: true
    }
  }

  componentDidMount() {
    this.getCarriers()
  }

  onhandleSelect = async (value) => {
    await this.setState({
      status: value,
      filter: {
        ...this.state.filter,
        status: value === "active" ? true : false
      }
    })
    this.getCarriers()
  }
  getCarriers() {
    const query = `query carriers($filter: CarrierFilter){
      carriers(filter: $filter){
        id
        name
        code
        description
        url
      }
    } `
    this.setState({ loading: true })
    gql.request(query, {
      filter: this.state.filter
    }).then(res => {
      this.setState({
        carriers: res.carriers || [],
        loading: false
      })
    })
  }
  deleteCarrier = id => {
    const mutation = `mutation updateCarrier($id: Int!, $input:EditCarrier!){
      updateCarrier(id: $id, input:$input){
        name
        code
        url
        description
      }
    }`
    this.setState({ loading: true })
    gql.request(mutation, {
      id: parseInt(id),
      input: { status: false }
    }).then(res => {
      this.setState({ loading: false })
      if (res.updateCarrier) {
        message["success"]("Delete success");
        this.getCarriers();
      } else {
        message["error"](_.get(res.errors, "[0].message"))
      }
    }).catch(err => {
      this.setState({ loading: false })
      message["error"](_.get(err, "[0].message"))
    })
  }
  render() {
    const { carriers } = this.state
    const isAdmin = this.props.currentUser.roles.find(el => ['Administrator', 'Listing'].includes(el.name))
    const columns = [
      {
        title: "Name",
        key: "name",
        width: 100,
        dataIndex: "name"
      },
      {
        title: "Code",
        key: "code",
        width: 100,
        dataIndex: "code"
      },
      {
        title: "Url",
        key: "url",
        width: 200,
        dataIndex: "url",
        render: url => <a target="_blank" rel="noopener noreferrer" href={url}>{url}</a>
      },
      {
        title: "Description",
        key: "secription",
        width: 150,
        dataIndex: "description"
      },
      isAdmin?
      {
        title: "Action",
        width: 120,
        align: "right",
        render: (text, record) => (
          <Container>
            <Button size="small" onClick={() => { this.setState({ showUpdate: true, updateCarrier: record }) }}><Icon type="edit" theme="twoTone" /></Button>
            <Divider type="vertical"></Divider>
            <Popconfirm
              placement="top"
              title="Are you sure to delete this carrier?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.deleteCarrier(record.id)} >
              <Button size="small"><Icon type="delete" style={{color:"red"}}/></Button>
            </Popconfirm>
          </Container>
        )
      }: {}
    ]
    const tableWidth = _.sum(columns.map(c => c.width))
    return (
      <div>
        <Row>
          <Col span={12} >
            <Select defaultValue="active" style={{ width: "180px" }} onChange={this.onhandleSelect}>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
            {/* <Search placeholder="Search"
              onSearch={value => console.log(value)}
              style={{ width: 300, marginLeft: "10px" }}></Search> */}
          </Col>
          <Col span={12} style={{ textAlign: "right", marginBottom: "15px" }}>
            {isAdmin&&(<Button type="primary" onClick={() => { this.setState({ createCarriers: true }) }} ><Icon type="plus" />New Carrier</Button>)}
          </Col>
        </Row>
        <Table columns={columns} dataSource={carriers} rowKey={(text, index) => index} scroll={{ x: tableWidth }}/>
        <Drawer
          visible={this.state.createCarriers}
          placement="right"
          title="New Carrier"
          width="500"
          onClose={() => { this.setState({ createCarriers: false }) }}>
          <AddCarrier onClose={() => { this.setState({ createCarriers: false }) }} onLoad={() => this.getCarriers()}></AddCarrier>
        </Drawer>
        <Drawer
          visible={this.state.showUpdate}
          width="500"
          placement="right"
          title="Update Carrier"
          onClose={() => { this.setState({ showUpdate: false }) }}>
          <UpdateCarrier carriers={this.state.updateCarrier} onLoad={() => this.getCarriers()} onClose={() => { this.setState({ showUpdate: false }) }}></UpdateCarrier>
        </Drawer>
      </div>
    );
  }
}

export default connect(
  state => ({
    currentUser: state.app.currentUser
  })
)(ListCarrier);