import React from 'react'
import ListNews from '../../components/news/ListNews'

 class NewsPage extends React.Component{
  state = {
    filter: null
  }
  render() {
    return (
      <div>
        <ListNews filter={this.state.filter}/>
      </div>
    )
  }
}
export default NewsPage

