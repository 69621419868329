import React from 'react'
import {connect} from 'react-redux'
import { history } from '../../history'
import { Button } from 'antd'

class CategoryToolbar extends React.Component{
  render(){
    const isAdmin = this.props.currentUser.roles.find(el => ['Administrator','Listing'].includes(el.name))
    return(
      <>
      {isAdmin&&(
      <Button onClick={() => history.push('/admin/category/add')} icon="plus" type="primary">Add New</Button>
      )}
      </>
    )
  }
}

export default connect(
  state => ({
    currentUser: state.app.currentUser
  })
)(CategoryToolbar)