import React, { Component } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

class DesignPositionVariantsSelect extends Component {
  keyFromAttributes = (attributes) => {
    let keys = [];
    if (attributes && attributes.length) {
      for (let i = 0; i < attributes.length; i++) {
        keys.push(attributes[i].option);
      }
    }
    return keys.join("/");
  };
  render() {
    const { value, variants } = this.props;
    let options = ["*"];
    let defaultValue = ["*"];
    let mapVariantsByKey = {};
    if (value && value.length) {
      defaultValue = [];
      for (let i = 0; i < value.length; i++) {
        defaultValue.push(this.keyFromAttributes(value[i].attributes));
      }
    }
    if (variants && variants.length) {
      for (let i = 0; i < variants.length; i++) {
        const key = this.keyFromAttributes(variants[i].attributes);
        options.push(key)
;
        mapVariantsByKey[key] = variants[i];
      }
    }
    return (
      <div>
        <Select
          onChange={(v) => {
            if (v.length === 0) {
              this.props.onChange([]);
            }
            if (v && v.length === 1 && v[0] === "*") {
              this.props.onChange([]);
              return;
            }
            let changeValues = [];
            for (let i = 0; i < v.length; i++) {
              if (v[i] === "*") {
                continue;
              }
              const variantFromMap = mapVariantsByKey[v[i]];
              changeValues.push(variantFromMap);
            }
            this.props.onChange(changeValues);
          }}
          mode={"tags"}
          value={defaultValue}
        >
          {options.map((option, index) => (
            <Select.Option key={index} value={option}>
              {option === "*" ? "All variants" : option}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  }
}

DesignPositionVariantsSelect.propTypes = {
  variants: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default DesignPositionVariantsSelect;
