import React from 'react'
import {connect} from 'react-redux'
import {initApp, setCurrentUser, setDestination} from '../../actions'
import styled from 'styled-components'
import {Link, Redirect} from 'react-router-dom'
import { Form, Input, Button, notification } from 'antd'
import gql from '../../api/gql'
import _ from 'lodash'

const Container = styled.div`
  margin: auto;
  text-align: center;
  padding: 25px;
  .login-form{
    .ant-row{
      margin-bottom: 10px;
      .ant-form-item-label{
        line-height: 25px;
      }
      label{
        font-weight: 500;
        padding-right: 15px;
        &:before{
          position: absolute;
          right: 0;
          top: 5px;
        }
        &:after{
          display: none;
        }
      }
    }
  }

`
class PageLogin extends React.Component{
  state = {
    loading: false,
  }

  handleLogin = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({loading: true})
        gql.login(values.email, values.password).then(user => {
          const {destination, history, initApp, setCurrentUser, setDestination} = this.props
          const isSupporter = user&&user.roles.find(role => ['Supporter'].includes(role.name))
          const isListing = user&&user.roles.find(role => ['Listing'].includes(role.name))
          const isAccounting = user&&user.roles.find(role => ['Accounting'].includes(role.name))
          this.setState({loading: false})
          initApp()
          setCurrentUser(user)
          setDestination(null)
          if (destination) {
            history.push(destination.path)
          }else{
            history.push(isSupporter? '/orders  ': isListing? '/products' : isAccounting? '/balance' :'/dashboard')
          }
        }).catch(err => {
          this.setState({loading: false})
          notification['error']({
            message: _.get(err, '[0].message')
          })
        })
      }
    });
  }

  render () {
    const {loading} = this.state
    const { getFieldDecorator } = this.props.form
    const { currentUser } = this.props
    //if(!appInit) return <Loading/>

    if(currentUser && !currentUser.roles.find(role => ['Supporter'].includes(role.name))) return <Redirect to="/dashboard"/>
    if(currentUser && currentUser.roles.find(role => ['Supporter'].includes(role.name))) return <Redirect to="/orders"/>
    return (
      <Container>
        <img src="/logo.png" style={{width: 150}} alt="OOPPRINT"/>
        <h1 style={{color: '#8397A7', fontSize: 20,margin: '20px 0 15px 0'}}>Sign In</h1>
        <Form className="login-form" onSubmit={this.handleLogin} style={{textAlign: 'initial'}}>
          <Form.Item label={'Email:'}>
            {getFieldDecorator('email', {
              rules: [{required: true, message: 'Email is required'}, { type: 'email', message: 'Email is not valid'}]
            })(<Input style={{textAlign: 'initial'}} placeholder="Email"/>)}
          </Form.Item>
          <Form.Item label={'Password:'}>
            {getFieldDecorator('password', {
              rules: [{required: true}]
            })(<Input.Password placeholder="Password"/>)}
          </Form.Item>
          <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: 20}}>
            <div style={{display: 'inline'}}>
              <Button type="primary" icon="check"/>
               &nbsp; &nbsp; Remember me
            </div>
            <Link style={{lineHeight: '35px'}} to='/forgot-password-confirm-email'>Forgot password?</Link>
          </div>
          <Form.Item style={{textAlign: 'center'}}>
            <Button loading={loading} type="primary" style={{
            border: 'none', width: '100%', 
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)'}} htmlType="submit">Login</Button>
          </Form.Item>
          <Form.Item style={{textAlign: 'center'}}>
            {/* <Link className={'register-link'} to="/register">Create an account</Link> */}
            {/*Don't have an account? <Link className={'register-link'} to="/register">Sign up</Link> */}
          </Form.Item>
        </Form>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  appInit: state.app.appInit,
  currentUser: state.app.currentUser,
  destination: state.app.destination
})

export default connect(
    mapStateToProps,
    {initApp, setCurrentUser, setDestination}
)(Form.create({name: 'login_form'})(PageLogin))