import React, { Component } from "react"
import { Link } from "react-router-dom"
import { message, Table, Button, Popconfirm, Icon, Tag, Avatar, Col, Row, Select, Input, notification, Popover, Form, Divider } from "antd"
import _ from "lodash"
import gql from "../../api/gql"
import styled from "styled-components"

const { Option } = Select
const Container = styled.div`
.ant-input-group-addon:hover {
  color: #ff9100;
}
@media only screen and (max-width: 768px) {
  .p-filter{
    display: flex;
    flex-direction: column-reverse;
    .btn-add{
      text-align: right
    }
  }
}
@media only screen and (min-width: 768px) {
  .btn-add{
    text-align: right
  }
}
`
export default class a extends Component {
  state = {
    loading: true,
    users: [],
    showModal: false,
    user: {},
    label: "",
    pagination: {
      results: 10,
      pageSize: 25,
      total: 0,
    },
    status: "active",
    filter: {
      search: ""
    },
    roles: [],
    page: 1
  };

  componentDidMount() {
    this.getListUser();
    this.getRole()
  }
  getRole() {
    const query = `query roles{roles{id name}}`
    gql.request(query, {
    }).then(res => {
      this.setState({
        roles: res.roles ?
          res.roles.filter((r) => r.name !== 'Authenticated') : [],
        loading: false
      })
    })
  }
  getListUser() {
    const { pagination, page } = this.state
    this.setState({ loading: true })
    const query = `query UsersList($filter: UserFilter!){
        users(filter: $filter){
          count
          hits{
            id 
            last_name
            first_name
            email
            pass
            phone
            address
            roles{
              id
              name
            }
            avatar{
              id
              url
            }
            status
            totalOrder
          }
        }
      }`
    gql.request(query, {
      filter: {
        offset: (page - 1) * pagination.pageSize,
        limit: pagination.pageSize,
        role: null,
        ...this.state.filter
      }
    })
      .then(res => {
        this.setState({ users: res.users.hits || [], loading: false, pagination: { ...pagination, total: res.users.count, results: res.users.count } });
      })
      .catch(err => {
        message["error"](_.get(err, "[0].message"));
        this.setState({ loading: false })
      });
  }

  deleteUser = id => {
    this.setState({ loading: true })
    const mutation = `
        mutation deleteUser($id: ID!){
          deleteUser(id: $id)
        }`;
    gql.request(mutation, { id })
      .then(
        res => {
          this.setState({ loading: false })
          if (res.deleteUser) {
            message["success"]("Delete success!");
            this.getListUser();
          } else {
            message["error"](_.get(res.errors, "[0].message"));
          }
        })
      .catch(err => {
        this.setState({ loading: false })
        message["error"](_.get(err, "[0].message"));
      });
  };

  onChangeTable = async (e) => {
    await this.setState({ page: e.current })
    this.getListUser()
  }
  onChangeSelect = async (value) => {
    await this.setState({
      status: JSON.parse(value),
      filter: {
        ...this.state.filter,
        status: JSON.parse(value)
      }
    })
    this.getListUser()
  }
  onChangeRole = async value => {
    if (value !== "all") {
      await this.setState({
        filter: {
          ...this.state.filter,
          role: value
        }
      })
    } else {
      delete this.state.filter.role
      await this.setState({
        filter: this.state.filter
      })
    }
    this.getListUser()
  }
  onShow = (user, label) => this.setState({ showModal: true, user, label });
  onClose = showModal => this.setState({ showModal })

  render() {
    const { currentUser } = this.props
    const { users, roles, filter } = this.state
    const columns = [
      {
        title: "Name",
        dataIndex: "id",
        key: "fullname",
        width:180,
        render: (id, { first_name, last_name, avatar }) => (
          <div>
            <Avatar src={avatar ? avatar.url : null} style={{ backgroundColor: '#ffc033' }}>{first_name.charAt(0)}{last_name.charAt(0)}</Avatar> {first_name} {last_name}
          </div>
        )
      },
      { title: "Email", dataIndex: "email", key: "email" , width:180},
      { title: "Phone", dataIndex: "phone", key: "phone" , width:130},
      { title: "Address", dataIndex: "address", key: "address", width:180 },
      // { title: "Total Order", dataIndex: "totalOrder", key: "totalOrder", align: "right" },
      {
        title: "Roles",
        dataIndex: "roles",
        key: "roles",
        width:120,
        render: (roles) => (
          <span>
            {roles.map(role => (
              <Tag style={{marginBottom: 3}} color="#ffc033" key={role.id}>
                {role.name}
              </Tag>
            ))}
          </span>
        )
      },
      {
        title: "Status",
        key: "status",
        width:100,
        render: (text, record) => ( currentUser.id === record.id? null :
        <Popconfirm
        title={record.status? "Are you sure block this user?": "Are you sure unblock this user?"}
        onConfirm={() => {
          const mutation = `mutation blockUser($id: ID!, $action: Boolean!){
            blockUser(id: $id, action: $action){
              id
              status
            }
          }`
          this.setState({loading: true})
          gql.request(mutation, {id: record.id, action: record.status})
            .then( res => {
              let users = this.state.users.map( el => {
                if(el.id === res.blockUser.id){
                  el.status = res.blockUser.status
                }
                return el
              })
              this.setState({loading: false, users: users})
              notification['success']({message: res.blockUser.status? 'User unblocked' :'User blocked'})
            })
            .catch( err => {
              notification['error']({
                message: 'ERROR',
                description: _.get(err, "[0].message")
              })
              this.setState({loading: false})
            })
        }}
        >
          <Button size='small'>
            {record.status? 'Block' : 'UnBlock'}
          </Button>
        </Popconfirm>
        )},
      {
        title: "Action",
        key: "action",
        width: 120,
        align: 'right',
        render: (text, record) => (
          <span>
            {
            //Hide Hoa Function
            (currentUser.email ==='jla.vnv@gmail.com')?(
              <Button size="small"><Link to={`/admin/users-manager/edit/${record.id}`}><Icon type="edit" theme="twoTone" /></Link></Button>
            ):''}
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure delete this user?"
              onConfirm={() => this.deleteUser(record.id)}
              okText="Yes"
              cancelText="No">
              <Button size="small"><Icon type="delete" style={{color:"red"}}/></Button>
            </Popconfirm>
          </span>
        )
      }
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <Container >
        <Row className="p-filter">
          <Col md={20}>
            <Input.Search
            style={{marginBottom: "10px" , color:"#ff9100"}}
            placeholder="Search text" onSearch={value => this.setState({ filter: { ...filter, search: value } }, () => this.getListUser())} 
            addonBefore={
              <Popover 
              placement="bottomLeft"
              content={
              <Form className="p-filter" >
                <Form.Item>
                <Select defaultValue="true" onChange={this.onChangeSelect} style={{ width: 250}}>
                  <Select.Option value="true">Active</Select.Option>
                  <Select.Option value="false">Inactive</Select.Option>
                </Select>
                </Form.Item>
                <Form.Item> 
                <Select 
                showSearch
                optionFilterProp='children'
                placeholder="Fillter by roles" onChange={this.onChangeRole} style={{ width: 250}}>
                  <Option value="all">All roles</Option>
                  {
                    roles.map(role => (
                      <Option value={role.name} key={role.id}>{role.name}</Option>
                    ))
                  }
                </Select>
                </Form.Item>
              </Form>
              }
              >
                Filter <Icon type="filter"/>
              </Popover>
            }
            >
            </Input.Search>
          </Col>
          <Col md={4}>
            <div className="btn-add">
              {
              //Hide Hoa Function
              (currentUser.email ==='jla.vnv@gmail.com')?(
                <Button type="primary" style={{  marginBottom: "10px" }}>
                  <Link to="/admin/user-form"><Icon type="user-add" /> New User</Link>
                </Button>  
              ):''}
            </div>
          </Col>
        </Row>
        <Table scroll={{x:tableWidth}} loading={this.state.loading} columns={columns} dataSource={users} rowKey="id" pagination={this.state.pagination} onChange={this.onChangeTable} />
      </Container>
    );
  }
}