import React from 'react'
import { Form, Input, Card, notification, Button, Divider, Skeleton } from 'antd'
import Wysiwyg from '../wysiwyg'
import _ from 'lodash'
import { history } from '../../history'
import gql from '../../api/gql'
class CategoryForm extends React.Component{

  state={
    category:null,
    loading: false,
  }

  componentDidMount(){
    const {id} = this.props.match.params
    if(id){
      const query = `query categoryById{
        categoryById(id:${id}){
          id title description
        }
      }`
      this.setState({loading: true})
      gql.request(query)
      .then(res => 
        this.setState({ category: res.categoryById || [],
          loading: false,
        }),  
      )
      .catch(err => {
          notification['error']({
          message: 'ERROR',
          description: _.get(err, '[0].message')
        })
        this.setState({ loading: false })
      })
    }
  }

  saveCategory = e => {
    e.preventDefault()
    this.props.form.validateFields((err,value) => {
      if(!err){
        const {id} = this.props.match.params
        var mutation = `mutation createCategory($input: NewCategory!){
          createCategory(input: $input){
            id 
          }
        }`
        if(id){
          mutation = `mutation updateCategory($input: EditCategory!){
            updateCategory(id: ${id},input: $input)
              {
                id
              }
            }`
        }
        this.setState({loading:true})
        gql.request(mutation, {input: value})
        .then(res => {
          notification['success']({
            message: 'Catygory has been ' + (id ? 'updated' : 'created')
          })
          this.setState({ loading: false })
          history.goBack()
        })
        .catch(err => {
          notification['error']({
            message: 'ERROR',
            description: _.get(err, '[0].message')
          })
          this.setState({ loading: false })
        })
      }
    })
  }

  render(){
    const{getFieldDecorator} = this.props.form
    const {category ,loading} = this.state
    const {id} = this.props.match.params
    if (id && !category) return <Skeleton/>
    return(
      <Form onSubmit={this.saveCategory}>
        <Card title="Detail" style={{marginBottom: 20}}>
          <Form.Item label="Title">
            {
              getFieldDecorator('title',{
                rules: [{ required: true ,message: "Please input title",}],
                initialValue: category ? category.title : ''
              })(<Input />)
            }
          </Form.Item>
          <Form.Item label="Description">
            {
              getFieldDecorator('description', {
                initialValue: category ? category.description : ''
              })(<Wysiwyg/>)
            }
          </Form.Item>
          <Button type="primary" loading={loading} icon="save" htmlType="submit">Save</Button>
          <Divider type="vertical" />
          <Button icon="left" onClick={() => history.goBack()}>Cancel</Button>
        </Card>
      </Form>
    )
  }
}

export default Form.create({ name: 'category-form' })(CategoryForm)