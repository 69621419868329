import React, {Component} from 'react'
import {Form, InputNumber} from 'antd'
import PropTypes from 'prop-types'

class ProductVariantCostField extends Component {
  render() {
    return (
        <Form.Item label={this.props.label}>
          <InputNumber
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g,
                  ',')}
              onChange={this.props.onChange}
              value={this.props.value}
          />
        </Form.Item>
    )
  }
}

ProductVariantCostField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  label: PropTypes.string,
}

export default ProductVariantCostField
