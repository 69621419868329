import { SET_CURRENT_USER, SET_CURRENT_SITE, INIT_APP, SET_DESTINATION, SET_SELLER_SITES } from "../types"

const initState = {
  currentUser: null,
  sellerSites: [],
  currentSite: null,
  appInit: false,
  destination: null,
}

export default (state = initState, action) => {
  switch (action.type) {
    case INIT_APP:
      return {
        ...state,
        appInit: true,
      }
    case SET_DESTINATION:
      return {
        ...state,
        destination: action.payload
      }
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload
      }
    case SET_CURRENT_SITE:
      localStorage.setItem("currentSiteID", action.payload ? action.payload.id : null)
      return {
        ...state,
        currentSite: action.payload
      }
    case SET_SELLER_SITES:
      return {
        ...state,
        sellerSites: action.payload
      }
    default:
      return state
  }
}