import React from 'react'
import styled from 'styled-components'
import { Tabs } from 'antd'
import UserDetail from './UserDetail'
import UserChangePassword from './UserChangePassword'

const Container = styled.div``

class UserEdit extends React.Component {
  render() {
    const { currentUser } = this.props
    const { id } = this.props.match.params
    return (
      <Container>
        <Tabs type='card'>
          <Tabs.TabPane tab='Account Detail' key='1'>
            <UserDetail id={id} />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Change Password' key='2'>
            <UserChangePassword id={id} currentUser={currentUser}/>
          </Tabs.TabPane>
        </Tabs>
      </Container>
    )
  }
}

export default UserEdit