import React from 'react'
import { Skeleton, Card, Button, notification, Progress, Tooltip, Icon, message } from 'antd'
import Upload from '../Upload'
import gql from '../../api/gql'
import _ from 'lodash'
import PrintFilePreview from '../file/PrintFilePreview'
import styled from 'styled-components'
import { CLOUDFRONT_URL } from '../../config';

const Container = styled.div`
  .ant-upload.ant-upload-select.ant-upload-select-text{
    width:100%;
  }
  .pod-file-download{
    position: absolute;
    font-size: 14px;
    bottom: 12px;
    right: 8px;
    border-radius: 50%;
    background-color:#ff6666;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    i{
      color: #FFF;
    }
  }
  .print_files{
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 10px;
  }
  @media only screen and (max-width: 600px) {
    .print_files{
      grid-template-columns: repeat(3, auto);
    }
  }
`
const UploadContainer = styled.div`
  position: relative;
  height:0;
  padding-bottom:67%;
  .upload-wrapper{
    display: block;
    position: relative;
    height: 0;
    padding-bottom:67%;
    .upload-area{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
class UploadDesign extends React.Component {
  state = {
    file: this.props.file,
    uploading: false,
    percent: 0
  }

  onUpload = file => {
    let query = `mutation createFile($input: NewFile!){
      createFile(input: $input){id}
    }`
    gql.request(query, {
      input: {
        source: 'aws',
        key: file.key,
        file_name: file.file.name,
        file_mime: file.file.type,
        file_size: file.file.size,
      }
    }).then(({ createFile }) => {
      const { file } = this.state
      this.setState({
        uploading: false,
        file: { ...file, file_id: createFile.id }
      }, () => this.props.onChange(this.state.file))
    }).catch(err => {
      this.setState({
        uploading: false,
        percent: 0
      })
    })
  }

  beforeUpload = (file, fileList) =>{
    var regex = new RegExp("^(\\d|\\w)+(.jpg|.jpeg|.png|.tiff|.tif|.psd)$")
    var result = regex.test(file.name);
    if (result===false){
      message["error"]("File name cannot contain empty space or any special characters")
      return false
    }else{
      return true
    }
  }

  render() {
    const { file, uploading, percent } = this.state
    return (
      <UploadContainer>
        <Upload
          beforeUpload={this.beforeUpload}
          onUpload={this.onUpload}
          onProcess={e => {
            this.setState({
              uploading: true,
              percent: Math.round(e.loaded / e.total * 100)
            })
          }}
          fileList={false}
          accept=".jpg, .png, .tiff, .psd"
          className="upload-wrapper">
          <div className="upload-area">
            {uploading ?
              <Progress type="circle" percent={percent} strokeColor="#1890ff" width={50} /> :
              (file.file_id ? <PrintFilePreview file_id={file.file_id} /> : <span style={{ textAlign: 'center' }}>Missing, click to upload or drag to upload</span>)
            }
          </div>
        </Upload>
      </UploadContainer>
    )
  }
}

export default class UploadDesigns extends React.Component {
  state = {
    product: null,
    printFiles: [],
    loading: true,
    percent: 0,
    submitLoading: false,
    submitReadyLoading:false,
    uploadLoading: {},
    isReady: false,
  }

  componentDidMount() {
    this.loadPrintFiles()
  }

  loadPrintFiles =()=>{
    const { product_id, variant_type_id } = this.props
    let query = `query productByID($id: Int!){
      productByID(id: $id){
        id
        title
        images
        is_custom_design
        variants{
          id
          product_type{
            title
            id
            print_files{
              key
              title
              note
              width
              height
              variant_ids
            }
          }
          product_type_variant{
            id
          }
          print_files{
            key
            mockup
            title
            file_id
            note
            width
            height
            variant_ids
            file{
              id
              key
            }
          }
        }
      }
    }`

    gql.request(query, {
      id: product_id
    }).then(res => {
      //let printFiles = _.uniqBy(res.productByID.variants, 'product_type.id').map(variant => ({ product_type: variant.product_type, print_files: variant.print_files ? variant.print_files : [/*{...variant.product_type.print_files, file_id: null}*/] }))
      //let printFiles = res.productByID.variants.map(variant => ({ product_type: variant.product_type, print_files: variant.print_files ? variant.print_files : [/*{...variant.product_type.print_files, file_id: null}*/] }))
      //console.log("printFiles", res.productByID.variants)
      //console.log("printFiles",  printFiles)
      let productTypes= _.uniqBy(res.productByID.variants, 'product_type.id')
      let allPrintFiles=[]
      res.productByID.variants.forEach(element => {
        if(element.print_files){
          element.print_files.forEach(p => {
            p.product_type_id = element.product_type.id
            allPrintFiles.push(p)
          });
        }
      });
      
      if(variant_type_id){
        //console.log("allPrintFiles", allPrintFiles)
        allPrintFiles = allPrintFiles.filter(ap=>ap.variant_ids===null || ap.variant_ids.length===0 || ap.variant_ids.includes(variant_type_id))
        //console.log("allPrintFiles1", allPrintFiles)
      }
      var printFiles = productTypes.map(productType =>({product_type: productType.product_type, print_files: _.uniqBy(allPrintFiles.filter(prf=>+prf.product_type_id === +productType.product_type.id),"key") }))
      // if (product_type_id) {
      //   printFiles = printFiles.filter(printFile => printFile.product_type.id === product_type_id)
      // }
      //console.log("printFiles",  printFiles)
      
      this.setState({
        product: res.productByID,
        printFiles: printFiles,
        loading: false
      })
      
    })
  }
  savePrintFiles = (e, isReady) => {
    e.preventDefault()
    if(isReady){
      this.setState({ submitReadyLoading: true })
    }else{
      this.setState({ submitLoading: true })
    }
    const { product, printFiles } = this.state
    const reloadVariable = printFiles.map(element => element.print_files.find(variant => variant.file_id === null))
    console.log(reloadVariable);
    let query = `mutation updatePrintFiles($product_id: Int!, $print_files: [ProductPrintFiles!]!, $is_ready: Boolean!){
      updatePrintFiles(product_id: $product_id, print_files: $print_files, is_ready: $is_ready)
    }`

    gql.request(query, {
      product_id: product.id,
      print_files: printFiles.map(printFile => ({
        product_type_id: printFile.product_type.id,
        print_files: printFile.print_files.map(pr => _.omit(pr, ['file', 'product_type_id']))
      })),
      is_ready: isReady
    }).then(res => {
      notification['success']({
        message: 'Print Files saved'
      })
      this.props.onClose()
      if (reloadVariable && reloadVariable[0] === undefined) {
        this.props.reloadProduct && this.props.reloadProduct()
      }
    }).catch(err => {
      if(isReady){
        this.setState({ submitReadyLoading: false })
      }else{
        this.setState({ submitLoading: false })
      }
      notification['error']({
        message: _.get(err, '[0].message')
      })
    })
  }
  render() {
    const { product, printFiles, loading } = this.state
    const {order} = this.props
    if (loading || !product) {
      return <Skeleton />
    }
    return (
      <Container>
        {printFiles.map((variant, index) => (
          <div key={index} style={{ marginBottom: 30 }}>
            <h4>{variant.product_type.title}</h4>
            <div className="print_files">
              {variant.print_files && variant.print_files.map((print_file, index2) => (
                <div key={`${variant.id}-${index2}`} >
                  <Card
                    hoverable
                    cover={(
                      <UploadDesign file={print_file} onChange={file => {
                        const { printFiles } = this.state
                        printFiles[index].print_files[index2] = file
                        this.setState({ printFiles })
                      }} />
                    )}
                  >
                    <Card.Meta title={print_file.title} description={`${print_file.width} x ${print_file.height}`} />
                    {print_file.note && <Card.Meta title={null} description={print_file.note} />}
                    {print_file.file && <div className="pod-file-download">
                      <a href={`${CLOUDFRONT_URL}/${print_file.file.key}`} rel="noopener noreferrer" target="_blank" title="Download Print File">
                        <Tooltip placement="topRight" title="Download print file">
                          <Icon type="download"></Icon>
                        </Tooltip>
                      </a>
                    </div>
                    }
                  </Card>
                </div>
              ))}
            </div>
            {product.images && product.images.length > 0 && <div>
              <h4 style={{ marginTop: 50 }}>Product Mockup</h4>
              <img src={product.images ? product.images[0] : null} alt="" style={{ width: "50%" }} />
            </div>}
          </div>
        ))}
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }}
        >
          <Button type="primary" onClick={(e)=>{
            this.savePrintFiles(e, false)
          }} 
            loading={this.state.submitLoading}>Save</Button>

          {order && (order.status === "sup_rejected" || order.status === "sup_failed") && <Button type="primary" onClick={(e)=>{
            this.savePrintFiles(e, true)
          }} 
            loading={this.state.submitReadyLoading} style={{marginLeft: "5px"}}>1111Save and Ready</Button>}
        </div>
      </Container>
    )
  }
}