import React from 'react'
import { Form, Card, Button, notification } from 'antd'
import gql from '../../api/gql'
import * as _ from "lodash"
import TextArea from 'antd/lib/input/TextArea';

class MessageSettingsForm extends React.Component {
  state = {
    initSetting: []
  }
  componentDidMount = () => {
    const query = `query getAppSettings($input: [String!]){
      getAppSettings(input: $input){
        name
        value
      }
    }`
    const input = ["seller_message"]
    this.setState({ loading: true })
    gql.request(query, { input: input })
      .then(res =>
        this.setState({ initSetting: res.getAppSettings || [], loading: false }))
      .catch(err => {
        notification["error"]({
          message: 'Error',
          description: _.get(err, "[0].message")
        })
        this.setState({ loading: false })
      })
  }
  render() {
    const { loading, form, onSubmit } = this.props
    const { initSetting } = this.state
    const setting = initSetting && initSetting.reduce((init, item) => ({ ...init, [item.name]: item.value }), {})
    const { getFieldDecorator } = form
    return (
      <Form
        loading={loading}
        onSubmit={e => {
          e.preventDefault()
          this.props.form.validateFields((err, values) => {
            if (!err) {
              onSubmit(values)
            } else {
              notification.error({ message: 'Form is invalid' })
            }
          })
        }}
      >
        <Card title="Message settings">
          <Form.Item label="Message">
            {getFieldDecorator('seller_message', {
              initialValue: setting ? setting['seller_message'] : ''
            })(<TextArea />)}
          </Form.Item>
        </Card>
        <Form.Item style={{ textAlign: 'right', marginTop: 20 }}>
          <Button icon="save" type='primary' htmlType='submit'>Save</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default Form.create({ name: 'form' })(MessageSettingsForm)
