import React, { useEffect, useState } from "react";
import { Button, Drawer, message, Card, Switch, Progress, Popconfirm } from "antd";
import gql from "../../api/gql";
import Upload from "../Upload";
import { get } from "lodash";
const CustomBrandingData = ({
  orderId,
  customBranding,
  customBrandingData,
  onChange,
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadingKey, setUploadingKey] = useState(null);
  const [percent, setPercent] = useState(0);
  const [customBrandingStatus, setCustomBrandingStatus] =
    useState(customBranding);
  const [customBrandingDataJson, setCustomBrandingDataJson] = useState(
    customBrandingData &&
      (typeof JSON.parse(customBrandingData) == "array" ||
        typeof JSON.parse(customBrandingData) == "object")
      ? JSON.parse(customBrandingData)
      : [
          {
            slip_front: "",
            slip_back: "",
            card_outside: "",
            card_inside: "",
            front_image: "",
          },
        ]
  );

  const brandingList = [
    {
      label: "Branding Slip (2400x3150px)",
      data: [
        {
          label: "Branding Packing Slip Front",
          key: "slip_front",
        },
        {
          label: "Branding Packing Slip Back",
          key: "slip_back",
        },
      ],
    },
    {
      label: "Insert Card (2400x1500px)",
      data: [
        {
          label: "Branding Insert Card Outside",
          key: "card_outside",
        },
        {
          label: "Branding Insert Cart Inside",
          key: "card_inside",
        },
      ],
    },
    {
      label: "Sticker (1250x1850px)",
      data: [
        {
          label: "Branding Sticker Front",
          key: "front_image",
        },
      ],
    },
  ];

  const { Meta } = Card;

  const mutitationCustomBradingData = ({
    order_id,
    branding_status,
    branding_data_json,
  }) => {
    setLoading(true);
    const mutation = `mutation changeBrandingOrder($order_id: Int!, $custom_branding: Boolean!, $custom_branding_data: String!) {
        changeBrandingOrder(order_id: $order_id, custom_branding: $custom_branding, custom_branding_data: $custom_branding_data){
            id,
            origin_id,
            custom_branding_data,
            custom_branding
        }
    }`;
    gql
      .request(mutation, {
        order_id: parseInt(order_id),
        custom_branding: branding_status,
        custom_branding_data: JSON.stringify(branding_data_json),
      })
      .then((res) => {
        const data = res.changeBrandingOrder;
        setCustomBrandingStatus(data.custom_branding);
        setCustomBrandingDataJson(JSON.parse(data.custom_branding_data));
        setLoading(false);
      })
      .catch((err) => {
        if (get(err, "[0].message")) {
          message.error(err[0].message);
        } else {
          message.error("An error occurred. Please try again!");
        }
        setLoading(false);
      });
  };

  const handleChangeBrandingStatus = (checked) => {
    mutitationCustomBradingData({
      order_id: orderId,
      branding_status: checked,
      branding_data_json: customBrandingDataJson,
    });
  };

  const unLoading = () => {
    setUploadingKey(null);
    setPercent(0);
  };

  const checkBeforeUpdate = (file, brandingKey) => {
    var regex = new RegExp("^(\\d|\\w)+(.jpg|.jpeg|.png)$");
    var result = regex.test(file.name);
    if (result === false) {
      message["error"](
        "File name cannot contain empty space or any special characters"
      );
      return false;
    } else {
      setUploadingKey(brandingKey);
      return true;
    }
  };
  const handleDeletBrandingItem = (key) => {
    const newcustomBrandingDataJson = customBrandingDataJson[0];
    newcustomBrandingDataJson[key] = "";
    mutitationCustomBradingData({
      order_id: orderId,
      branding_status: customBrandingStatus,
      branding_data_json: [newcustomBrandingDataJson],
    });
    unLoading();
  }
  const onUpload = (file) => {
    try {
      const newcustomBrandingDataJson = customBrandingDataJson[0];
      newcustomBrandingDataJson[uploadingKey] = file.url;

      mutitationCustomBradingData({
        order_id: orderId,
        branding_status: customBrandingStatus,
        branding_data_json: [newcustomBrandingDataJson],
      });
      unLoading();
    } catch (error) {
      unLoading();
    }
  };
  return (
    <>
      <Button
        onClick={() => {
          setVisible(true);
        }}
        icon="crown"
        style={{ width: "100%" }}
      >
        Custom Branding
      </Button>

      <Drawer
        width={500}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        title="Premium Branding Settings"
      >
        <div
          style={{
            marginBottom: 20,
          }}
        >
          <h4>Custom branding status:</h4>
          <Switch
            loading={loading}
            onChange={handleChangeBrandingStatus}
            defaultChecked={customBrandingStatus}
          ></Switch>
        </div>
        {customBrandingStatus && (
          <div>
            {brandingList.map((item, index) => (
              <div
                key={index}
                style={{
                  marginBottom: 20,
                }}
              >
                <h4>{item.label}</h4>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  {item.data.map((brandItem, BrandIndex) => (
                    <div
                      key={BrandIndex}
                      className={(uploadingKey && (uploadingKey != brandItem.key)) ? 'disabled-card' : 'cart-item-brading'}
                      style={{
                        width: "50%",
                        position: 'relative'
                      }}
                    >
                      {customBrandingDataJson[0][brandItem.key] && 
                      <div style={{
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        zIndex: 99999
                      }} className="box-delete-brading">
                        <Popconfirm 
                          title="Are you sure delete this?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => handleDeletBrandingItem(brandItem.key)}
                        >
                          <Button icon="delete" shape="circle"/>
                        </Popconfirm>
                      </div>
                      }
                      <Card
                        cover={
                          <Upload
                            accept=".jpg, .png"
                            fileList={false}
                            beforeUpload={(file) =>
                              checkBeforeUpdate(file, brandItem.key)
                            }
                            onUpload={onUpload}
                            onProcess={(e) => {
                              setPercent(
                                Math.round((e.loaded / e.total) * 100)
                              );
                            }}
                            className="upload-wrapper"
                          >
                            <div
                              style={{
                                height: 170,
                                display: "flex",
                                justifyItems: "center",
                                alignContent: "center",
                                alignItems: "center",
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              {uploadingKey != brandItem.key &&
                                !customBrandingDataJson[0][brandItem.key] && (
                                  <span
                                    style={{ textAlign: "center", margin: 10 }}
                                  >
                                    Missing, click to upload or drag to upload
                                  </span>
                                )}
                              {customBrandingDataJson[0][brandItem.key] && (
                                <div
                                  style={{
                                    width: "100%",
                                    justifyItems: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    padding: 5,
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "100%",
                                    }}
                                    src={
                                      customBrandingDataJson[0][brandItem.key]
                                    }
                                  />
                                </div>
                              )}
                              {uploadingKey == brandItem.key && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    width: "100%",
                                    display: "flex",
                                    placeItems: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: '#fafafa'
                                  }}
                                >
                                  <Progress
                                    type="circle"
                                    percent={percent}
                                    strokeColor="#1890ff"
                                    width={50}
                                  />
                                </div>
                              )}
                            </div>
                          </Upload>
                        }
                      >
                        <Meta description={brandItem.label}></Meta>
                      </Card>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </Drawer>
    </>
  );
};

export default CustomBrandingData;
