import React, { Component } from 'react';
import { Form, notification, Input, Button, Checkbox, DatePicker, Card } from 'antd'
import gql from '../../api/gql'
import _ from 'lodash'
import { history } from '../../history'


class UserForm extends Component {
  state = {
    loading: false,
    isChecked: [],
    roles: [],
    form: {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      address: '',
      password: '',
      roles: [],
    },
  }

  compareToFirstPassword = (rule, values, callback) => {
    const { form } = this.props;
    if (values && values !== form.getFieldValue("pass")) {
      callback("Two passwords that you enter is inconsistent!")
    } else {
      callback()
    }
  }

  componentDidMount() {
    const q = `query roles{roles{id name}}`
    gql.request(q).then((res) => {
      this.setState({
        roles: res.roles ?
          res.roles.filter((r) => r.name !== 'Authenticated') :
          [],
      })
    })
  }
  onhandleSubmit = e => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      e.preventDefault()
      delete values.confirm;
      if (values.pass === "") {
        delete values.pass;
      }
      if (!err) {
        //values.birthday = moment(values.birthday).format("DD-MM-YYYY")
        if (values.birthday) {
          values.birthday = values.birthday.toDate()
        }
        const mutation = `mutation createUser($input: NewUser!){
          createUser(input: $input){
            id
            first_name
            last_name
            email
            address
            phone
            birthday
            roles{
              id
              name
            }
            is_subscription
          }
        }`
        this.setState({ loading: true })
        gql.request(mutation, {
          input: values
        }).then(res => {
          notification['success']({ message: "User successfully created " })
          this.setState({
            loading: false
          })
          history.push('/admin/users-manager')
        })
          .catch(err => {
            notification['error']({
              message: _.get(err, '[0].message')
            })
            this.setState({ loading: false })
          })
        return
      }
    })
  }
  render() {
    const { loading } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.onhandleSubmit}>
        <Card title="Detail" style={{ marginBottom: 20, maxWidth: "800px" }}>
          <Form.Item label="First Name">
            {
              getFieldDecorator('first_name', {
                rules: [
                  {
                    required: true,
                    message: "Please input first name"
                  }
                ]
              })(<Input />)
            }
          </Form.Item>
          <Form.Item label="Last Name">
            {
              getFieldDecorator('last_name', {
                rules: [
                  {
                    required: true,
                    message: "Please input last name"
                  }
                ]
              })(<Input />)}
          </Form.Item>
          <Form.Item label="Email">
            {
              getFieldDecorator('email', {
                rules: [{
                  required: true,
                  message: "Please input email"
                }]
              })(<Input />)}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator('pass', {
              rules: [{
                required: true,
                message: "Please input password"
              }]
            })(<Input.Password type="password" />)}
          </Form.Item>
          <Form.Item label="Confirm">
            {getFieldDecorator('confirm', {
              rules: [{
                required: true,
                validator: this.compareToFirstPassword
              }]
            })(<Input.Password type="password" />)}
          </Form.Item>
          <Form.Item label="Birthday">
            {getFieldDecorator('birthday', {
              // initialValue: moment(),
            })((<DatePicker format={'DD/MM/YYYY'} style={{ width: '300px' }} />))}
          </Form.Item>
          <Form.Item label="Address">
            {getFieldDecorator('address', {
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Phone">
            {getFieldDecorator('phone', {
              rules: [{
                required: true,
                message: "Please input phone"
              }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Subscription">
            {getFieldDecorator('is_subscription', {
              valuePropName: 'checked'
            })(
              <Checkbox>Receive updates about new products and email marketing</Checkbox>
            )}
          </Form.Item>
          <Form.Item label="Roles">
            {
              getFieldDecorator('role', {
                rules: [{ required: true, message: 'Please choose user roles' }]
              })(
                <Checkbox.Group
                  options={this.state.roles.map(role => { return { label: role.name, value: role.name } })} />
              )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>Save</Button>{"  "}
            <Button onClick={() => { history.push('/admin/users-manager') }}>Cancel</Button>
          </Form.Item>
        </Card>
      </Form>
    );
  }
}

export default Form.create({ name: "form" })(UserForm);