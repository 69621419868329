import React from 'react'
import { Button } from 'antd'
import { connect } from 'react-redux'
import { history } from '../../history'

class ProductToolbar extends React.Component {
  render() {
    const add = this.props.currentUser.roles.find(el => ['Seller'].includes(el.name))
    return (
      <>
        {add && (
          <Button type="primary" icon="plus" onClick={() => history.push('/products/add/step1')}>Add New</Button>
        )}
      </>
    )
  }
}

export default connect(
  state => ({
    currentUser: state.app.currentUser
  })
)(ProductToolbar)