import React from 'react'
import Category from '../../components/categories/ListCategory'

export default class Categories extends React.Component{
  render(){
    return(
      <div>
        <Category/>
      </div>
    )
  }
}