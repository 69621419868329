import React, { Component } from 'react'
import { Form, Input, Button, notification } from 'antd'
import styled from 'styled-components'
import gql from '../../api/gql'
import _ from 'lodash'

const { TextArea } = Input
const Container = styled.div`
.ant-form-item{
  margin-bottom: 10px;
}
.ant-form-horizontal{
  margin-bottom: 30px;
}`

class AddCarrier extends Component {
  state = {
    loading: false
  }

  onSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: false })
        const mutation = `mutation createCarrier($input: NewCarrier!){
          createCarrier(input: $input){
            id
            name
            code
            url
            description
          }
        }`
        this.setState({ loading: true })
        gql.request(mutation, {
          input: values
        }).then(res => {
          notification['success']({ message: "Carrier successfully created" })
          this.setState({ loading: false })
          this.props.onLoad()
          this.props.onClose()
          this.props.form.resetFields()
        }).catch(err => {
          notification['error']({
            message: _.get(err, '[0].message')
          })
          this.setState({ loading: false })
        })
        return
      }
    })
  }
  render() {
    const { loading } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <Container>
        <Form onSubmit={this.onSubmit}>
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: "Please input name"
                }
              ]
            })(<Input placeholder="Name" />)}
          </Form.Item>
          <Form.Item label="Code">
            {getFieldDecorator('code', {
              rules: [
                {
                  required: true,
                  message: "Please input code"
                }
              ]
            })(<Input placeholder="Code" />)}
          </Form.Item>

          <Form.Item label="Url">
            {getFieldDecorator('url', {
              rules: [
                {
                  required: true,
                  message: "Please input url"
                }
              ]
            })(<Input placeholder="http//:" />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description', {
            })(<TextArea />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>Save</Button>{" "}
            <Button onClick={() => this.props.onClose()}>Cancel</Button>
          </Form.Item>
        </Form>
      </Container>
    );
  }
}

export default Form.create({ name: 'form' })(AddCarrier);