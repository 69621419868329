import React from 'react'
import gql from '../../api/gql'
import { Table, Row, Col, Select, message } from 'antd'
import S3Image from '../../components/S3Image'
const {Option} = Select

export default class SupplierProductTypes extends React.Component{
  _isMounted = false;
  state = {
    productTypes: [],
    loading: true,
    filter:{
      categories:[],
      supplier_id: this.props.currentUser && this.props.currentUser.roles.filter(el => ['Supplier'].includes(el.name)).length>0? this.props.currentUser.id : null,
      status: true,
    },
    categories:[],
    suppliers:[],
    pagination: {
      results: 10,
      pageSize: 10,
      total: 0,
    },
    page: 1,
  }

  componentDidMount() {
    this.loadCategories()
    this.loadProductType()
  }
  
  loadProductType(){
    const { pagination } =this.state
    const query = `query productTypes($filter: ProductTypeFilter){
      productTypes(filter: $filter){
        count,
        hits{
          id
          sku
          title
          images
          categories{
            id, title
          }
          attributes{
            name
          }
        }
      }
    }`
    this.setState({loading:true})
    gql.request(query,{ filter:{
      ...this.state.filter,
      limit: pagination.pageSize,
      offset: (this.state.page-1) * pagination.pageSize
    } })
    .then(res => {
      this.setState({
        productTypes: res.productTypes.hits || [],
        loading: false,
        pagination: { ...pagination, total: res.productTypes.count, results: res.productTypes.count }
      })
    })
  }
  loadCategories(){
    const query = `query categories {
      categories(filter:{status:true, limit: 9999, offset: 0}){
        hits{id title}
      }
    }
    `
    gql.request(query)
    .then(res=> this.setState({categories: res.categories.hits || [], loading:false}))
    .catch(err => {
      message['error']('Something wrong!')
    })
  }

  onChangeTable = async (e) => {
    await this.setState({ page: e.current })
    this.loadProductType()
  }
  handleChangeCategory = async (value) => {
    if (value !== "All") {
      await this.setState({
        filter: {
          ...this.state.filter,
          categories: [value]
        }
      })
    } else {
      delete this.state.filter.categories
      await this.setState({
        filter: this.state.filter
      })
    }
    this.loadProductType()
  }

  handleChangeSupplier = async (value) => {
    if (value !== "All"){
      await this.setState({
        filter:{
          ...this.state.filter,
          supplier_id: value
        }
      })
    }else {
      delete this.state.filter.supplier_id
      await this.setState({
        filter:this.state.filter
      })
    }
    this.loadProductType()
  }
  handleChangeStatus = async (value) => {
    await this.setState({
      filter:{
        ...this.state.filter,
        status: value.key === "active" ? true : false
      }
    })
    this.loadProductType()
  }
  
  render() {
    const {productTypes, loading, categories, suppliers} = this.state
    const isAdmin = this.props.currentUser && this.props.currentUser.roles.filter(el => ['Administrator'].includes(el.name)).length>0? true : false
    let columns = [
      {
        title: 'Title',
        dataIndex: 'title',
      render: (title, {images}) => <div>{images && <S3Image src={images[0]} style={{maxWidth:60, maxHeight:60}}/>} {title}</div>
      },
      {
        title: 'SKU',
        width: 120,
        key: 'sku',
        dataIndex: 'sku'
      },
      {
        title: 'Categories',
        dataIndex: 'categories',
        render: (categories) => categories.map(cat => cat.title).join(', ')
      }
    ]
    return (
      <div>
        <Row style={{marginBottom: 20}} >
          <Col span={18} style={{display: "flex", justifyContent: 'flex-start'}}>
            <Select style={{ width: 200 }} labelInValue defaultValue={{key: 'active'}} onChange={this.handleChangeStatus}>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
            <Select placeholder="Filter by Category" style={{margin:'0px 10px', width: 200 }} onChange={this.handleChangeCategory} >
              <Option value="All">All</Option>
            {
              categories.map(category => 
              <Option key={category.id} value={category.id}>{category.title}</Option>  
              )
            }
            </Select>
            {isAdmin && (
              <Select placeholder="Filter by Supplier" style={{ width: 200 }} onChange={this.handleChangeSupplier}>
              <Option value="All">All</Option>
            {
              suppliers.map(supplier => 
              <Option key={supplier.id} value={supplier.id}>{supplier.first_name} {supplier.last_name}</Option>
              )
            }
            </Select>
            )}
          </Col>
        </Row>
        <Table pagination={this.state.pagination} onChange={this.onChangeTable} dataSource={productTypes} loading={loading} columns={columns} rowKey={row => row.id}/>
      </div>
    )
  }
}