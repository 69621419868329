import React, { Component } from 'react'
import { Card, Row, Input, Col, Select, Button } from 'antd'
const { Option } = Select;
const InputGroup = Input.Group;
export default class CustomerEdit extends Component{
  state={
    data: this.props.data
  }
  render(){
    const {data}= this.state
    return(
      <div>
        <Card title="Contact Info">
          <Row gutter={16}>
            <Col span={24}>
            <h4>Tags</h4>
            <Input placeholder="Start typing..." style={{marginBottom:'15px'}}></Input> 
            <h4>Lists</h4>
            <Input placeholder="Start typing..." style={{marginBottom:'15px'}}/> 
            </Col>
          </Row>
          <Row  gutter={16}>
            <Col span={12} >
              <h4>First Name</h4>
              <Input defaultValue={data.first_name} style={{marginBottom:'15px'}}/>
              <br/>
              <h4>Phone</h4>
              <Input placeholder={data.phone} style={{marginBottom:'15px'}}/>
            </Col>
            <Col span={12}>
              <h4>Last Name</h4>
              <Input defaultValue={data.last_name} style={{marginBottom:'15px'}}/>
              <br/>
              <h4>Email</h4>
              <Input defaultValue={data.email} style={{marginBottom:'15px'}}/>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <h4>Company</h4>
              <Input placeholder="Company name" style={{marginBottom:'15px'}}/>
              <h4>Street Address</h4>
              <Input placeholder="Address line 1" style={{marginBottom:'10px'}}/> 
              <Input placeholder="Address line 2" style={{marginBottom:'15px'}}/>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8} style={{marginBottom:'15px'}}>
              <h4>Country</h4>
              <InputGroup compact>
                <Select placeholder="Select..." style={{ width: '100%' }}>
                  <Option value="Select">Select</Option>
                </Select>
              </InputGroup>
            </Col>
            <Col span={8}>
              <h4>City</h4>
              <Input placeholder="New York City" />
            </Col>
            <Col span={8}>
              <h4>Zip</h4>
              <Input placeholder="Zip/postcode" />
            </Col>
          </Row> 
          <Row gutter={16}>
            <Col span={24} style={{justifyContent: ''}}>
              <Button type="link" onClick={this.props.onClick}>Cancel</Button>
              <Button type="primary">Save</Button>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}