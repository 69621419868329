import React from "react";
import { connect } from "react-redux";
import { setOrdersFilter } from "../../actions";
import { Select, Input, message } from "antd";
import gql from "../../api/gql";
import _ from "lodash";
import moment from "moment";
import DateRange from "../DateRange";
import styled from "styled-components";

const Container = styled.div`
  .filter{
    display: flex;
  }
  .product-types{
    max-width: 250px;
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
    min-width: 120px;
  }
  .left-filter{
    display: flex;
    margin-left: 10px;
    width: -webkit-fill-available;
  }
  .search{
    min-width: 200px;
    width: 100%;
    margin: 0 10px 10px 0px;
  }
  @media only screen and (max-width: 720px) {
    .filter{
      display: grid;
    }
    .search{
      min-width: 200px;
      width: 100%;
      margin: 0;
    }
    .product-types{
      max-width: 250px;
      width: 100%;
      margin-right: 10px;
      margin-bottom: 0;
      min-width: 120px;
    }
    .left-filter{
      margin-top: 10px;
      margin-left: 0;
    }
  }
`

class SupplierOrdersFilter extends React.Component {
  state = {
    sites: [],
    productTypes: [],
    suppliers: [],
    sellers: [],
    filter: { ...this.props.filter },
  };

  componentDidMount() {
    this.loadSites();
    this.loadProductType();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        filter: { ...this.props.filter },
      });
    }
  }

  loadSites() {
    const query = `query sites{sites{hits{id,title}}}`;
    gql.request(query).then((res) => {
      this.setState({ sites: res.sites.hits || [] });
    });
  }

  loadProductType() {
    const query = `query productTypes{
      productTypes(filter: {limit: 9999}){
        hits{
          id title
        }
      }
    }`;
    gql
      .request(query)
      .then((res) => {
        this.setState({ productTypes: res.productTypes.hits || [] });
      })
      .catch((err) => {
        message["error"](_.get(err, "[0].message"));
      });
  }

  onChangeDate = (range, callback = () => {}) => {
    if (range.from !== null && range.to !== null) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            from: moment(range.from).format("YYYY-MM-DD[T]00:00:00[Z]"),
            to: moment(range.to).format("YYYY-MM-DD[T]HH:mm:ss[Z]"),
          },
        },
        callback
      );
    } else {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            from: null,
            to: null,
          },
        },
        callback
      );
    }
  };

  handleChange(key, value, callback = () => {}) {
    if (_.isArray(value)) {
      value = value.filter((item) => item !== null);
    }
    const { filter } = this.state;
    filter[key] = value;
    this.setState({ filter: { ...filter } }, callback);
  }

  applyFilter = () => {
    const { setOrdersFilter } = this.props;
    setOrdersFilter(this.state.filter);
  };

  resetFilter = () => {
    const { setOrdersFilter } = this.props;
    setOrdersFilter({
      site_ids: null,
      product_type_ids: null,
      supplier_ids: null,
      seller_ids: null,
      from: null,
      to: null,
      search: null,
      status: [],
      field: "all"
    });
  };

  render() {
    const { productTypes, filter } = this.state;
    return (
      <Container style={{ width: "100%" }}>
        <div className="filter" >
          <DateRange
            onChange={(dateRange) =>
              this.onChangeDate(dateRange, this.applyFilter)
            }
          />
          <div className="left-filter">
            <Select
              className="product-types"
              placeholder="Product types"
              onChange={(v) =>
                this.handleChange("product_type_ids", [v], this.applyFilter)
              }
              >
                <Select.Option value={null}>All Product Types</Select.Option>
                {productTypes.map((type) => (
                  <Select.Option key={type.id}>{type.title}</Select.Option>
                  ))}
            </Select>
            <Input.Search
              className="search"
              placeholder="Search..."
              value={filter.search}
              onChange={(e) => this.handleChange("search", e.target.value)}
              onSearch={e => {
                this.applyFilter()
              }}
            />
          </div>
        </div>
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    filter: state.orders.filter,
  }),
  { setOrdersFilter }
)(SupplierOrdersFilter);
