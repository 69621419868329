import React from 'react'
import {Menu, Icon} from 'antd'
import {adminMenu} from '../menu'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import _ from "lodash"

class LeftMenu extends React.Component {
  render () {
    const { location, currentUser, currentSite } = this.props;
    const childMenu = _.filter(adminMenu, function (menu) {
      return menu.child;
    });
    const defaultOpenKeys = _.filter(childMenu, function (children) {
      return _.find(children.child, function (childRouter) {
        return childRouter.router === location.pathname;
      });
    });
    const { SubMenu } = Menu
    if (!currentUser) return null
    return (
      <div className={'left-menu'} style={{height:'100%', position:'relative', paddingTop: 20}}>
        {/* <img src="/logo.png" alt="" style={{padding: "5px 15px", height: 64}}/> */}
        <Menu 
            defaultOpenKeys={
            defaultOpenKeys.length > 0
              ? [defaultOpenKeys[0].title]
              : defaultOpenKeys
          }
          mode="inline" 
          selectedKeys={[location.pathname]}
        >
          {adminMenu.map(menu => {
            if (menu.role) {
              let canAccess = false
              menu.role.forEach(role => {
                if (currentUser.roles.map(r => r.name).includes(role)){
                  canAccess = true
                }
                //Hide Hoa Menu
                // if(currentUser.email === 'jla.vnv@gmail.com' && menu.icon === 'setting'){
                //   canAccess = false
                // }
              })
              if (!canAccess) return null
            }
            return (
              menu.child?(
                <SubMenu
                  key={`${menu.title}`}
                  title={
                    <span>
                      {menu.icon && <Icon type={menu.icon}/>}
                      <span>{menu.title}</span>
                    </span>
                  }
                >
                  {
                    menu.child.map(sub => {
                      return(
                        <Menu.Item key={sub.router} style={{marginTop:0}}>
                          <Link to={sub.router}>
                            {sub.icon && <Icon type={sub.icon}/>}
                            <span>{sub.title}</span>
                          </Link>
                        </Menu.Item>
                      )
                    })
                  }
                </SubMenu>
              ):(
                <Menu.Item key={menu.router}>
                  <Link to={menu.router}>
                    {menu.icon && <Icon type={menu.icon}/>}
                    <span>{menu.title}</span>
                  </Link>
                </Menu.Item>
              )
            )
          })}
          {currentUser.roles.map(role => role.name).includes('Administrator') ?
          <Menu.Item key={`/admin/settings`} style={{position:'absolute', bottom:8}}>
              <Link to={`/admin/settings`}><Icon type='setting'/> <span>Settings</span></Link>:
          </Menu.Item> :
          currentSite &&
          <Menu.Item key={`/sites/${currentSite.id}/settings`} style={{position:'absolute', bottom:8}}>
            <Link to={`/sites/${currentSite.id}/settings`}><Icon type='setting'/> <span>Settings</span></Link>
          </Menu.Item>}
        </Menu>
      </div>
    )
  }
}

export default connect(
  state => ({
    currentUser: state.app.currentUser,
    currentSite: state.app.currentSite
  })
)(withRouter(LeftMenu))