import { all, call, put, takeEvery } from 'redux-saga/effects'
import { SET_CURRENT_USER, INIT_APP, INIT_APP_2, SET_CURRENT_SITE, SET_SELLER_SITES, RESET_ORDERS_FILTER } from '../types'
import gql from '../api/gql'

function* initApp(){
  //const { app } = yield select()
  yield put({
    type: RESET_ORDERS_FILTER
  })
  let q = `query me{me{id,first_name,last_name,email,roles{name}}}`
  var user = yield call(()=>gql.request(q).then(res => res.me).catch(() => {
    localStorage.removeItem('_token')
    localStorage.removeItem('_currentSiteID')
    return null
  }))
  if (user && user.roles.map(role => role.name).includes('Seller')) {
    let q = `query sites{sites(filter: {status: true, verified: true}){hits{id, title, platform, status, verified, type}}}`
    var sites = yield call(() => gql.request(q).then(res => res.sites.hits).catch(err => {}))
    let currentSiteID = localStorage.getItem('currentSiteID')
    if (sites.length){
      let currentSite = sites.find(site => site.id.toString() === currentSiteID)
      if (currentSite) {
        yield put({
          type: SET_CURRENT_SITE,
          payload: currentSite
        })
      }else{
        yield put({
          type: SET_CURRENT_SITE,
          payload: sites[0]
        })
      }
    }else{
      yield put({
        type: SET_CURRENT_SITE,
        payload: null
      })
    }
    yield put({
      type: SET_SELLER_SITES,
      payload: sites
    })
  }
  yield put({
    type: SET_CURRENT_USER,
    payload: user
  })
  yield put({
    type: INIT_APP,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(INIT_APP_2, initApp),
    call(initApp)
  ])
}