import { Button, Modal, Select, message, Alert } from "antd";
import React, {useState, useEffect} from "react";
import gql from "../../api/gql";
import { get } from "lodash";
export default function({orderId, supplier, onChange}){
    const { Option } = Select;
    const [visible, setVisible] = useState(false);
    const [option, setOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const [assign, setAssign] = useState(supplier ? supplier : null);
    const [error, setError] = useState({});
    const getNewSuppliers = () => {
        const query = `query {
            getNewSuppliers(order_id: ${orderId})
        }`;
        gql.request(query).then(res => {
            const newOption = res.getNewSuppliers.map(e => {
                return {
                    label: e,
                    value: e
                }
            })
            setOption(newOption);
            setError({})
        }).catch(err => {
            setError(err)
        })
    }
    const handSendAssign = () => {
        setLoading(true);
        const mutation = `
        mutation assignNewSupplier($order_id: Int!, $new_supplier: String!){
            assignNewSupplier(order_id: $order_id,new_supplier: $new_supplier)
        }`;
        gql.request(mutation, {
            order_id: orderId,
            new_supplier: assign
        }).then(res => {
            if(res.assignNewSupplier){
                setLoading(false);
                setVisible(false);
                onChange('load');
                message.success("New provider assignment successful.")
            }
        }).catch(err => {
            if(get(err, '[0].message')){
                message.error(err[0].message);
            }else{
                message.error("An error occurred. Please try again!");
            }
            setLoading(false);
        })
    }
    return (
        <>
            <Button style={{ width: "100%"}} icon="exception" onClick={() => {
                getNewSuppliers();
                setVisible(true);
            }}>Assign Supplier</Button>
            <Modal
            title="Select Assign Supplier"
            onOk={handSendAssign}
            onCancel={() => setVisible(false)}
            footer={
                [
                    <Button key={"1"} onClick={() => setVisible(false)}>Cancel</Button>,
                    <Button disabled={assign == null} key={"2"} onClick={handSendAssign} loading={loading} type="primary">Apply</Button>
                ]
            }
            visible={visible}>
                <div style={{
                    marginTop: -10
                }}>
                    {get(error, '[0].message') ? <Alert message={error[0]?.message} type="error" />:
                    <>
                    <div style={{marginBottom: 12}}>Present supplier: <b style={{color: '#24a105'}}>{supplier}</b></div>
                    <Select 
                    defaultValue={supplier ? supplier : null}
                    style={{ width: "100%" }}
                    onChange={(v) => setAssign(v)}
                    >
                        {option.map((o, index) => (
                            <Option key={index} value={o.value} >{o.label}</Option>
                        ))}
                    </Select>
                    </>
                    }
                    
                </div>
            </Modal>
        </>
    )
}