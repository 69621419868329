import React from 'react'
import gql from '../../api/gql'
import {Icon, Tooltip} from 'antd'

export default class PrintFileDownload extends React.Component{
    state = {
        fileUrl: null,
        error: false,
    }

    componentDidMount () {
        const {file_id} = this.props
        let query = `query getFileUrl{
      getFileUrl(file_id: ${file_id})
    }`
        gql.request(query).then(res => {
            this.setState({
                fileUrl: res.getFileUrl
            })
        }).catch(err => {
            this.setState({
                error: true
            })
        })
    }


    render() {
        const {fileUrl, error} = this.state

        return <a href={fileUrl} target="_blank" rel="noopener noreferrer"  title="Download Print File" style={{
            fontSize: '20px',
            float: 'right',
            position: 'absolute',
            right: '15px',
            bottom: '-40px',
        }}>
            <Tooltip placement="topRight" title="Download print file">
                <Icon type="download"></Icon>
            </Tooltip>
        </a>
    }



}