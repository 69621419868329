export const adminMenu = [
  {
    title: 'Dashboard',
    router: '/dashboard',
    icon: 'dashboard',
    role: ["Administrator", "Seller", "Supplier"]
  },
  {
    title: 'Users',
    router: '/admin/users-manager',
    icon: 'user',
    role: ['Administrator']
  },
  {
    title: 'Connect Sites',
    router: '/sites',
    icon: 'global',
    role: ['Administrator', 'Seller']
  },
  {
    title: 'Products',
    router: '/products',
    icon: 'skin',
    role: ['Administrator', 'Seller']
  },
  // {
  //   title: 'Product Types',
  //   router: '/product-types',
  //   icon: 'skin',
  //   role: ['Supplier']
  // },
  {
    title: 'Orders',
    router: '/orders',
    icon: 'shopping-cart',
    role: ["Administrator", "Seller", "Supplier", 'Supporter'],
    // child: [
    //   {
    //     title: 'Orders',
    //     router: '/orders',
    //     icon: 'shopping-cart'
    //   },
    //   {
    //     title: 'Import Order',
    //     router: '/import-orders',
    //     icon: 'shopping-cart'
    //   }
    // ]
  },
  {
    title: 'Download CSV',
    router: '/export-orders',
    icon: 'file',
    role: ['Supplier'],
  },
  {
    title: 'Balance',
    router: '/balance',
    icon: 'dollar',
    role: ['Administrator', 'Seller', 'Supporter', 'Listing', 'Accounting']
  },
  // {
  //   title: 'Productions',
  //   router: '/productions',
  //   icon: 'file-excel',
  //   role: ['Administrator']
  // },
  // {
  //   title: 'Transactions',
  //   router: '/transactions',
  //   icon: 'credit-card',
  //   role: ['Administrator', 'Supplier', 'Supporter', 'Listing', 'Accounting']
  // },
  // {
  //   title: 'Categories',
  //   router: '/admin/categories',
  //   icon: 'unordered-list',
  //   role: ['Supporter', 'Listing']
  // },
  // {
  //   title: 'Product Types',
  //   router: '/admin/product-types',
  //   icon: 'skin',
  //   role: ['Supporter', 'Listing']
  // },
  // {
  //   title: 'Carriers',
  //   router: '/admin/carriers',
  //   icon: 'car',
  //   role: ['Supporter', 'Listing']
  // },
  // {
  //   title: 'Seller Level',
  //   router: '/admin/seller-levels',
  //   icon: 'rise',
  //   role: ['Supporter', 'Listing']
  // },
  // {
  //   title: 'News',
  //   router: '/admin/news',
  //   icon: 'info-circle',
  //   role: ['Listing']
  // },
  {
    title: 'App Settings',
    icon: 'setting',
    role: ['Administrator'],
    child: [
      {
        title: 'Categories',
        router: '/admin/categories',
        icon: 'unordered-list'
      },
      {
        title: 'Product Types',
        router: '/admin/product-types',
        icon: 'skin'
      },
      {
        title: 'Carriers',
        router: '/admin/carriers',
        icon: 'car'
      },
      {
        title: 'Export templates',
        router: '/export-templates',
        icon: 'database',
      },
      // {
      //   title: 'Seller Levels',
      //   router: '/admin/seller-levels',
      //   icon: 'rise'
      // },
      // {
      //   title: 'Mail Templates',
      //   router: '/admin/mail-templates',
      //   icon: 'inbox'
      // },
      // {
      //   title: 'News',
      //   router: '/admin/news',
      //   icon: 'info-circle'
      // }
    ]
  },
  {
    title: 'Chat GPT Tool',
    router: '/chat-gpt-tool',
    icon: 'star',
    role: ["Administrator", "Seller", "Supplier"]
  },
  {
    title: 'Export templates',
    router: '/export-templates',
    icon: 'database',
    role: ['Supplier']
  },
  // {
  //   title: 'Sites',
  //   router: '/sites',
  //   icon: 'global'
  // },
  // {
  //   title: 'Products',
  //   router: '/products',
  //   icon: 'appstore'
  // },
  // {
  //   title: 'Orders',
  //   router: '/orders',
  //   icon: 'ordered-list'
  // },
  // {
  //   title: "Customers",
  //   router: '/customers',
  //   icon: 'user'
  // },
  // {
  //   title: "Members",
  //   router: '/members',
  //   icon: 'smile'
  // }
]