import React from 'react'
import { Table } from 'antd'
import _ from 'lodash'

export default class ExportTemplateView extends React.Component {

  state = {
    data: []
  }

  componentDidMount() {
    this.setState({
      data: this.props.value
    })
  }

  render() {
    const { data } = this.state
    const columns = [
      {
        title: 'Column Name',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        render: (name, _, index) => <div key={index}> {name} </div>
      },
      {
        title: "Type",
        dataIndex: 'type',
        width: 100,
        key: "type",
        render: (type, _, index) => <div key={index}> {type === 'custom_value' ? 'Custom' : 'Default'} </div>
      },
      {
        title: 'Value',
        width: 150,
        dataIndex: 'value',
        key: 'value',
        render: (v,_,index) => <div key={index}> {v} </div>
      }
    ];
    const tableWidth = _.sum(columns.map(c => c.width))
    return (
      <Table
        scroll={{ x: tableWidth }}
        columns={columns}
        dataSource={ data }
        bordered
        rowKey={(row, index) => index}
        pagination={false}
      />
    );
  }
}
