import React from 'react'
import Routes from '../routes'
import 'antd/dist/antd.css'

function App() {
  return (
    <div className={'niftyjs-crm-app'}>
      <Routes/>
    </div>
  )
}

export default App