import React from 'react'
import { Table, Icon, Button, Popconfirm, Select, Input } from 'antd'
import { DndProvider, DragSource, DropTarget } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import styled from 'styled-components'

const SortTable = styled.div`
  table tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }
  table tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
`

let dragingIndex = -1;

class BodyRow extends React.Component {
  
  render() {
    const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
    const style = { ...restProps.style, cursor: 'move' };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    );
  }
}


const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

export default class DragSortingTable extends React.Component {
  
  state = {
    data: this.props.value
  }

  handleDelete = index => {
    const {data} = this.state
    this.setState({ data: data.filter((item,i) => i !== index) }, () => this.props.onChange(this.state.data))
  }

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  footer = () => {
    return(
      <Button 
        icon="plus"onClick={(e)=>{
          e.preventDefault()
          const {data} = this.state
          data.push({name: "", slug:"", options: []})
          this.setState({data})
        }}
        type="primary"
      >
        Add
      </Button>
    )
  }

  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
        },
      }),
      () => {
        this.props.onChange(this.state.data)
      }
    );
  };

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width:250,
        render: (text, record, index) => (
          <Input value={text} onChange={e => {
            const {data} = this.state
            data[index].name = e.target.value
            this.setState({data}, () => this.props.onChange(this.state.data))
          }}/>
        )
      },
      {
        title: 'Slug',
        dataIndex: 'slug',
        key: 'slug',
        width:150,
        render: (text, record, index) => (
          <Input value={text} onChange={e => {
            const {data} = this.state
            data[index].slug = e.target.value
            this.setState({data}, () => this.props.onChange(this.state.data))
          }}/>
        )
      },
      {
        title: 'Options',
        dataIndex: 'options',
        render: (options, row, index) => (
          <Select mode="tags" style={{ width: '100%' }} value={options}
          onChange={value => {
            const {data} = this.state
            data[index].options = value
            this.setState({data}, () => this.props.onChange(this.state.data))
          }} dropdownStyle={{display:'none'}} tokenSeparators={[',']} ></Select>
        )
      },
      {
        title: '',
        width: 50,
        render: (_, row, i) =>
         this.state.data.filter.length >= 1 ? (
          <Popconfirm title="Are you sure to delete?" onConfirm={() => this.handleDelete(i)}>
            <Icon type="delete" style={{ color: 'red' }} />
          </Popconfirm>
         ) : null
      }
    ];
    return (
      <div className="attribute">
        <SortTable>
          <DndProvider backend={HTML5Backend}>
            <Table
              footer={this.footer}
              columns={columns }
              dataSource={this.state.data}
              components={this.components}
              onRow={(row, index) => ({
                index,
                moveRow: this.moveRow,
              })}
              rowKey={(row, index) => index}
              pagination={false}

            />
          </DndProvider>
        </SortTable>
      </div>
    );
  }
}
