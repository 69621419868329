import React from 'react'
import { connect } from 'react-redux'
import { setOrdersFilter } from '../../actions'
import { Select, Input, message } from 'antd'
import gql from '../../api/gql'
import _ from 'lodash'
import moment from 'moment'
import DateRange from '../DateRange'
import styled from 'styled-components'

const Container = styled.div`
  .seller-order-filter{
    display: flex;
  }
  .search-field {
    display: flex;
    width: 100%;
  }
  .od-search {
    width: 100%;
    min-width: 180px;
    margin: 0 5px 10px 0;
  }
  @media only screen and (max-width: 700px) {
    .seller-order-filter{
      display: grid;
    }
  }
`

class SellerOrdersFilter extends React.Component {
  state = {
    sites: [],
    productTypes: [],
    suppliers: [],
    sellers: [],
    filter: { ...this.props.filter }
  }

  componentDidMount() {
    this.loadSites()
    this.loadProductType()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        filter: { ...this.props.filter }
      })
    }
  }

  loadSites() {
    const query = `query sites{sites{hits{id,title}}}`
    gql.request(query).then(res => {
      this.setState({ sites: res.sites.hits || [] })
    })
  }

  loadProductType() {
    const query = `query productTypes{
      productTypes(filter: {limit: 9999}){
        hits{
          id title
        }
      }
    }`
    gql.request(query).then(res => {
      this.setState({ productTypes: res.productTypes.hits || [] })
    })
      .catch(err => {
        message["error"](_.get(err, "[0].message"));
      })
  }

  onChangeDate = (range, callback = () => { }) => {
    if (range.from !== null && range.to !== null) {
      this.setState({
        filter: {
          ...this.state.filter,
          from: moment(range.from).format('YYYY-MM-DD[T]00:00:00[Z]'),
          to: moment(range.to).format('YYYY-MM-DD[T]00:00:00[Z]')
        }
      }, callback)
    } else {
      this.setState({
        filter: {
          ...this.state.filter,
          from: null,
          to: null
        }
      }, callback)
    }
  }

  handleChange(key, value, callback = () => { }) {
    if (_.isArray(value)) {
      value = value.filter(item => item !== null)
    }
    const { filter } = this.state
    filter[key] = value
    this.setState({ filter: { ...filter } }, callback)
  }

  applyFilter = () => {
    const { setOrdersFilter } = this.props
    setOrdersFilter(this.state.filter)
  }

  resetFilter = () => {
    const { setOrdersFilter } = this.props
    setOrdersFilter({
      site_ids: null,
      product_type_ids: null,
      supplier_ids: null,
      seller_ids: null,
      from: null,
      to: null,
      search: null,
      status: null,
      field: "all"
    })
  }

  render() {
    const { filter, productTypes } = this.state
    return (
      <Container style={{ width: "100%" }}>
        <div className="seller-order-filter">
          <div style={{ marginRight: 5, marginBottom: 10 }}>
            <DateRange onChange={(dateRange) => this.onChangeDate(dateRange, this.applyFilter)}></DateRange>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <Select showSearch placeholder="Product types" style={{ width: 150, marginRight: 5, marginBottom: 10 }} onChange={v => this.handleChange('product_type_ids', [v], this.applyFilter)}>
              <Select.Option value={null}>All Product Types</Select.Option>
              {productTypes.map(type =>
                <Select.Option key={type.id}>{type.title}</Select.Option>)
              }
            </Select>
            <div className="search-field">
              <Select
                showSearch
                optionFilterProp="children"
                value={filter.field}
                placeholder="Select Field"
                style={{
                  width: 150,
                  marginBottom: 15,
                }}
                onChange={(v) => {
                  this.handleChange("field", v, null)
                  if (filter.search) {
                    this.applyFilter()
                  }
                }
                }
              >
                <Select.Option value="all">All Fields</Select.Option>
                <Select.Option value="name">Origin Number</Select.Option>
                <Select.Option value="origin_id">Origin ID</Select.Option>
                <Select.Option value="id">App ID</Select.Option>
              </Select>
              <div className="od-search">
                <Input.Search style={{ width: "100%", marginBottom: 10 }} placeholder="search..." 
                value={filter.search} 
                onChange={e => this.handleChange('search', e.target.value)} 
                onSearch={(e) => {
                  this.applyFilter();
                }}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

export default connect(
  state => ({
    filter: state.orders.filter
  }),
  { setOrdersFilter }
)(SellerOrdersFilter)