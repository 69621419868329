import React from 'react'
import styled from 'styled-components'
import gql from "../../api/gql"
import _ from "lodash"
import {Table, Button, Icon, Divider, Row, Col, Drawer, Popconfirm, message} from 'antd'
import AddUserLevel from './AddUserLevel'
import UpdateUserLevel from '../userlevel/UpdateUserLevel'
import CurrencyFormat from 'react-currency-format'
import {connect} from 'react-redux'
const Container = styled.div``
class ListUserLevel extends React.Component {
  state = {
    data: [],
    Status:true,
    loading: false,
    createUserLevel: false,
    updateUserLevel: false,
    showDrawer:false
  };
  componentDidMount() {
    this.getListUserLevel();
  }
  getListUserLevel() {
    const query = `query userlevels($filter: UserLevelFilter){
      userLevels(filter:$filter){
        id
        name
        order_total
        discount
        status
        totalUser
        creater{
          id
          first_name
          last_name
          email
        }
      }
    }`
    this.setState({ loading: true })
    gql.request(query, {
      filter: {status:true}
    }).then(res => {
      this.setState({
        data: res.userLevels || [],
        loading: false
      })
    })
  }

  deleteUserLevel = id => {
    this.setState({ loading: false })
    const mutation = `
        mutation deleteUserLevel($id: Int!){
          deleteUserLevel(id: $id)
        }
      `;
    gql.request(mutation, { id })
      .then(
        res => {
          this.setState({ Status: true })
          if (res.deleteUserLevel) {
            message["success"]("Delete success!");
            this.getListUserLevel();
          } else {
            message["error"](_.get(res.errors, "[0].message"));
          }
        })
      .catch(err => {
        this.setState({ loading: false })
        message["error"](_.get(err, "[0].message"));
      });
  };
  render(){
    const { loading } = this.state
    const isAdmin = this.props.currentUser.roles.find(el => ['Administrator', 'Listing'].includes(el.name))
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 50,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 150
      },
      {
        title: 'User' ,
        dataIndex: 'totalUser',
        key: 'totalUser',
      },
      {
        title: 'Total Order',
        dataIndex: 'order_total',
        key: 'orderTotal',
        width: 120,
        align: 'right',
        render: (text) =>(
          <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/>
        )
      },
      {
        title: 'Discount (USD)',
        dataIndex: 'discount',
        key: 'discount',
        width: 140,
        align: 'right',
        render: (text) =>(
          <CurrencyFormat decimalScale={2} fixedDecimalScale={true} value={text} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        )
      },
      isAdmin?
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        width: 120,
        render: (text, record) => (
          <Container>
            <Button size="small" onClick={() => { this.setState({ updateUserLevel: record, showDrawer:true }) }}>
              <Icon type="edit" style={{color:"#1890ff"}}/>
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure delete this level?"
              onConfirm={() => this.deleteUserLevel(record.id)}
              okText="Yes"
              cancelText="No">
              <Button size="small"
                onClick={() => {
                  console.log(record.id);
                }}>
                <Icon type="delete" style={{color:"red"}}/>
              </Button>
            </Popconfirm>
          </Container>
        )
      }:{}
    ]
  return (
    <Container>
      <div>
        {isAdmin&&(
        <Row>
          <Col style={{ textAlign: "right", marginBottom: 15 }}>
            <Button type="primary" onClick={() => {
              this.setState({ createUserLevel: true })
            }}><Icon type="plus" /> New Level</Button>
          </Col>
        </Row>
        )}
        <Table
          columns={columns }
          dataSource={this.state.data}
          loading={loading}
          rowKey={(text, index) => index}
          />
          <Drawer
              visible={this.state.createUserLevel}
              title="Add Level"
              placement="right" 
              onClose={() => {
                this.setState({
                  createUserLevel: false
                })
              }} 
              >
              <AddUserLevel onClose={()=> {this.setState({createUserLevel:false})}} 
              onLoad={() => this.getListUserLevel()}/>
          </Drawer>
          <Drawer
              visible={this.state.showDrawer}
              placement="right"
              title="Update Level"
              onClose={() => { this.setState({ showDrawer: false }) }}>
              <UpdateUserLevel userlevel={this.state.updateUserLevel} onLoad={() => this.getListUserLevel()} 
              onClose={() => { this.setState({ showDrawer: false }) }}>
              </UpdateUserLevel>
        </Drawer>
      </div>
    </Container>
  )
  }
}
export default connect(
  state => ({
    currentUser: state.app.currentUser
  })
)
(ListUserLevel)
