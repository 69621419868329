import React, { Component } from 'react'
import { Table } from 'antd'
import gql from '../../api/gql'
import { Link } from 'react-router-dom'

export default class Customers extends Component {
  state = {
    customers: [],
    total: 0,
    filter: {
      offset: 0,
      limit: 20,
      ...this.props.filter
    },
    page: 1,
    loading: false
  }

  componentDidMount() {
    this.loadCustomers()
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.state
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        page: 1,
        filter: { ...filter, offset: 0, ...this.props.filter }
      }, () => this.loadCustomers())
    }
  }


  loadCustomers() {
    const query = `query customers($filter: CustomerFilter){
      customers(filter: $filter){
        count
        hits{
          id
          first_name
          last_name
          email
          phone
          address{country_code, country}
        }
      }
    }`
    this.setState({ loading: true })
    gql.request(query, {
      filter: this.state.filter
    }).then(res => {
      this.setState({
        customers: res.customers.hits || [],
        total: res.customers.count,
        loading: false
      })
    })
  }

  render() {
    const { customers, total, filter, loading, page } = this.state
    const columns = [
      {
        title: 'Name',
        dataIndex: 'id',
        key: 'name',
        render:  (id, row) => <Link to={`/customers/${id}`}>{`${row.first_name} ${row.last_name}` }</Link>
      },
      {
        title: 'Email',
        dataIndex: 'email',
        //render: (p) => p ? p.title : null
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        //render: (p) => p ? p.title : null
      },
      {
        title: 'Location',
        dataIndex: 'address',
        key: 'location',
        render: (address) => address.country ? address.country : address.country_code
      },
      // {
      //   title: 'Email',
      //   dataIndex: 'customer.email'
      // },
      // {
      //   title: 'Date',
      //   dataIndex: 'created_at',
      //   render: (created_at) => moment(created_at).format('MMM DD, YYYY')
      // },
      // {
      //   title: 'Status',
      //   dataIndex: 'status'
      // }
    ]
    const pagination = {
      pageSize: filter.limit,
      total: total,
      current: page,
      showTotal: (total, range) => `${range} of ${total}`,
      onChange: (page, pageSize) => {
        this.setState({
          page: page,
          filter: {
            ...filter,
            offset: (page - 1) * pageSize
          }
        }, () => this.loadCustomers())
      }
    }
    return (
      <div>
        <Table columns={columns} dataSource={customers} pagination={pagination} rowKey={(text, index) => index} loading={loading} />
      </div>
    )
  }
}