import React from 'react'
import { connect } from 'react-redux'
import { Button, notification, Drawer, Select } from 'antd'
//import { AWS_CLOUDFRONT_URL } from '../../config'
import ImportTrackings from '../tracking/ImportTracking'
import gql from '../../api/gql'
import _ from 'lodash'
import { setOrderId } from '../../actions'
import FetchOrders from './FetchOrders'

class OrdersToolbar extends React.Component {

  state = {
    loading: false,
    showFetchOrders: false,
    currentSite: null,
    siteList: [],
    loadingSiteLoad: true,
    SiteFilter:{
      limit:999
    }
  }

  getSites(){
    let getSiteAll = `query sites($filter: SiteFilter){
      sites(filter: $filter){
        hits{
          id
          title
        }
      }
    }`;
    gql.request(getSiteAll,{
      filter:this.state.SiteFilter
    }).then(res => {
      this.setState({
        siteList: res.sites.hits,
        currentSite: res.sites.hits[0] ? res.sites.hits[0].id : null,
        loadingSiteLoad: false
      });
    })
  }

  exportOrders = e => {
    e.preventDefault()
    this.setState({
      loading: true
    })
    const { filter, currentUser } = this.props
    let isSupplier = currentUser.roles.find(role => ['Supplier'].includes(role.name))
    let query = `mutation exportOrders($filter: OrderFilter){
      exportOrders(filter: $filter)
    }`
    let supplier = `mutation supplierExportOrder($filter: OrderFilter){
      supplierExportOrder(filter: $filter)
    }`

    gql.request(isSupplier ? supplier : query, {
      filter: { ...filter, order_id: this.props.order_id }
    }).then(res => {
      window.location = `${isSupplier ? res.supplierExportOrder : res.exportOrders}`
      this.setState({
        loading: false
      })
      this.props.setOrderId(null)
    }).catch(err => {
      this.setState({
        loading: false
      })
      notification['error']({
        message: _.get(err, '[0].message')
      })
    })
  }

  render() {
    const { loading, showFetchOrders, currentSite, siteList, loadingSiteLoad} = this.state
    const { currentUser,filter,onChange } = this.props

    const { Option } = Select;
    
    let isSeller = currentUser.roles.find(role => ['Seller'].includes(role.name))
    let isAdmin = currentUser.roles.find(role => ['Administrator'].includes(role.name))
    const status = _.get(filter, 'status', [])
    return (
      <div style={{ display: 'flex', justifyContent: "flex-end", marginBottom: 10, marginLeft: 5}}>
        {!isSeller && <ImportTrackings onChange={() => onChange()}/>}
        <Button onClick={this.exportOrders} disabled= {!status || (status && status.length===0)} loading={loading} style={{ marginLeft: 5}} type="primary" icon="export">Export Orders</Button>
        {isAdmin &&
          <Button
              style={{ marginLeft: 5 }}
              type="primary" onClick={() => {
            if(siteList.length <= 0){
              this.getSites();
            }
            this.setState({ showFetchOrders: true })

          }}
          >
            Fetch Orders
          </Button>
        }
        <Drawer
            width="500px"
            title="Fetch Orders"
            onClose={e => {
              this.setState({ showFetchOrders: false })
              // this.loadOrders()
            }}
            visible={showFetchOrders}
          >
            {!loadingSiteLoad &&
              <>
              <label>Select site: </label>
                <Select 
                  defaultValue={currentSite}
                  style={{
                    width: "100%",
                    marginBottom: 5
                  }}
                  onChange={(val) => this.setState({ currentSite: val })}
                >
                  {siteList.map(e => (<Option key={e.id} value={e.id}>{e.title}</Option>))}
                </Select>
                <FetchOrders site_id={currentSite} onClose={e => this.setState({ showFetchOrders: false })} />
              </>
              }
          </Drawer>
      </div>
    )
  }
}

export default connect(
  state => ({
    currentUser: state.app.currentUser,
    filter: state.orders.filter,
    order_id: state.orders.order_id
  }), { setOrderId }
)(OrdersToolbar)