import React from "react";
import { gql } from "apollo-boost";
import gql_request from "../../api/gql";
import { Query } from "@apollo/react-components";
import VariantCarrierCost from "./VariantCarrierCost";
import {
  Button,
  Input,
  Modal,
  notification,
  Switch,
  Table,
  Card,
  Form,
  Divider,
} from "antd";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import styled from "styled-components";
import ProductTypeVariantSelectModal from "./ProductTypeVariantSelectModal";
import ProductVariantCostField from "./ProductVariantCostField";
import VariantSupplierCost from "./VariantSupplierCost";
import { history } from "../../history";
import _ from 'lodash';
import VariantSupplierProductVariant from "./VariantSupplierProductVariant";
// // import PrintFilesProductTypeVariant from "./PrintFilesProductTypeVariant";
import ProductTypePrintFiles from "../product_type/PrintFiles";

const Q = gql`
  query get($productTypeId: Int!) {
    productTypeById(id: $productTypeId) {
      title
      sku
      description
      print_files{
        key
        title
        mockup
        width
        height
        note
        variant_ids
      }
      attributes {
        name
        slug
        options
      }
      suppliers {
        id
        first_name
        last_name
        api_support
      }
      product_type_carriers{
        carrier_id
        price
        default
        carrier{
          name
        }
      }

    }
    productTypeVariants(product_type_id: $productTypeId) {
      id
      sku
      attributes {
        option
        name
        slug
      }
      supplier_product_variants{
        id
        user_id
        supplier_product_variant_id
      }
      supplier_costs {
        id
        user_id
        cost
      }
      carrier_costs {
        id
        carrier_id
        cost
        default
      }
      retail_cost
      base_cost
      sale_cost
      is_active
    }
  }
`;

const Container = styled.div`
  .actions {
    display: flex;
    .left-action {
      flex-grow: 1;
    }
    span {
      margin: 5px;
    }
  }
  tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }

  tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
  .supplier-cost-container{
    display: flex;
    .ant-form-item{
      margin-right: 10px;
    }
  }
  @media only screen and (max-width: 540px) {
    .actions {
      display: grid;
      padding: 0 5px;
      .left-action {
        margin-bottom: 15px;
        .ant-btn{
          padding: 0 5px;
        }
      }
    }
  }
`;
const FormContainer = styled.div`
  display: flex;
  .sku-container {
    @media (min-width: 992px) {
      min-width: 250px;
    }
  }
  div.cost {
    margin-left: 10px;
  }
`;

let permutationArr = [];
let draggingIndex = -1;

const JOIN = ":";

class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > draggingIndex) {
        className += " drop-over-downward";
      }
      if (restProps.index < draggingIndex) {
        className += " drop-over-upward";
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}

const rowSource = {
  beginDrag(props) {
    draggingIndex = props.index;
    return {
      index: props.index
    };
  }
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) {
      return;
    }
    props.moveRow(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  }
};

const DraggableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
}))(
  DragSource("row", rowSource, connect => ({
    connectDragSource: connect.dragSource()
  }))(BodyRow)
);

class ProductTypeVariants extends React.Component {
  state = {
    attributes: [],
    dataSource: [],
    loading: false,
    suppliers: [],
    carriers: [],
    productTypeSKU: "",
    modal: {
      visible: false,
      selected: [],
      missingVariants: []
    },
    updateproductTypeById: {}
  };
  components = {
    body: {
      row: DraggableBodyRow
    }
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { dataSource } = this.state;
    const dragRow = dataSource[dragIndex];

    this.setState(
      update(this.state, {
        dataSource: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        }
      })
    );
  };

  heapPermutation = (options = [], size = 0) => {
    if (size === 1) {
      // print
      let items = [];
      for (let i = 0; i < options.length; i++) {
        items.push(options[i]);
      }
      permutationArr.push(items.join(JOIN));
      return permutationArr;
    }

    for (let i = 0; i < size; i++) {
      this.heapPermutation(options, size - 1, options.length);
      if (size % 2 === 1) {
        const tmp = options[0];
        options[0] = options[size - 1];
        options[size - 1] = tmp;
      } else {
        const tmp = options[i];
        options[i] = options[size - 1];
        options[size - 1] = tmp;
      }
    }
  };
  getCombine = () => {
    const { attributes, productTypeSKU } = this.state;
    let res = [];
    const n = attributes.length;
    let indices = [];
    let sortingIncrement = this.state.dataSource.length;
    // fill zero
    for (let i = 0; i < n; i++) {
      indices[i] = 0;
    }
    while (true) {
      let options = [productTypeSKU];
      let attrs = [];
      for (let i = 0; i < n; i++) {
        const option = attributes[i].options[indices[i]];
        options.push(option);
        attrs.push({
          name: attributes[i].name,
          slug: attributes[i].slug,
          option: option
        });
      }
      const sku = options.join(JOIN);
      sortingIncrement++;
      res.push({
        sku: sku,
        attributes: attrs,
        base_cost: null,
        retail_cost: null,
        sale_cost: null,
        is_active: true,
        sorting: sortingIncrement
      });

      let next = n - 1;
      while (
        next >= 0 &&
        indices[next] + 1 >= attributes[next].options.length
      ) {
        next--;
      }

      if (next < 0) {
        break;
      }
      indices[next]++;
      for (let i = next + 1; i < n; i++) {
        indices[i] = 0;
      }
    }

    return res;
  };
  getMissingVariants = () => {
    const { dataSource, attributes, productTypeSKU } = this.state;

    if (attributes.length === 0) {
      notification.warning({ message: "There is no attribute." });
      return [];
    }
    let dataSourceByOptionsMap = {};
    for (let i = 0; i < dataSource.length; i++) {
      let options = [productTypeSKU];
      for (let j = 0; j < dataSource[i].attributes.length; j++) {
        options.push(dataSource[i].attributes[j].option);
      }
      permutationArr = [];
      this.heapPermutation(options, options.length);
      for (let j = 0; j < permutationArr.length; j++) {
        const key = permutationArr[j];
        dataSourceByOptionsMap[key] = dataSource[i];
      }
    }
    const combine = this.getCombine();
    let missingVariants = [];
    for (let i = 0; i < combine.length; i++) {
      if (typeof dataSourceByOptionsMap[combine[i].sku] === "undefined") {
        missingVariants.push(combine[i]);
      }
    }

    return missingVariants;
  };

  addMissingVariants = () => {
    const { dataSource } = this.state;
    const missingVariants = this.getMissingVariants();
    if (missingVariants.length > 0) {
      this.setState({
        dataSource: [...dataSource, ...missingVariants]
      });
    }
  };

  handleSave = client => {
    const { dataSource, carriers } = this.state;
    const productTypeId = parseInt(this.props.match.params.id);
    const args = [];
    const mutationArr = [];
    let variables = {};
    for (let i = 0; i < dataSource.length; i++) {
      const item = dataSource[i];
      item.attributes = item.attributes.map(it => {
        if (typeof it.__typename !== "undefined") {
          delete it.__typename;
        }
        return it;
      });
      if (typeof item.__typename !== "undefined") {
        delete item.__typename;
      }
      if (item.supplier_costs && item.supplier_costs.length) {
        item.supplier_costs = item.supplier_costs.map(sc => {
          if (typeof sc.__typename !== "undefined") {
            delete sc.__typename;
          }
          return sc;
        });
      }
      if (item.carrier_costs && item.carrier_costs.length) {
        item.carrier_costs = item.carrier_costs.map(cc => {
          if (typeof cc.__typename !== "undefined") {
            delete cc.__typename;
          }
          if (cc.cost === null) {
            cc.cost = carriers.filter(f => f.carrier_id === cc.carrier_id)[0].price
          }
          return cc;
        });
      }
      if (item.supplier_product_variants && item.supplier_product_variants.length) {
        item.supplier_product_variants = item.supplier_product_variants.map(sc => {
          if (typeof sc.__typename !== "undefined") {
            delete sc.__typename;
          }
          return sc;
        });
      } else if (carriers && carriers.length > 0) {
        item.carrier_costs = carriers.map(carrier => {
          return {
            carrier_id: carrier.carrier_id,
            cost: carrier.price,
            default: carrier.default
          }
        });
      }

      item.productTypeId = productTypeId;
      item.sorting = i + 1;
      variables[`input_${i}`] = item;
      args.push(`$input_${i}: NewProductTypeVariant!`);
      mutationArr.push(`m_${i}: createProductTypeVariant(input: $input_${i}){
      id
      sku
      attributes{
          option
          name
          slug
      }
      supplier_product_variants{
        id
        user_id
        supplier_product_variant_id
      }
      supplier_costs{
          id
          user_id
          cost
      }
      carrier_costs {
        id
        carrier_id
        cost
        default
      }
      retail_cost
      base_cost
      sale_cost
      is_active
      }`);
    }

    const q = `mutation execute(${args.join(",")}){
      ${mutationArr.join("\n")}
    }`;
    client
      .mutate({
        mutation: gql`
          ${q}
        `,
        variables
      })
      .then(res => {
        notification.success({ message: "Variants has been saved!" })
        if (res.data) {
          for (let i = 0; i < dataSource.length; i++) {
            dataSource[i] = res.data[`m_${i}`];
          }
          this.setState(
            {
              loading: false,
              dataSource
            },
            () => {
              // handle save cache
              const cache = client.readQuery({
                query: Q,
                variables: {
                  productTypeId: this.props.match.params.id
                }
              });

              client.writeQuery({
                query: Q,
                variables: {
                  productTypeId: this.props.match.params.id
                },
                data: {
                  ...cache,
                  productTypeVariants: dataSource
                }
              });
            });
          this.saveProductTypePrintFiles(res.data)
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(e => {
        this.setState({ loading: false });
        notification.error({ message: e.toString() });
      });
  };

  saveProductTypePrintFiles = (result) => {
    console.log("result", result)
    var variantActive = Object.keys(result).map(function (key) {
      return result[key];
    });
    this.props.form.validateFields((err, values) => {
      // let new_print_files = Object.assign({}, values);
      // console.log('new_print_files', new_print_files);
      // new_print_files.print_files = [];
      values.print_files && values.print_files.forEach((print_file, index) => {
        print_file.variant_ids && print_file.variant_ids.forEach((print_file_variant, index2) => {
          variantActive.forEach((variant) => {
            if (print_file_variant.sku === variant.sku) {
              print_file.variant_ids[index2].id = variant.id; ////
            }
          });
        });
        values.print_files[index] = print_file;
      });

      //// get int variant id
      let print_files = Object.assign([], values.print_files);
      let print_files_push = print_files.map((print_file) => {
        let new_variants = []
        if (print_file.variant_ids) {
          new_variants = print_file.variant_ids.map((variant, i) => {
            return variant.id;
          });
        }

        return {
          key: print_file.key,
          height: print_file.height,
          mockup: print_file.mockup,
          note: print_file.note,
          title: print_file.title,
          width: print_file.width,
          variant_ids: new_variants
        }
      });
      if (!err) {
        const { id } = this.props.match.params
        if (id) {
          var query = `mutation updateProductTypePrintFiles($product_type_id: Int! $print_files: [NewProductTypePrintFile!]){
            updateProductTypePrintFiles(product_type_id: $product_type_id, print_files: $print_files)
          }`
        }
        this.setState({ loading: true })
        gql_request.request(query, {
          product_type_id: id,
          print_files: print_files_push
        }).then(res => {
          notification['success']({
            message: 'Product type has been update'
          })
          this.setState({ loading: false })
          history.push('/admin/product-types')
        }).catch(err => {
          notification['error']({
            message: 'ERROR',
            description: _.get(err, '[0].message')
          })
          this.setState({ loading: false })
        })
      }
    })
  }

  render() {
    const { attributes, dataSource, suppliers, carriers, updateproductTypeById } = this.state;
    updateproductTypeById && updateproductTypeById.print_files && updateproductTypeById.print_files.forEach((print_file, index) => {
      let init_variants = print_file.variant_ids && print_file.variant_ids.map((variant_id) => {
        return dataSource.find(el => el.id === variant_id);
      });
      init_variants && init_variants.forEach((init_variant, key) => {
        if (!_.isUndefined(init_variant)) {
          updateproductTypeById.print_files[index].variant_ids[key] = init_variant;
        }
      });
    });

    const { getFieldDecorator } = this.props.form
    const { match } = this.props;
    const { id } = match.params;
    return (
      <Query
        fetchPolicy="network-only"
        onCompleted={data => {
          this.setState({
            attributes: data.productTypeById.attributes
              ? data.productTypeById.attributes
              : [],
            suppliers: data.productTypeById.suppliers,
            dataSource: data.productTypeVariants,
            productTypeSKU: data.productTypeById.sku,
            carriers: data.productTypeById.product_type_carriers,
            updateproductTypeById: data.productTypeById
          });
        }}
        query={Q}
        variables={{ productTypeId: id }}
      >
        {({ error, loading, client }) => {
          if (error) return <div>{error.toString()}</div>;
          if (loading) return <div>loading</div>;
          let columns = [
            {
              width: 250,
              title: 'Variant[SKu]',
              key: 'Variant[SKu]',
              render: (_, record, index) =>
                <div key={index}>
                  {record.attributes.map(attr => attr.option).join('/')}
                  <Input style={{ marginTop: 5 }}
                    onChange={e => {
                      dataSource[index].sku = e.target.value;
                    }}
                    defaultValue={record.sku} />
                </div>
            }
          ]

          columns.push({
            dataIndex: "suppliers",
            key: "suppliers",
            width: 250,
            title: "Supplier[Cost]",
            render: (_, record, index) => (
              <VariantSupplierCost
                onChange={updateSupplierCosts => {
                  dataSource[index].supplier_costs = updateSupplierCosts;
                  this.setState({ dataSource });
                }}
                supplierCosts={
                  record.supplier_costs ? record.supplier_costs : []
                }
                suppliers={suppliers}
              />
            )
          });
          columns.push({
            dataIndex: "supplier_product_variants",
            key: "supplier_product_variants",
            width: 250,
            title: "Supplier[MappingID]",
            render: (_, record, index) => (
              <VariantSupplierProductVariant
                onChange={updateSupplierCosts => {
                  dataSource[index].supplier_product_variants = updateSupplierCosts;
                  this.setState({ dataSource });
                }}
                supplierProductVariants={
                  record.supplier_product_variants ? record.supplier_product_variants : []
                }
                suppliers={suppliers}
              />
            )
          });
          columns.push({
            dataIndex: "carriers",
            key: "carriers",
            width: 250,
            title: "Carriers[Cost]",
            render: (_, record, index) => (
              <VariantCarrierCost
                onChange={updateCarrierCosts => {
                  dataSource[index].carrier_costs = updateCarrierCosts;
                  this.setState({ dataSource });
                }}
                carrierCosts={
                  record.carrier_costs ? record.carrier_costs : []
                }
                carriers={carriers}
              />
            )
          });

          columns.push({
            dataIndex: "form",
            key: "form",
            title: "Seller",
            width: 300,
            render: (_, record, index) => (
              <div>
                <FormContainer>
                  <div className={"cost base-cost"}>
                    <ProductVariantCostField
                      value={record.base_cost}
                      onChange={value => {
                        dataSource[index].base_cost = value;
                        this.setState({
                          dataSource
                        });
                      }}
                      label={"Base Cost"}
                    />
                  </div>
                  <div className={"cost retail-cost"}>
                    <ProductVariantCostField
                      value={record.retail_cost}
                      onChange={value => {
                        dataSource[index].retail_cost = value;
                        this.setState({
                          dataSource
                        });
                      }}
                      label={"Retail Cost"}
                    />
                  </div>
                  <div className={"cost sale-cost"}>
                    <ProductVariantCostField
                      value={record.sale_cost}
                      onChange={value => {
                        dataSource[index].sale_cost = value;
                        this.setState({
                          dataSource
                        });
                      }}
                      label={"Sale Cost"}
                    />
                  </div>
                </FormContainer>
              </div>
            )
          });

          columns.push({
            dataIndex: "actions",
            key: "actions",
            width: 100,
            title: "Status",
            align: 'center',
            render: (_, record, index) => (
              <Switch
                size="small"
                onChange={value => {
                  dataSource[index].is_active = value;
                  this.setState({ dataSource });
                }}
                checked={record.is_active}
              />
            )
          });

          const tableWidth = _.sum(columns.map(c => c.width))

          return (
            <Container>
              <DndProvider backend={HTML5Backend}>
                <Table
                  onRow={(record, index) => ({
                    index,
                    moveRow: this.moveRow
                  })}
                  components={this.components}
                  rowKey={record => record.sku}
                  locale={{
                    emptyText: (
                      <div>
                        <p>No variants added.</p>
                      </div>
                    )
                  }}
                  scroll={{ x: tableWidth }}
                  pagination={false}
                  dataSource={dataSource}
                  columns={columns}
                  className={"product-variants"}
                  footer={() => (
                    <div className={"actions"}>
                      <div className={"left-action"}>
                        <Button
                          onClick={() => this.addMissingVariants()}
                          icon={"plus"}
                        >
                          Add all missing variants
                        </Button>
                        <span>Or</span>
                        <Button
                          onClick={() => {
                            const missingVariants = this.getMissingVariants();
                            if (missingVariants.length > 0) {
                              this.setState({
                                modal: {
                                  ...this.state.modal,
                                  visible: true,
                                  missingVariants
                                }
                              });
                            } else {
                              notification.info({
                                message: "No missing variants"
                              });
                            }
                          }}
                        >
                          Select Variants
                        </Button>
                      </div>

                    </div>
                  )}
                />
              </DndProvider>
              <Card style={{ marginTop: 20 }} title="Print Files">
                <Form onSubmit={this.saveProductTypePrintFiles}>
                  <Form.Item>
                    {getFieldDecorator('print_files', {
                      initialValue: updateproductTypeById ? updateproductTypeById.print_files ? updateproductTypeById.print_files : [] : []
                    })(<ProductTypePrintFiles variantActive={dataSource} />)}
                  </Form.Item>
                </Form>

              </Card>
              {this.state.modal.visible && (
                <Modal
                  className="product-type-modal"
                  width={700}
                  title={"Select variants"}
                  onOk={() => {
                    if (this.state.modal.selected.length) {
                      this.setState({
                        modal: {
                          ...this.state.modal,
                          visible: false,
                          selected: []
                        },
                        dataSource: [
                          ...dataSource,
                          ...this.state.modal.selected
                        ]
                      });
                    }
                  }}
                  onCancel={() => {
                    this.setState({
                      modal: {
                        ...this.state.modal,
                        visible: false
                      }
                    });
                  }}
                  visible={this.state.modal.visible}
                >
                  <ProductTypeVariantSelectModal
                    onChange={variants => {
                      this.setState({
                        modal: {
                          ...this.state.modal,
                          selected: variants
                        }
                      });
                    }}
                    variants={this.state.modal.missingVariants}
                    attributes={attributes}
                  />
                </Modal>
              )}
              <div style={{ marginTop: 20 }}>
              </div>
              <Button
                loading={this.state.loading}
                onClick={() => {
                  if (!dataSource.length) {
                    return;
                  }
                  this.setState({ loading: true });

                  this.handleSave(client);
                }}
                type={"primary"}
                icon={"save"}
              >
                Save
            </Button>
              <Divider type="vertical" />
              <Button icon="undo" onClick={() => history.goBack()}>Cancel</Button>
            </Container>
          );
        }}
      </Query>
    );
  }
}
export default Form.create({ name: 'product-type-variant-form' })(ProductTypeVariants)
