import React, { Component } from 'react'
import gql from '../../api/gql'
import { message, Form, Input, Button, Card } from 'antd'
import * as _ from "lodash"

class ChangePassword extends Component {
  state = {
    loading: false,
    currentPassword: ""
  }

  changePassword(id, current_password, new_password) {
    this.setState({ loading: true })
    const mutation = `mutation changePassword($id:Int! $current_password: String, $new_password: String!){
      changePassword(id: $id current_password: $current_password new_password: $new_password )
      }`
    gql.request(mutation, { id, current_password, new_password })
      .then(res => {
        this.setState({ loading: false })
        message["success"]("Your password updated");
      })
      .catch(err => {
        this.setState({ loading: false })
        message["error"](_.get(err, "[0].message"));
      })
  }

  handleConfirmBlur = e => {
    const { values } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!values });
  };

  compareToFirstPassword = (rule, values, callback) => {
    const { form } = this.props;
    if (values && values !== form.getFieldValue("new_password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  onSubmit = e => {
    e.preventDefault();
    const { currentUser } = this.props;
    this.props.form.validateFields((err, values) => {
      delete values.confirm;
      delete values.current;
      if (values.pass === "") {
        delete values.pass;
      }
      if (!err) {
        if (currentUser.id) {
          this.changePassword(currentUser.id, values.current_password, values.new_password)
        }
      }
    });
  };

  render() {
    const { form: { getFieldDecorator } } = this.props;
    return (
      <Form
        layout={"vertical"}
        hideRequiredMark
        onSubmit={this.onSubmit}>
        <Card>
          <Form.Item label="Current password">
            {getFieldDecorator('current_password', {
              initialValue: "",
              rules: [{ required: true, message: "Please enter current password" }]
            })(
              <Input autoComplete="current-password" type="password" />
            )}
          </Form.Item>
          <Form.Item label="New password">
            {getFieldDecorator('new_password', {
              initialValue: "",
              rules: [{ required: true, message: "Please enter confirm password" }]
            })(
              <Input autoComplete="new-password" type="password" />
            )}
          </Form.Item>
          <Form.Item label="Retype password">
            {getFieldDecorator('confirm', {
              initialValue: "",
              rules: [
                { required: true, message: "Please enter confirm password" },
                {
                  validator: this.compareToFirstPassword
                }
              ],
            })(
              <Input autoComplete="confirm" type="password" onBlur={this.handleConfirmBlur} />
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={this.state.loading}>
              Submit
            </Button>
          </Form.Item>
        </Card>
      </Form>
    )
  }
}

export default Form.create({ name: "change_password" })(ChangePassword);