import React, { Component } from 'react'
import { Form, Button, Menu, Row, Col, notification, Card } from 'antd'
import gql from '../../api/gql'
import TextArea from 'antd/lib/input/TextArea'

class NotiticationSetings extends Component {
  state = {
    init: false,
    menukey:"1",
    settings: {
      order_received_message: '',
      order_producing_message: '',
      order_shipping_message: '',
      order_tracking_changed_message: '',
    }
  }

  componentDidMount() {
    this.loadSiteSettings()
  }

  loadSiteSettings() {
    let query = `query getAppSettings($input: [String!]){
      getAppSettings(input: $input){
        name
        value
      }
    }`

    gql.request(query, {
      input: ['order_received_message', 'order_producing_message', 'order_shipping_message', 'order_tracking_changed_message']
    }).then(res => {
      const {settings} = this.state
      res.getAppSettings.forEach(s => {
        settings[s.name]= s.value
      })
      this.setState({
        settings: settings,
        init: true
      })
    })
  }

  handleClickMenu = (e) => {
    this.setState({menukey: e.key})
  }

  onChangeChecked = (key, checked) =>{
    const {settings} = this.state
    settings[key] = checked.target.checked
    this.setState({settings:{...settings}})
  }

  render() {
    const { settings, menukey, init} = this.state
    const { getFieldDecorator } = this.props.form
    const { onSubmit } = this.props
    if (!init) return false
    return (
      <Form onSubmit={e => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
          if (!err) {
            onSubmit(values)
          } else {
            notification.error({ message: 'Form is invalid' })
          }
        })
      }}>
          <Row>
            <Col span={24} lg={4} style={{ display: 'flex'}}>
              <Menu
                onClick={this.handleClickMenu}
                style={{ width: 256,float:"left", background: "rgb(247, 249, 251)" }}
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                mode='inline'
              >
                <Menu.Item key="1">
                  Order received
                </Menu.Item>
                <Menu.Item key="2">
                  Order start producing
                </Menu.Item>
                <Menu.Item key="3">
                  Order shipped
                </Menu.Item>
                <Menu.Item key="4">
                  Order Tracking Changed
                </Menu.Item>
              </Menu>
            </Col>
            <Col span={24} lg={20} >
            <Card title="Notification Message" style={{marginBottom: 20}}>
              <div style={menukey === "1" ? {display:"block"} : {display:"none"}}>
                <Form.Item label="Message">
                  {
                    getFieldDecorator('order_received_message', {
                      initialValue: settings.order_received_message
                    })(<TextArea/>)
                  }
                </Form.Item>
              </div>
              <div style={menukey === "2" ? {display:"block"} : {display:"none"}}>
              <Form.Item label="Message">
                {
                  getFieldDecorator('order_producing_message', {
                    initialValue: settings.order_producing_message
                  })(<TextArea/>)
                }
              </Form.Item>
              </div>
              <div style={menukey === "3" ? {display:"block"} : {display:"none"}}>
                <Form.Item label="Message">
                  {
                    getFieldDecorator('order_shipping_message', {
                      initialValue: settings.order_shipping_message
                    })(<TextArea/>)
                  }
                </Form.Item>
              </div>
              <div style={menukey === "4" ? {display:"block"} : {display:"none"}}>
                <Form.Item label="Message">
                  {
                    getFieldDecorator('order_tracking_changed_message', {
                      initialValue: settings.order_tracking_changed_message
                    })(<TextArea/>)
                  }
                </Form.Item>
              </div>
              <Form.Item>
                <Button loading={this.state.loading} type="primary" htmlType="submit">Save</Button>
              </Form.Item>
              </Card>
            </Col>
          </Row>
      </Form>
    )
  }
}

export default Form.create({ name: 'site_settings_form' })(NotiticationSetings)