import { Router, Switch } from 'react-router-dom'
import { history } from './history'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DefaultLayout from './layouts/default'
import LoginLayout from './layouts/login'
import { Spin, Button } from 'antd'
import gql from './api/gql'
import { setDestination } from './actions'
import LoginPage from './pages/user/Login'
import UsersManagerPage from './pages/admin/UsersManager'
import SitesManagerPage from './pages/sites/SitesManager'
import Page404 from './pages/404'
import Register from './pages/user/Login' // Page Register redirect to Login
import ProfilePage from './pages/user/Profile'
import Blank from './layouts/blank'
import Orders from './pages/orders'
import Products from './pages/products'
import Customers from './pages/customers'
import ForgotPassword from './pages/user/ForgotPassword'
import ForgotPasswordEmail from './pages/user/ForgotPasswordEmail'
import CustomerDetail from './components/customers/CustomerDetail'
import DetailOrder from './pages/orders/orders-manager/detailsOrder'
import EditProduct from './pages/products/edit'
import ProductTypesPage from './pages/product_types'
import ProductTypeForm from './pages/product_types/form'
import ProductTypeVariants from './components/products/ProductTypeVariant'
import Carrier from './pages/carrier/Carrier'
import Balance from './pages/balance/balance'
import UserLevelPage from './pages/userlevel/UserLevel'
import NewsPage from './pages/news/News'
import Categories from './pages/categories'
import UserForm from './components/admin/UserForm'
import ChangePassword from './pages/user/ChangePassword'
import UserEdit from './components/admin/UserEdit'
import UpdateNews from './components/news/UpdateNews'
import AddNews from './components/news/AddNews'
import PushStepOne from './components/products/PushStepOne'
import PushStepTwo from './components/products/PushStepTwo'
import CategoryForm from './components/categories/form'
import SiteSettings from './pages/sites/SiteSettings'
import Settings from './pages/admin/settings'
import ExportOrder from './pages/export_order'
import DashboardGPT from "./pages/tool_gpt/dashboardGPT";
// import Dashboards from './components/dashboard/Dashboards'
import Dashboard from "./components/admin/Dashboard"
import DetailNews from './components/news/DetailNews'
import { EventEmitter } from 'fbemitter'
import MapProducts from './components/products/MapProducts'
import Verification from './pages/verification'
import ProductToolbar from './components/products/ProductToolbar'
import CategoryToolbar from './components/categories/CategoryToolbar'
import ProductTypeToolbar from './components/product_type/ProductTypeToolbar'
import ImportOrders from './components/orders/ImportOrders';
import EggSitesList from './components/egg/EggSitesList';
import ExportOrderTemplate from './pages/manager-files/export-template'
import ExportTemplateForm from './components/manager_files/ExportTemplateForm'
export const emitter = new EventEmitter()

const routers = [
  {
    path: '/',
    component: LoginPage,
    exact: true,
    layout: LoginLayout,
    title: 'Login - OOPPRINT Group',
  },
  {
    path: '/login',
    component: LoginPage,
    exact: true,
    layout: LoginLayout,
    title: 'Login'
  },
  {
    path: '/register',
    component: Register,
    exact: true,
    layout: LoginLayout,
    title: 'Register'
  },
  {
    path: '/forgot-password-confirm-email',
    component: ForgotPasswordEmail,
    exact: true,
    layout: LoginLayout,
    title: 'Forgot Password - Confirm Your Email'
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    exact: true,
    layout: LoginLayout,
    title: 'Forgot Password - Reset Your Password'
  },
  {
    path: '/profile',
    component: Auth(ProfilePage),
    exact: true,
    title: 'My Profile'
  },
  {
    path: '/change-password',
    component: Auth(ChangePassword),
    exact: true,
    title: 'Change Password'
  },
  {
    path: '/dashboard',
    component: Auth(Dashboard),
    exact: true,
    title: 'Dashboard',
    role: ["Administrator", "Seller", "Supplier"]
  },
  {
    path: '/chat-gpt-tool',
    component: Auth(DashboardGPT),
    exact: true,
    title: 'Dashboard',
    role: ["Administrator", "Seller", "Supplier"]
  },
  {
    path: '/admin/users-manager',
    component: Auth(UsersManagerPage),
    exact: true,
    title: 'Users Manager',
    role: ['Administrator']
  },
  {
    path: '/admin/user-form',
    component: Auth(UserForm),
    exact: true,
    title: 'User Form',
    role: ['Administrator']
  },
  {
    path: '/admin/settings',
    component: Auth(Settings),
    exact: true,
    title: 'App Settings',
    role: ['Administrator']
  },
  {
    path: '/sites',
    component: Auth(SitesManagerPage),
    exact: true,
    title: 'Sites',
    role: ['Administrator', 'Seller', 'Supporter']
  },
  {
    path: '/sites/:id/settings',
    component: Auth(SiteSettings),
    exact: true,
    title: 'Site Settings',
    role: ['Administrator', 'Seller']
  },
  {
    path: '/sites/:id/mapping-products',
    component: Auth(MapProducts),
    exact: true,
    title: 'Mapping Products',
    role: ['Seller']
  },
  {
    path: '/admin/seller-levels',
    component: Auth(UserLevelPage),
    exact: true,
    title: 'Seller Levels',
    role: ['Administrator', "Supporter", 'Listing']
  },
  {
    path: '/admin/news',
    component: Auth(NewsPage),
    exact: true,
    title: 'News',
    role: ['Administrator', 'Listing']
  },
  {
    path: '/admin/news/edit/:id',
    component: Auth(UpdateNews),
    exact: true,
    title: 'Edit News',
    toolbar: <Button type="primary" icon="save">Save</Button>,
    role: ['Administrator', 'Listing']
  },
  {
    path: '/admin/news/addnews',
    component: Auth(AddNews),
    exact: true,
    title: 'Add News',
    role: ['Administrator', 'Listing']
  },
  {
    path: '/orders',
    component: Auth(Orders),
    exact: true,
    title: 'Orders Manager',
    role: ["Administrator", "Seller", "Supplier", 'Supporter']
  },
  // {
  //   path: '/import-orders',
  //   component: Auth(ImportOrdersPage),
  //   exact: true,
  //   title: 'Import Orders Manager',
  //   role: ["Administrator", "Seller"]
  // },
  // {
  //   path: '/import-orders/import',
  //   component: Auth(ImportOrders),
  //   exact: true,
  //   title: 'Import Orders Manager',
  //   role: ["Administrator", "Seller"]
  // },
  {
    path: '/sites/:id/import-orders',
    component: Auth(ImportOrders),
    exact: true,
    title: 'Import Orders',
    site_selector: false,
    role: ["Administrator", 'Seller']
  },
  {
    path: '/orders/orders-manager/:id',
    component: Auth(DetailOrder),
    exact: true,
    title: 'Order Detail',
    role: ["Administrator", "Seller", "Supplier"]
  },
  {
    path: '/products',
    component: Auth(Products),
    exact: true,
    title: 'Products Manager',
    toolbar: <ProductToolbar />,
    role: ['Administrator', 'Seller', 'Supporter', 'Listing']
  },
  {
    path: '/products/add/step1',
    component: Auth(PushStepOne),
    exact: true,
    title: 'Step 1: Select Product Base',
    role: ['Administrator', 'Seller']
  },
  {
    path: '/products/add/step2/:id',
    component: Auth(PushStepTwo),
    exact: true,
    title: 'Step 2: Input Product Detail',
    role: ['Administrator', 'Seller']
  },
  {
    path: '/products/clone/:product_id/:id',
    component: Auth(PushStepTwo),
    exact: true,
    title: 'Step 2: Input Product Detail',
    role: ['Administrator', 'Seller']
  },
  {
    path: '/products/edit/:id',
    component: Auth(EditProduct),
    exact: true,
    title: 'Edit Product',
    role: ['Administrator', 'Seller']
  },
  {
    path: '/orders',
    component: Auth(Orders),
    exact: true,
    title: 'Orders Manager',
    role: ["Administrator", "Seller", "Supplier"]
    //toolbar: <Button icon="plus" onClick={() => history.push('/products/add/step1')}>Add New</Button>
  },
  {
    path: '/export-orders',
    component: Auth(ExportOrder),
    exact: true,
    title: 'Export Order Manager',
    role: ['Administrator', 'Supplier']
  },
  {
    path: '/export-templates',
    component: Auth(ExportOrderTemplate),
    exact: true,
    title: 'Export Templates',
    role: ['Administrator', 'Supplier']
  },
  {
    path: '/export-templates/add',
    component: Auth(ExportTemplateForm),
    exact: true,
    title: 'Add Export Templates',
    role: ['Administrator', 'Supplier']
  },
  {
    path: '/export-templates/clone/:id',
    component: Auth(ExportTemplateForm),
    exact: true,
    title: 'Clone Export Templates',
    role: ['Administrator', 'Supplier']
  },
  {
    path: '/export-templates/edit/:id',
    component: Auth(ExportTemplateForm),
    exact: true,
    title: 'Edit Export Templates',
    role: ['Administrator', 'Supplier']
  },
  // {
  //   path: '/productions',
  //   component: Auth(Production),
  //   exact: true,
  //   title: 'Production',
  //   role: ['Administrator']
  // },
  {
    path: '/admin/product-types',
    component: Auth(ProductTypesPage),
    exact: true,
    title: 'Product Types',
    toolbar: <ProductTypeToolbar />,
    role: ['Administrator', 'Supporter', 'Listing']
  },
  {
    path: '/product-types',
    component: Auth(ProductTypesPage),
    exact: true,
    title: 'Product Types',
    role: ["Administrator", "Seller", "Supplier"]
  },
  {
    path: '/admin/product-types/add',
    component: Auth(ProductTypeForm),
    exact: true,
    title: 'Add Product Type',
    toolbar: <Button type="primary" icon="save">Save</Button>,
    role: ['Administrator', 'Listing']
  },
  {
    path: '/admin/product-types/edit/:id',
    component: Auth(ProductTypeForm),
    exact: true,
    title: 'Edit Product Type',
    toolbar: <Button type="primary" onClick={() => {
      emitter.emit('saveProductType', null)
    }} icon="save">Save</Button>,
    role: ['Administrator', 'Listing']
  },
  {
    path: '/admin/users-manager/edit/:id',
    component: Auth(UserEdit),
    exact: true,
    title: 'Edit User',
    role: ['Administrator']
    // toolbar: <Button type="primary" icon="save">Save</Button>
  },
  {
    path: '/admin/product-types/:id/variants',
    component: Auth(ProductTypeVariants),
    exact: true,
    title: 'Edit Product Type Variants',
    role: ['Administrator', 'Listing']
  },
  // {
  //   path: '/admin/mail-templates',
  //   component: Auth(Mails),
  //   exact: true,
  //   title: 'Mail Templates',
  //   toolbar: <Button type="primary" icon="plus" onClick={() => history.push('/admin/mail-templates/add')}>Create New Mail</Button>,
  //   role: ['Administrator', 'Supporter']
  // },
  // {
  //   path: '/admin/mail-templates/add',
  //   component: Auth(MailTemplateForm),
  //   exact: true,
  //   title: 'Create New Mail',
  //   toolbar: <Button type="primary" icon="save">Save</Button>,
  //   role: ['Administrator', 'Supporter']
  // },
  // {
  //   path: '/admin/mail-templates/edit/:id',
  //   component: Auth(MailTemplateForm),
  //   exact: true,
  //   title: 'Edit Mail Templates',
  //   role: ['Administrator', 'Supporter']
  // },
  {
    path: '/customers',
    component: Auth(Customers),
    exact: true,
    title: 'Customers Manager',
    role: ['Administrator']
  },
  // {
  //   path: '/members',
  //   component: Auth(UserTeam),
  //   exact: true,
  //   title: 'Member Manager'
  // },
  {
    path: '/customers/:id',
    component: Auth(CustomerDetail),
    exact: true,
    title: 'Customer Detail',
  },
  {
    path: '/admin/carriers',
    component: Auth(Carrier),
    exact: true,
    title: 'Carriers',
    role: ['Administrator', 'Supporter', 'Listing']
  },
  {
    path: '/balance',
    component: Auth(Balance),
    exact: true,
    title: 'Balance',
    role: ["Administrator", "Seller", "Supplier", 'Supporter', 'Listing', 'Accounting'],
  },
  {
    path: '/admin/categories',
    component: Auth(Categories),
    exact: true,
    title: 'Categories',
    toolbar: <CategoryToolbar />,
    role: ['Administrator', 'Supporter', 'Listing']
  },
  {
    path: '/admin/category/edit/:id',
    component: Auth(CategoryForm),
    exact: true,
    title: 'Edit Category',
    role: ['Administrator', 'Supporter']
  },
  {
    path: '/admin/category/add',
    component: Auth(CategoryForm),
    exact: true,
    title: 'New Category',
    role: ['Administrator', 'Supporter']
  },
  // {
  //   path: '/transactions',
  //   component: Auth(SupplierTransactions),
  //   exact: true,
  //   title: 'Transactions',
  //   role: ["Administrator", "Seller", "Supplier", 'Supporter', 'Listing', 'Accounting']
  // },
  {
    path: '/news/:id',
    component: Auth(DetailNews),
    exact: true,
    title: 'News'
  },
  {
    path: '/egg/sites',
    component: Auth(EggSitesList),
    exact: true,
    title: 'Dev Site List',
    role: ['Administrator']
  },
  {
    path: `/verify/:token`,
    component: Verification,
    exact: true,
    title: 'Verification',
    layout: Blank,
  },
  {
    component: Page404,
    layout: Blank
  },
]

export default () => {
  return (
    <Router history={history}>
      <Switch>
        {routers.map((router, index) => {
          if (router.layout) {
            return <router.layout {...router} key={index} />
          } else {
            return <DefaultLayout {...router} key={index} />
          }
        })}
      </Switch>
    </Router>
  )
}

function Auth(ComposedComponent) {
  class Auth extends React.Component {
    checkAuth() {
      const { match, history } = this.props
      this.props.setDestination(match)
      var token = gql.getToken()
      if (!token) {
        history.push('/login')
      }
    }
    UNSAFE_componentWillMount() {
      this.checkAuth()
    }

    UNSAFE_componentWillUpdate(nextProps) {
      this.checkAuth()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.checkAuth()
    }

    render() {
      const { currentUser } = this.props
      if (!currentUser) {
        return <Spin />
      } else {
        return <ComposedComponent {...this.props} />
      }
    }
  }

  const mapStateToProps = state => ({
    currentUser: state.app.currentUser
  })

  const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
  return connect(
    mapStateToProps,
    { mapDispatchToProps, setDestination }
  )(Auth)
}