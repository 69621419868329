import { SET_ORDERS_FILTER, RESET_ORDERS_FILTER, SET_ORDER_ID, RESET_ORDER_ID } from "../types"

export const setOrdersFilter = filter => ({
  type: SET_ORDERS_FILTER,
  payload: filter
})

export const resetOrdersFilter = () => ({
  type: RESET_ORDERS_FILTER
})

export const setOrderId = id => ({
  type: SET_ORDER_ID,
  payload: id
})

export const resetOrderId = () => ({
  type: RESET_ORDER_ID
})