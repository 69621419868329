import React, { Component } from 'react'
import { message, Row, Col, Skeleton } from 'antd'
import _ from 'lodash'
import { Link } from "react-router-dom";
import gql from '../../api/gql'
import { Card } from 'antd';
import moment from 'moment'


export default class OrderDetail extends Component {
  state = {
    loading: true,
    order: null
  }

  componentDidMount() {
    const query = `query order($id: String!){
      orderById(id: $id){
        id
        product{title,sku,images}
        variant{image}
        customer{id,first_name, last_name, email, phone}
        site{id, title, domain}
        billing{address1,  city,country_code, zip}
        shipping{address1, city,country_code, zip}
        price
        quantity
        status
        created_at
      }
    }`

    gql.request(query, { id: this.props.match.params.id }).then(res => {
      this.setState({ order: res.orderById || {}, loading: false })
    }).catch(err => {
      message['error'](_.get(err, '[0].message'))
    })
  }

  render() {
    let { order } = this.state
    if (!order) return <Skeleton/>
    const billingAddress =`${order.billing.address1}, ${order.billing.zip}, ${order.billing.city}, ${order.billing.country_code}` 
    const shippingAddress =`${order.shipping.address1}, ${order.shipping.zip}, ${order.shipping.city}, ${order.shipping.country_code}` 
    order = order || {}
    return (
      <div className="detail-order">
        <div className="header" >
          <b>Order:</b> {order.id} {moment(order.created_at).format('MMM DD, YYYY')}
        </div>
        <Row>
          <Col span={12}>
            <div>
              <b>Status: </b>
              {order.status}
            </div>
            <div>
              <b>Billing Address: </b>
              {billingAddress}
            </div>
            
            <div>
              <b>Phone: </b>
              <Link to={`/orders/orders-manager/:id`}>
                {order.customer.phone}
              </Link>
            </div>
          </Col>

          <Col span={12}>
            <b>Customer: </b>
            <Link to={`/customers/${order.customer ? order.customer.id : ""}`}>
              {order.customer.first_name + " " + order.customer.last_name }
            </Link>
            <div>
              <b>Email Address: </b>
              <Link to={`/orders/orders-manager/:id`}>
                {order.customer.email}
              </Link>
            </div>
            <div>
              <b>Shipping Address: </b>
              {shippingAddress === billingAddress ? "Same as billing address" : shippingAddress}
            </div>
          </Col>
        </Row>

        <Row gutter={24} className="order-item">
          <p className="titel-item"><b>Line Items</b></p>
          <div className="list-item">
            <Col span={2}>
              <div className="image">
               <img alt="" src={order.product.images} />}
              </div>
            </Col>
            <Col span={7}>
              <Card >
                <Link to={`/orders/orders-manager/:id `}>
                  {order.product.title }
                </Link>
                <p className=" title-sku">SKU:{order.product.sku }</p>
              </Card>
            </Col>

            <Col span={7}>
              <Card >
                {"$" + order.price + " x " + order.quantity}
              </Card>
            </Col>

            <Col span={6}>
              <Card >
                {"$" + order.price * order.quantity}
              </Card>
            </Col>

          </div>
        </Row>
      </div>

    )
  }
}