import { SET_PRODUCT } from "../types"

const initState = {
  id: null
}

export default (state= initState, action) => {
  switch (action.type) {
    case SET_PRODUCT:
      return {
        ...state,
        id: action.payload,
      }
    default: return state
  }
}