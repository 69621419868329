import React, { Component } from 'react'
import gql from '../../api/gql'
import { Table } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Skeleton, message, Layout, Avatar, Row, Col, Button, Icon, Card } from 'antd';
import CustomerEdit from './CustomerEdit'
const { Content, Header, Footer } = Layout;

export default class CustomerDetail extends Component {
  state = {
    loading: false,
    key: 'Orders',
    oderKey: 'Orders',
    showCard: false,
    orders: [],
    count: 0
  };
  onTabChange = (key, type) => {
    this.setState({ [type]: key });
  };
  onHide = () =>{
    this.setState({showCard:true})
  }
  onShow =()=>{
    this.setState({showCard:false})
  }
  componentDidMount() {
    this.loadorders()
    const { id } = this.props.match.params
    const query = `query CustomerById($id: String!){
      customerById(id: $id) 
      {
        first_name last_name email phone 
        address{
          address1 address2 city state state_code country country_code zip
        }
      }      
    }`
    this.setState({ loading: true })
    gql.request(query, {
      id: id
    }).then(res => {
      this.setState({
        customerById: res.customerById || null,
        loading: false
      })
    })
  }
  loadorders() {
    const query = `query orders($cus_id: String!){
      ordersByCustomer(cus_id: $cus_id) 
      { 
        count
        hits{
          id,
          product{
            title
          }
          variant{
            attributes{
              name option
            }
          }
          price quantity created_at status
        }
      }      
    }`
    gql.request(query, { cus_id: this.props.match.params.id }).then(res => {
      this.setState({ orders: res.ordersByCustomer.hits || [], count: res.ordersByCustomer.count, loading: false })
    }).catch(err => {
      message['error'](_.get(err, '[0].message'))
    })
  }
  render() {
    const columns = [
      {
        title: 'Product',
        dataIndex: 'product',
        render: (p, { variant, id }) => <Link to={`/orders/orders-manager/${id}`} >
          <div>{p ? p.title : null} <small> {variant.attributes && variant.attributes.map(attr => `${attr.name}:${attr.option}`).join(',')}</small></div>
        </Link>
      },
      {
        title: 'Price',
        dataIndex: 'price',
        render: (p) => `${p}`
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
      },
      {
        title: 'Date',
        dataIndex: 'created_at',
        render: (created_at) => moment(created_at).format('MMM DD, YYYY')
      },
      {
        title: 'Status',
        dataIndex: 'status'
      }
    ]
    const { customerById, orders } = this.state
    if (!customerById) return <Skeleton />
    const contentListNoTitle = {
      Orders: <Table columns={columns} dataSource={orders} rowKey={({ id }) => id} />,
      Outbox: <p>Outbox content</p>,
    };
    const gridStyle = {
      width: '100%',
      padding: '10px',
      margin: '5px'
    }
    const tabListNoTitle = [
      {
        key: 'Orders',
        tab: 'Orders',
      },
      {
        key: 'Outbox',
        tab: 'Outbox',
      },
    ];
    const price = this.state.orders.map(order => (
      order.price
    )).reduce((a, b) => a += b, 0)
    const averageOder = this.state.orders.map(order => (
      order.price / this.state.count
    )).reduce((a, b) => a += b, 0)

    return (
      <div>
        <Layout>
          <Header style={{ background: '#fff', padding: '0px', display: 'flex', justifyContent: 'space-between' }}>
            <Col order={1}>
              <span>
                <Avatar size="64" icon="user" ></Avatar>
                {customerById.first_name + " " + customerById.last_name}
              </span>
            </Col>
            <Col order={2} style={{ float: 'right' }}>
              <Button>
                Actions<Icon type="down"></Icon>
              </Button>
            </Col>
          </Header>
          <Layout>
            <Content >
              <Row>
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                  <Row gutter={16} style={{ padding: '20px 20px 0px 20px' }}>
                   {
                     this.state.showCard ? (
                     <CustomerEdit data= {customerById} onClick={this.onShow}></CustomerEdit>):(
                    <Card title="Contact Info" extra={<Button type="link" onClick={this.onHide}>Edit</Button>}>
                      <Col span={8}>
                        <Card bordered={false}>
                          <h4>Name</h4>
                          <p>{customerById && (customerById.first_name + " " + customerById.last_name)}</p>
                          <h4>Phone</h4>
                          <p>{customerById && (customerById.phone)}</p>
                          <h4>Location</h4>
                          <p>{customerById && (customerById.address.address1 = !"" ? customerById.address.address1 : customerById.address.address2)}</p>
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card bordered={false}>
                          <h4>Email</h4>
                          <p>{customerById && (customerById.email)}</p>
                          <h4>Company</h4>
                          <p>-</p>
                        </Card>
                      </Col>
                    </Card>)
                    }
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Row style={{ padding: '20px 10px 0px 10px', background: 'transparent' }}>
                    <Card title="Stats" >
                      <Row style={{ margin: '0px 0px 0px -8px' }} >
                        <Card.Grid style={gridStyle}>
                          <Col span={4} style={{ textAlign: 'center' }}>
                            <Avatar size={32} icon="dollar" style={{ backgroundColor: '#87d068' }}></Avatar>
                          </Col>
                          <Col span={20} >
                            <h4>Lifetime Value</h4>
                            <p>${price}</p>
                          </Col>
                        </Card.Grid>
                      </Row>
                      <Row style={{ margin: '0px 0px 0px -8px' }}>
                        <Card.Grid style={gridStyle}>
                          <Col span={4} style={{ textAlign: 'center' }}>
                            <Avatar size={32} icon="unordered-list" style={{ backgroundColor: '#87d068' }}></Avatar>
                          </Col>
                          <Col span={20}>
                            <h4>Order count</h4>
                            <p>{this.state.count}</p>
                          </Col>
                        </Card.Grid>
                      </Row>
                      <Row style={{ margin: '0px 0px 0px -8px' }}>
                        <Card.Grid style={gridStyle}>
                          <Col span={4} style={{ textAlign: 'center' }}>
                            <Avatar size={32} icon="dollar" style={{ backgroundColor: '#87d068' }}></Avatar>
                          </Col>
                          <Col span={20}>
                            <h4>Average order value</h4>
                            <p>${averageOder}</p>
                          </Col>
                        </Card.Grid>
                      </Row>
                    </Card>
                  </Row>
                </Col>
              </Row>
            </Content>
          </Layout>
          <Footer style={{ padding: '20px 10px 20px 10px' }} >
            <Card
              style={{ width: '100%' }}
              tabList={tabListNoTitle}
              activeTabKey={this.state.oderKey}
              onTabChange={key => {
                this.onTabChange(key, 'oderKey');
              }}
            >
              {contentListNoTitle[this.state.oderKey]}
            </Card>
          </Footer>
        </Layout>
      </div>
    )
  }
}