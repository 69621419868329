import React from 'react'
import { connect } from 'react-redux'
import { Menu, Icon, Dropdown, Avatar, Drawer } from 'antd'
import { Link } from 'react-router-dom'
import { setCurrentUser, setDestination } from '../actions'
import { history } from '../history'
import MyProfile from '../pages/user/MyProfile';

class UserMenu extends React.Component {
  state = {
    profileVisible: false
  }

  logout = (e) => {
    e.preventDefault()
    const { setCurrentUser, setDestination } = this.props
    localStorage.removeItem('_token')
    setCurrentUser(null)
    setDestination(null)
    history.push('/login')
  }

  profile = (e) => {
    e.preventDefault()
    history.push('/profile')
  }

  changePassword = (e) => {
    e.preventDefault()
    history.push('/change-password')
  }

  showProfile = () => {
    this.setState({ profileVisible: true })
  }
  closeProfile = () => {
    this.setState({ profileVisible: false })
  }
  render() {
    const { currentUser } = this.props;
    if (!currentUser) return null;
    const menu = (
      <Menu>
        {/* <Menu.Item key="user-profile">
          <Link to="#" onClick={this.showProfile}>
            <Icon type="profile" /> <span>User Profile</span>
          </Link>
        </Menu.Item> */}
        <Menu.Item key="change-password">
          <Link to='/change-password' onClick={this.changePassword}>
            <Icon type="unlock" /> <span>Change Password</span>
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="user-logout">
          <Link to='/user/logout' onClick={this.logout}>
            <Icon type="logout" /> <span>Logout</span>
          </Link>
        </Menu.Item>
      </Menu>
    )
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {/* <Icon type="bell" style={{ marginRight: 20 }} /> */}
        <Dropdown overlay={menu} style={{ height: 64 }}>
          <span>
            Hi, {currentUser.first_name} <Avatar src={null}> {currentUser.first_name.charAt(0)} {currentUser.last_name.charAt(0)}</Avatar>
          </span>
        </Dropdown>
        <Drawer
          title="My Profile"
          placement="right"
          closable={false}
          onClose={this.closeProfile}
          visible={this.state.profileVisible}
          width={480}
          bodyStyle={{ paddingBottom: 80 }} >
          <MyProfile/>
        </Drawer>
      </div>
    )
  }
}

export default connect(
  state => {
    return {
      currentUser: state.app.currentUser
    }
  },
  { setCurrentUser, setDestination }
)(UserMenu)