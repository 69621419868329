import { SET_DESTINATION, SET_CURRENT_USER, SET_CURRENT_SITE, INIT_APP_2, SET_SELLER_SITES } from "../types"

export const initApp = () => ({
  type: INIT_APP_2
})

export const setCurrentUser = user => ({
  type: SET_CURRENT_USER,
  payload: user
})

export const setDestination = router => ({
  type: SET_DESTINATION,
  payload: router
})

export const setCurrentSite = site => ({
  type: SET_CURRENT_SITE,
  payload: site
})

export const setSellerSites = sites => ({
  type: SET_SELLER_SITES,
  payload: sites
})