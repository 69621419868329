import React, { Component } from 'react';
import { Form, notification, Input, Button,Switch, Col, Row, Card } from 'antd';
import gql from '../../api/gql';
import Wysiwyg  from '../../components/wysiwyg'
import { history } from '../../history'
import _ from 'lodash'

class AddNews extends Component {
  state = {
    loading: false
  }
  onClose = () => {
    this.props.onClose();
  };
  onhandleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const mutation = `mutation createNews($input: AddNews!){
          createNews(input: $input){
            id
            title
            notification
            send_mail
          }
        }`
        this.setState({ loading: true })
        gql.request(mutation, {
          input: values,
          
        }).then(res => {
          this.setState({
            loading: false
          })
          history.goBack()
          this.props.form.resetFields()
        })
        .catch(err => {
          notification['error']({
            message: _.get(err, '[0].message')
          })
          this.setState({ loading: false })
        })
        return
      }
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.onhandleSubmit}>
      <Row gutter={20}>
        <Col span={24} lg={16}>
          <Card title="Detail" style={{ marginBottom: 20 }}>
            <Form.Item label="Title">
              {
                getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      message: "Please enter title!"
                    }
                  ]
                })(<Input />)
              }
            </Form.Item>
            <Form.Item label="Content">
              {
                getFieldDecorator('content', {
                  rules: [
                    {
                      required: true,
                      message: "Please enter content!"
                    }
                  ]
                })(<Wysiwyg />)
              }
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} lg={8}>
          <Card title="Option" style={{ marginBottom: 20 }}>
            <Form.Item label="Notification">
              {
                getFieldDecorator('notification', {
                  valuePropName: 'checked',
                  initialValue:false,
                  rules: [
                    {

                      required: false
                    }
                  ]
                })(<Switch size="small" onChange={this.handleChange} />)
              }
            </Form.Item>
            <Form.Item label="Send Mail">
              {
                getFieldDecorator('send_mail', {
                  valuePropName: 'checked',
                  initialValue:false,
                  rules: [{ required: false }]
                })(
                  (<Switch size="small" onChange={this.handleChange} />)
                )}
            </Form.Item>
          </Card>
        </Col>
      </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon="save">Save</Button>
          <Button icon="undo" style={{marginLeft: 10}} onClick={()=> history.goBack()} >Cancel</Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create({ name: "form" })(AddNews);


