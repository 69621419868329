import React from 'react'
import { connect } from 'react-redux'
import { notification, Radio, Dropdown, Icon, Menu, Popconfirm } from 'antd'
import ImportTrackings from '../tracking/ImportTracking'
import gql from '../../api/gql'
import _ from 'lodash'
import { setOrderId } from '../../actions'
import styled from 'styled-components'

const Container = styled.div`
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled], .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
    border-left-color: #d9d9d9;
  }
  .ant-btn-group .ant-btn-primary:last-child:not(:first-child), body .ant-btn-group .ant-btn-primary + .ant-btn-primary{
    border-left-color: white;
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child){
    border-right-color: white;
  }
`

class SupplierOrdersToolbar extends React.Component {

  state = {
    loading: false,
    templates: [],
    template_id: "0",
    template_name: ""
  }

  componentDidMount() {
    this.loadExportTemplates()
  }
  
  loadExportTemplates = () => {
    let query = `query exportTemplates{
      exportTemplates{
        hits {
          id
          name
        }
      }
    }`
    gql.request(query).then(res => {
      this.setState({
        templates: res.exportTemplates.hits,
        loading: false
      })
    })
  }

  exportOrders = e => {
    const {template_id} = this.state
    e.preventDefault()
    this.setState({
      loading: true
    })
    const { filter, currentUser } = this.props
    let isSupplier = currentUser.roles.find(role => ['Supplier'].includes(role.name))
    let query = `mutation exportOrders($filter: OrderFilter){
      exportOrders(filter: $filter)
    }`
    let supplier = `mutation supplierExportOrder($template_id: Int, $filter: OrderFilter){
      supplierExportOrder(template_id: $template_id, filter: $filter)
    }`

    gql.request(isSupplier ? supplier : query, {
      template_id: template_id === "0" ? null : template_id,
      filter: { ...filter, order_id: this.props.order_id }
    }).then(res => {
      window.location = `${isSupplier ? res.supplierExportOrder : res.exportOrders}`
      this.setState({
        loading: false
      })
      this.props.setOrderId(null)
      _.get(this, 'props.onDownload', () => { })()
    }).catch(err => {
      this.setState({
        loading: false
      })
      notification['error']({
        message: _.get(err, '[0].message')
      })
    })
  }

  render() {
    const { loading, templates, template_id } = this.state
    const { currentUser, filter } = this.props
    const status = _.get(filter, 'status', [])
    let isSeller = currentUser.roles.find(role => ['Seller'].includes(role.name))
    const radioStyle = {
      display: 'block',
      height: '25px',
      lineHeight: '25px',
      fontSize: 13
    };
    const template = templates.find(template => template_id === template.id) 
    const templateName = template ? template.name : "Default Template"
    const menu = (
      <Menu style={{padding: 10}}>
        Choose export template
        <Menu.Divider />
        <Radio.Group value={template_id} onChange={(e) => this.setState({ template_id: e.target.value })}>
          <Radio name="Default Template" style={radioStyle} value="0">
            Default Template
          </Radio>
          {templates.map(template => 
            <Radio style={radioStyle} key={template.id} value={template.id}>
              {template.name}
            </Radio>
          )}
        </Radio.Group>
      </Menu>
    )
    return (
      <Container style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!isSeller && <ImportTrackings onChange={() => this.props.onChange()}/>}
        {/* <Button onClick={this.exportOrders} disabled={!status.includes('ready') || status.length > 1} loading={loading} style={{ marginLeft: 5 }} type="primary" icon="export">Export Orders</Button> */}
        <Popconfirm
          title={"Export order by " + templateName}
          onConfirm={this.exportOrders}
          okText="Yes"
          cancelText="No"
          placement="bottom"
        >
          <Dropdown.Button loading={loading.toString()} style={{ marginLeft: 5 }} overlay={menu} type="primary" disabled={!status.includes('ready') || status.length > 1}>
            <Icon type="export" />Export Orders
          </Dropdown.Button>
        </Popconfirm>
        {/* <Popover
          content={menu}
          title="Choose export template"
          trigger="click"
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
          >
          <Button disabled={!status.includes('ready') || status.length > 1} onClick={this.exportOrders} loading={loading} style={{ marginLeft: 5 }} type="primary" icon="export"> <Icon type="export" />Export Orders </Button>
        </Popover> */}
      </Container>
    )
  }
}

export default connect(
  state => ({
    currentUser: state.app.currentUser,
    filter: state.orders.filter,
    order_id: state.orders.order_id
  }), { setOrderId }
)(SupplierOrdersToolbar)