import React, { Component } from "react";
import BalanceTable from "../../components/balance/BalanceTable";
import { connect } from 'react-redux'

class Balance extends Component {
  state = {
    filter: null
  }
  render() {
    return(
      <div>
        <BalanceTable filter={this.state.filter} {...this.props}/>
      </div>
    )
  }
}
export default connect(
  state => ({
    currentSite: state.app.currentSite
  })
)(Balance)