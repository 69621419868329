import React from 'react'
import gql from '../../api/gql'
import { Select } from 'antd'
class CarrierSelect extends React.Component {
  state = {
    value: this.props.value || [],
    carrier: [],
    loading: false,
    option: []
  }
  componentDidMount() {
    const query = `query carriers($filter: CarrierFilter){
      carriers(filter: $filter){
        id
        name
        code
      }
    }`
    this.setState({loading: true})
    gql.request(query,{filter: {status: true}})
    .then(res => this.setState({loading: false, carrier: res.carriers}))
  }
  render(){
    const {carrier} = this.state
    return(
      <Select
      placeholder="Select"
      mode='multiple'
      value={this.state.value}
      optionFilterProp='children'
      onChange={(value,option) => this.setState({value, option}, () => this.props.onChange(this.state.value,this.state.option))}
      >
        {carrier.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
      </Select>
    )
  }
}

export default CarrierSelect