import React, { Component } from "react";
import styled from "styled-components";
import {
  Card,
  Select,
  Button,
  Icon,
  Table,
  Drawer,
  message,
  Popover
} from "antd";
import NewDepositForm from "./NewDepositForm";
import { setCurrentUser } from "../../actions";
import gql from "../../api/gql";
import moment from "moment";
import { connect } from "react-redux";
import DateRange from "../DateRange";
import CurrencyFormat from "react-currency-format";
import _ from "lodash"

const Container = styled.div`
  .column-approve button {
    padding: 0 10px;
    margin: 0 5px;
  }
  .filter {
    margin-bottom: 10px;
    display: flex; 
    justify-content: space-between;
  }
  .dropdown-filter{
    margin-right: 5px;
  }
  .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .head h1 {
    font-weight: bold;
    font-size: 26px;
  }
  .card {
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .card .available-amount {
    color: green;
    font-weight: bold;
  }
  .card .pending-amount {
    color: red;
    font-weight: bold;
  }
  .card .paid-amount {
    color: #8e8e8e;
    font-weight: bold;
  }
  .card .pending-deposit {
    color: blue;
    font-weight: bold;
  }
  .balance-card{
    display: grid;
    grid-template-columns: repeat(4,24%);
    grid-gap: 1%;
  }
  @media only screen and (max-width: 700px) {
    .balance-card{
      display: grid;
      grid-template-columns: repeat(2,50%);
      grid-gap: 1%;
    }
  }
  @media only screen and (max-width: 540px) {
    .btn-deposit {
      margin-bottom: 20px;
    }
    .balance-card{
      display: grid;
      grid-template-columns: repeat(2,50%);
      margin-bottom: 10px;
    }
    .card {
      margin-bottom: 10px;
    }
    .filter {
      display: flex;
      flex-direction: column-reverse;
    }
  }

`;

class BalanceTable extends Component {
  state = {
    loading: false,
    balanceList: [],
    balance: {},
    balanceID: null,
    depositAmount: 1000,
    sites: [],
    users: [],
    filter: {
      user_id: null,
      site_id: null,
      action: null,
      limit: 20,
      offset: 0,
      from: null,
      to: null
    },
    siteFilter: {
      user_id: null,
      limit: 999,
    },
    total: 0,
    page: 1,
    amount: {},
    visible: false
  };

  handleChange = value => {
    this.setState({
      status: value
    });
  };

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  componentDidMount() {
    if (this.props.currentUser.roles.find(role => role.name === "Seller")) {
      this.applySiteFilter(this.props.currentSite.id);
    } else {
      this.loadBalanceTable();
    }
    this.loadSites();
    this.loadSellers();
  }
  componentDidUpdate(prevProps) {
    if (this.props.currentUser.roles.find(role => role.name === "Seller")) {
      if (
        prevProps.currentSite !== this.props.currentSite &&
        this.props.currentUser.roles.find(role =>
          ["Seller"].includes(role.name)
        )
      ) {
        this.applySiteFilter(this.props.currentSite.id);
      }
    }
  }
  handleApprove(id, status) {
    const mutation = `mutation updateBalance($id: Int! $status: String!){
      updateBalance(id: $id status: $status){
        id
      }
    }`;
    gql
      .request(mutation, { id, status })
      .then(res => {
        this.setState({ loading: false });
        status === "Approved"
          ? message["success"](" Approve success!")
          : message["success"](" Deny success!");
        this.loadBalanceTable();
        return;
      })
      .catch(err => {
        message["error"]("Something wrong!");
      });
  }

  loadSites() {
    const { currentUser } = this.props;
    if (!currentUser) return null;
    const admin = currentUser.roles.find(role => role.name === "Administrator");
    const query = `query sites($filter: SiteFilter) {
      sites(filter: $filter){
        hits {
          id
          title
        }
      }
    }`;
    this.setState({
      loading: true
    });
    gql
      .request(query, {
        filter: {
          ...this.state.siteFilter,
          user_id: admin ? null : +this.props.currentUser.id
        }
      })
      .then(res => {
        this.setState({
          sites: res.sites.hits || [],
          loading: false
        });
      });
  }

  loadSellers() {
    const query = `query usersByRole(){
      usersByRole(
        role: "Seller",
        status: true
      ){
        id
        first_name
        last_name
        email
        pass
        roles{
          id
          name
        }
        status
      }
    }`;
    this.setState({
      loading: true
    });
    gql.request(query, {}).then(res => {
      this.setState({
        users: res.usersByRole || [],
        loading: false
      });
    });
  }

  loadBalanceTable() {
    const { currentUser } = this.props;
    if (!currentUser) return null;
    const seller = currentUser.roles.find(role => role.name === "Seller");
    const query = `query balances($filter: BalanceFilter) {
      balances(filter: $filter){
        count
        depositAmounts
        pendingDepositAmounts
        pendingAmounts
        fullfilledAmounts
        hits{
          id
          action
          actionNotes
          amount
          transaction_id
          status
          order_id
          creater{
            id
            first_name
            last_name
            email
          }
          updater {
            id
            first_name
            last_name
            email
          }
          site{
            id
            title
            domain
            platform
          }
          created_at
          updated_at
        }
      }
    }`;
    this.setState({
      loading: true
    });
    gql
      .request(query, {
        filter: seller
          ? {
            ...this.state.filter
            //user_id: this.props.currentUser.id
          }
          : { ...this.state.filter }
      })
      .then(res => {
        this.setState({
          amount: res.balances || {},
          balanceList: res.balances.hits || [],
          loading: false,
          total: res.balances.count
        });
      });
  }

  resetFilter = () => {
    this.setState({filter: {
      user_id: null,
      site_id: null,
      action: null,
    }})
    this.loadBalanceTable()
  }

  onChange = async dateRange => {
    if (dateRange.from !== null && dateRange.to !== null) {
      await this.setState({
        filter: {
          ...this.state.filter,
          from: moment(dateRange.from).format("MMM DD, YYYY"),
          to: moment(dateRange.to).format("MMM DD, YYYY")
        }
      });
    } else {
      delete this.state.filter.from && delete this.state.filter.to;
      await this.setState({
        filter: this.state.filter
      });
    }
    this.loadBalanceTable();
  };

  applyActionFilter = async value => {
    if (value !== "All") {
      await this.setState({
        filter: {
          ...this.state.filter,
          action: value
        }
      });
    } else {
      delete this.state.filter.action;
      await this.setState({
        filter: this.state.filter
      });
    }
    this.loadBalanceTable();
  };

  applySiteFilter = async value => {
    if (value !== "All") {
      await this.setState({
        filter: {
          ...this.state.filter,
          site_id: value
        }
      });
    } else {
      delete this.state.filter.site_id;
      await this.setState({
        filter: this.state.filter
      });
    }
    this.loadBalanceTable();
  };

  applyUserFilter = async value => {
    if (value !== "All") {
      await this.setState({
        filter: {
          ...this.state.filter,
          user_id: value
        }
      });
    } else {
      delete this.state.filter.user_id;
      await this.setState({
        filter: this.state.filter
      });
    }
    this.loadBalanceTable();
  };

  render() {
    const {
      balanceList,
      loading,
      sites,
      users,
      filter,
      total,
      page,
      amount
    } = this.state;
    const { currentUser } = this.props;
    if (!currentUser) return null;
    const admin = currentUser.roles.find(
      role => role.name === "Administrator" || role.name === "Accounting"
    );
    const seller = currentUser.roles.find(role => role.name === "Seller");
    const columns = [
      {
        title: "Amount",
        key: "amount",
        width: 120,
        align: "right",
        className: "column-money",
        dataIndex: "amount",
        render: (id, row) => (
          <CurrencyFormat
            decimalScale={2}
            fixedDecimalScale={true}
            value={row.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        )
      },
      {
        title: "Transaction ID",
        key: "transaction_id",
        width: 250,
        render: record => (
          <div>
            {record.action!=='Fulfilled' && <span style={{ marginRight: 10 }}>{record.transaction_id}</span>}
            {record.action==='Fulfilled' && <span style={{ marginRight: 10 }}>order_{record.order_id}</span>}
            {record.actionNotes ? (
              <Popover content={record.actionNotes} placement="rightTop">
                <Icon type="info-circle" />
              </Popover>
            ) : null}
          </div>
        )
      },
      {
        title: "Site",
        key: "site",
        width: 150,
        dataIndex: "site",
        render: (id, row) => <p>{row.site.title}</p>
      },
      {
        title: "Created By",
        width: 150,
        render: record =>
          record.creater ? (
            <p>
              {record.creater.first_name} {record.creater.last_name}
            </p>
          ) : (
              "-"
            )
      },
      {
        title: "Create Date",
        key: "created_at",
        width: 150,
        dataIndex: "created_at",
        render: created_at => moment(created_at).format("MMM DD, YYYY")
      },
      // {
      //   title: 'Update Date',
      //   key: 'updated_at',
      //   width: 150,
      //   dataIndex: 'updated_at',
      //   render: (updated_at) => moment(updated_at).format('MMM DD, YYYY')
      // },
      {
        title: "Status",
        key: "status",
        width: 130,
        dataIndex: "status"
      },
      {
        title: "Approve/ Reject",
        width: 180,
        className: "column-approve",
        align: "center",
        render: record => {
          const id = record.id,
            approve = "Approved",
            deny = "Denied";
          return (
            <div>
              {admin ? (
                <div>
                  {record.status === "Approved" ||
                    record.status === "Denied" ? null : (
                      <div>
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => this.handleApprove(id, approve)}
                        >
                          Approve
                      </Button>
                        <Button
                          size="small"
                          onClick={() => this.handleApprove(id, deny)}
                        >
                          Reject
                      </Button>
                      </div>
                    )}
                </div>
              ) : (
                  <p style={{ margin: 0 }}>
                    {record.status === "Approved" || record.status === "Denied"
                      ? null
                      : "Pending"}
                  </p>
                )}
            </div>
          );
        }
      }
    ];
    const pagination = {
      pageSize: filter.limit,
      total: total,
      current: page,
      showTotal: (total, range) => `${range} of ${total}`,
      onChange: (page, pageSize) => {
        this.setState(
          {
            page: page,
            filter: {
              ...filter,
              offset: (page - 1) * pageSize
            }
          },
          () => this.loadBalanceTable()
        );
      }
    };
    const tableWidth = _.sum(columns.map(c => c.width))
    const content = (
      <div style={{ width: 250 }}>
        <Select
            placeholder="Filter by action"
            style={{
              maxWidth: 250,
              width: "100%",
              marginBottom: 15
            }}
            onChange={this.applyActionFilter}
          >
            <Select.Option value="All">All Actions</Select.Option>
            <Select.Option value="Deposit">Deposit</Select.Option>
            <Select.Option value="Fulfilled">Fulfilled</Select.Option>
          </Select>
          {admin ? (
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Filter by seller"
              style={{
                maxWidth: 250,
                width: "100%",
                marginRight: 10,
                marginBottom: 15
              }}
              onChange={this.applyUserFilter}
            >
              <Select.Option value="All">All Sellers</Select.Option>
              {users.map(user => (
                <Select.Option key={user.id} value={user.id}>
                  {user.first_name} {user.last_name}
                </Select.Option>
              ))}
            </Select>
          ) : null}
          {!seller ? (
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Filter by site"
              style={{
                maxWidth: 250,
                width: "100%",
                marginRight: 10,
                marginBottom: 15
              }}
              onChange={this.applySiteFilter}
            >
              <Select.Option value="All">All sites</Select.Option>
              {sites.map(site => (
                <Select.Option key={site.id} value={site.id}>
                  {site.title}
                </Select.Option>
              ))}
            </Select>
          ) : null}
          <Button onClick={this.resetFilter} >Reset Filter</Button>
      </div>
    );
    return (
      <Container>
        <div className="balance-card">
          <Card className="card" title="Available Amount" bordered={false}>
            <CurrencyFormat
              className="available-amount"
              decimalScale={2}
              fixedDecimalScale={true}
              value={amount.depositAmounts + amount.fullfilledAmounts}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </Card>
          <Card className="card" title="Pending Amount" bordered={false}>
            <CurrencyFormat
              className="pending-amount"
              decimalScale={2}
              fixedDecimalScale={true}
              value={amount.pendingAmounts}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </Card>
          <Card className="card" title="Paid Amount" bordered={false}>
            <CurrencyFormat
              className="paid-amount"
              decimalScale={2}
              fixedDecimalScale={true}
              value={amount.fullfilledAmounts}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </Card>
          <Card className="card" title="Pending Deposit" bordered={false}>
            <CurrencyFormat
              className="pending-deposit"
              decimalScale={2}
              fixedDecimalScale={true}
              value={amount.pendingDepositAmounts}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
            </Card>
        </div>
        <div className="filter" >
          <div style={{display: "flex"  }}>
            <div className="dropdown-filter">
              <Popover placement="bottomLeft" content={content} trigger="click">
                <Button>
                  Filter <Icon type="filter"></Icon>
                </Button>
              </Popover>
            </div>
            <div style={{ marginBottom: 10 }}>
              <DateRange
                onChange={dateRange => this.onChange(dateRange)}
              />
            </div>
          </div>
          <div>
          {seller ? (
            <div style={{ display: 'flex', justifyContent: "flex-end" }}>
              <Button className="btn-deposit" type="primary" onClick={this.showDrawer}>
                <Icon type="plus" /> Make a Deposit
            </Button>
            </div>
          ) : null}
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={balanceList}
          pagination={pagination}
          loading={loading}
          rowKey={(text, index) => index}
          scroll={{ x: tableWidth }}
        />
        <Drawer
          title="Make a Deposit"
          placement="right"
          width="500px"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          {/* <h3>
            {" "}
            Please send payment to OOP Print Company{" "}
            <span style={{ color: "red", fontWeight: "bold" }}>
              knocktheme@gmail.com
            </span>{" "}
            and submit form below.
          </h3> */}
          <NewDepositForm
            {...this.props} 
            onClose={this.onClose}
            currentUser={currentUser}
            onLoad={() => this.loadBalanceTable()}
            data={this.props.balance}
          />
        </Drawer>
      </Container>
    );
  }
}

export default connect(
  state => {
    return {
      currentUser: state.app.currentUser
    };
  },
  { setCurrentUser }
)(BalanceTable);
