import React from 'react'
import { connect } from 'react-redux'
import { Select, Divider, Icon, Modal, Form, Input, message, notification } from 'antd'
import gql from '../../api/gql'
import _ from 'lodash'

class ImportTemplateList extends React.Component {
  state = {
    loading: false,
    hasError: false,
    addTemplate: false,
    templateName: '',
    templates: [],
    value: null
  }

  componentDidMount() {
    this.getTemplates()
  }

  getTemplates = (page = 1) => {
    const { site_id } = this.props
    const query = `query GetSiteSetting($site_id: Int!){
      getSiteSetting(site_id: $site_id){
        name
        value
      }
    }`
    gql.request(query, { site_id: parseInt(site_id) })
      .then(res => {
        if (res.getSiteSetting && res.getSiteSetting.length > 0) {
          //const settings = JSON.parse(res.getSiteSetting[0].value)
          this.setState({ 
            templates: res.getSiteSetting.filter(t=>t.name.startsWith("order_import_setting_")),
          })
          if(this.state.templates && this.state.templates.length>0){
            this.setState({ 
              value: this.state.templates[this.state.templates.length-1].name
            }, this.handleOnChange)
          }
        }
        //this.setState({ initSetting: res.getAppSettings || [], loading: false })
      })
      .catch(err => {
        notification["error"]({
          message: 'Error',
          description: _.get(err, "[0].message")
        })
        this.setState({ loading: false })
      })
  }

  addTemplate = e => {
    e.preventDefault()
    const { templates, templateName } = this.state
    if (templateName === '') {
      this.setState({ hasError: true })
      message['error']('Please input template name')
      return
    }
    if (templates.find(tem => tem.name === templateName)) {
      message['warning']('Template already exists')
      return
    }

    const { site_id } = this.props
    const mutation = `mutation CreateSetting($input: NewSetting!){
      createSiteSetting(input: $input){
        site_id
        name
        value
      }
    }`
    this.setState({ loading: true })
    gql.request(mutation, {
      input: {
        site_id: parseInt(site_id),
        name: "order_import_setting_"+templateName,
        value: '',
      }
    })
      .then(res => {
        this.setState({
          templates: [...this.state.templates, _.omit(res.createSiteSetting, ["site_id"])],
          addTemplate: false,
          loading: false,
          value: res.createSiteSetting.name
        }, this.handleOnChange)
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleOnChange = () => {
    const { onSourceChange } = this.props
    if (typeof onSourceChange === 'function') {
      onSourceChange(this.state.templates.filter(t=>t.name===this.state.value))
    }
  }

  render() {
    const { templates, loading, hasError, value } = this.state
    return (
      <div>
        <Select
          value={value}
          onChange={value => {
            this.setState({ value }, this.handleOnChange)
          }}
          placeholder="Select Template"
          style={{ width: '200px' }}
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ padding: '4px 8px 8px', cursor: 'pointer' }}
                onMouseDown={e => e.preventDefault()}
                onClick={(e) => {
                  e.preventDefault()
                  this.setState({ addTemplate: true })
                }}>
                <Icon type="plus" /> Add item
              </div>
            </div>
          )}>
          {templates.map(tem => <Select.Option key={tem.name}>{tem.name?tem.name.replace("order_import_setting_", ""):null}</Select.Option>)}
        </Select>
        <Modal
          visible={this.state.addTemplate}
          confirmLoading={loading}
          onCancel={() => this.setState({ addTemplate: false })}
          onOk={this.addTemplate}
          title="New Template">
          <Form.Item label="Template Name" className={hasError ? 'has-error' : ''}>
            <Input value={this.state.templateName} onChange={e => this.setState({ hasError: false, templateName: e.target.value })} />
          </Form.Item>
        </Modal>
      </div>
    )
  }
}

export default connect(state => ({
  currentSite: state.app.currentSite
}))(ImportTemplateList)