import React from 'react'
import ExportOrders from '../../components/orders/ExportOrders'

class ExportOrder extends React.Component{
  render(){
    return(
      <ExportOrders/>
    )
  }
}
export default ExportOrder