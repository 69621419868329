import React, { Component } from 'react'
import { Form, Input, Button, notification } from 'antd'
import gql from '../../api/gql'
import _ from 'lodash'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
.ant-form-item{
  margin-bottom: 10px;
}
.ant-form-horizontal{
  margin-bottom: 30px;
}`
const { TextArea } = Input
class UpdateCarrier extends Component {
  state = {
    loading: false,
    carriers: this.props.carriers
  }
  onClose = () => {
    this.props.onClose(false);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.carriers.id !== this.props.carriers.id) {
      this.props.form.setFieldsValue({
        ...this.props.carriers
      })
    }
  }
  onUpdate = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const mutation = `mutation updateCarrier($input: EditCarrier!, $id: Int!){
          updateCarrier(input: $input, id:$id){
            id
            name
            code
            url
            description
          }
        }`
        this.setState({ loading: true })
        gql.request(mutation, {
          input: values,
          id: this.props.carriers.id
        }).then(res => {
          notification['success']({ message: " Carrier has been updated" })
          this.setState({ loading: false })
          this.props.onLoad()
          this.onClose()
        }).catch(err => {
          notification['error']({
            message: _.get(err, '[0].message')
          })
          this.setState({ loading: false })
        })
      }
    })
  }
  render() {
    const { carriers } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <Container>
        <Form onSubmit={this.onUpdate}>
          <Form.Item label="Name">
            {
              getFieldDecorator('name', {
                initialValue: carriers ? carriers.name : '',
                rules: [
                  {
                    required: true,
                    message: "Please input name"
                  }
                ]
              })(<Input />)
            }
          </Form.Item>
          <Form.Item label="Code">
            {
              getFieldDecorator('code', {
                initialValue: carriers ? carriers.code : '',
                rules: [
                  {
                    required: true,
                    message: "Please input code"
                  }
                ]
              })(<Input />)
            }
          </Form.Item>
          <Form.Item label="Url">
            {
              getFieldDecorator('url', {
                initialValue: carriers ? carriers.url : '',
                rules: [
                  {
                    required: true,
                    message: "Please input url"
                  }
                ]
              })(<Input />)
            }
          </Form.Item>
          <Form.Item label="Description">
            {
              getFieldDecorator('description', {
                initialValue: carriers ? carriers.description : ''
              })(<TextArea />)
            }
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={this.state.loading}>Save</Button>{" "}
            <Button onClick={() => this.props.onClose()}>Cancel</Button>
          </Form.Item>
        </Form>
      </Container>
    );
  }
}
UpdateCarrier.propTypes = {
  carriers: PropTypes.object
}
export default Form.create({ name: 'form' })(UpdateCarrier)