import React from 'react'
import AdminProductTypes from '../../components/admin/ProductTypes'
import SupplierProductTypes from '../../components/supplier/ProductTypes'

export default class ProductTypesPage extends React.Component{
  render() {
    const {currentUser} = this.props
    if (!currentUser) return null
    if (currentUser.roles.map(role => ['Supporter', 'Administrator', 'Listing'].includes(role.name))) {
      return <AdminProductTypes currentUser={currentUser}/>
    }
    if (currentUser.roles.map(role => role.name).includes('Supplier')) {
      return <SupplierProductTypes/>
    }
    return null
  } 
}