import React from 'react'
import gql from '../../api/gql'
import { Table, Icon, Button, Select, message, Popconfirm, Input, Divider, Popover } from 'antd'
import { Link } from 'react-router-dom'
import S3Image from '../../components/S3Image'
import { connect } from 'react-redux'
import ProductTypeToolbar from '../product_type/ProductTypeToolbar';
import _ from "lodash"
import styled from 'styled-components'
const { Option } = Select

const Container = styled.div`
  .product-types{
    display: flex;
    justify-content: space-between;
  }
  .product-types-filter{
    display: flex; 
    width: -webkit-fill-available;
  }
  .product-type-toolbar{
    margin-left: 10px; 
    display: flex;
    justify-content: flex-end; 
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 540px) {
    .product-types-filter{
      margin-bottom: 10px;
    }
    .product-types{
      display: flex;
      flex-direction: column-reverse;
    }
    .product-type-toolbar{
      margin-bottom: 10px;
    }
  }
`

class SupplierProductTypes extends React.Component {
  _isMounted = false;
  state = {
    productTypes: [],
    loading: true,
    filter: {
      search: null,
      categories: [],
      supplier_id: [],
      status: "true",
    },
    categories: [],
    suppliers: [],
    pagination: {
      results: 100,
      pageSize: 30,
      total: 0,
    },
    page: 1,
  }

  resetFilter = () => {
    this.setState({filter: {
      search: null,
      categories: [],
      supplier_id: [],
      status: true,
    }})
  }

  componentDidMount() {
    this.loadCategories()
    this.loadProductType()
    this.loadSupplier()
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  loadProductType() {
    const { pagination, filter, page } = this.state
    const query = `query productTypes($filter: ProductTypeFilter){
      productTypes(filter: $filter){
        count,
        hits{
          id
          sku
          title
          images
          state
          categories{
            id, title
          }
          attributes{
            name
          }
          suppliers{
            first_name last_name id
          }
        }
      }
    }`
    this.setState({ loading: true })
    gql.request(query, {
      filter: {
        ...filter,
        limit: pagination.pageSize,
        offset: (page - 1) * pagination.pageSize,
        status: JSON.parse(filter.status)
      }
    })
      .then(res => {
        this.setState({
          productTypes: res.productTypes.hits || [],
          loading: false,
          pagination: { ...pagination, total: res.productTypes.count, results: res.productTypes.count }
        })
      })
  }
  loadCategories() {
    const query = `query categories {
      categories(filter:{status:true, limit: 9999, offset: 0}){
        hits{id title}
      }
    }
    `
    gql.request(query)
      .then(res => this.setState({ categories: res.categories.hits || [], loading: false }))
      .catch(err => {
        message['error']('Something wrong!')
      })
  }

  loadSupplier() {
		const {currentUser} = this.props
    if(currentUser.roles && currentUser.roles[0].name !== "Supplier"){
			const query = `query user{
				users(filter:{role:"Supplier"}){
					hits{
						id first_name last_name
					}
				}
			}`
			gql.request(query)
				.then(res => this.setState({ suppliers: res.users.hits || [] }))
				.catch(err => {
					message['error']('Something wrong!')
				})
		}
  }
  onChangeTable = async (e) => {
    await this.setState({ page: e.current })
    this.loadProductType()
  }
  handleChangeCategory = async (value) => {
    if (!_.isArray(value) && value !== null) {
      await this.setState({
        filter: {
          ...this.state.filter,
          categories: [value],
        }
      })
    } else {
      delete this.state.filter.categories
      await this.setState({
        filter: this.state.filter,
      })
    }
    this.loadProductType()
  }

  handleChangeStatus = async (value) => {
    await this.setState({
      filter: {
        ...this.state.filter,
        status: JSON.parse(value)
      }
    })
    this.loadProductType()
  }

  handleChangeSearch = async (e) => {
    await this.setState({ filter: {...this.state.filter, search: e.target.value}})
    this.loadProductType()
  }

  resetButton = async () => {
    await this.resetFilter()
    this.loadProductType()
  }
  deleteProductType(record) {
    const mutation = `mutation deleteProductType($id: Int!){
      deleteProductType(id: $id)
    }`
    this.setState({ loading: true })
    gql.request(mutation, { id: record })
      .then(res => {
        this.setState({ loading: false })
        message['success']('Delele success')
        this.loadProductType()
      })
      .catch(err => {
        message['error']('Something wrong!')
      })

  }
  pauseOrResume = (id, state) => {
    const mutation = `mutation updateProductTypeState($id: Int!, $state: String!){
      updateProductTypeState(id: $id, state: $state)
    }`
    this.setState({ loading: true })
    gql.request(mutation, { id: id, state: state })
      .then(res => {
        let type="resumed"
        if (state ==="active"){
          type= "paused"
        }
        message["success"](`Product Type has been ${type}`)
        this.loadProductType()
        this.setState({ loading: false })
      })
      .catch(err => {
        message["error"](_.get(err, "[0].message"))
      })
  }
  render() {
    const { productTypes, loading, categories, filter } = this.state
    const isAdmin = this.props.currentUser.roles.find(role => ['Administrator', 'Listing'].includes(role.name))
    let columns = [
      {
        title: 'Title',
        width: 200,
        dataIndex: 'title',
        render: (title, { images }) => <div>{images && <S3Image src={images[0]} style={{ maxWidth: 60, maxHeight: 60 }} />} <span style={{paddingLeft: 10}}>{title}</span></div>
      },
      {
        title: 'SKU',
        width: 100,
        key: 'sku',
        dataIndex: 'sku'
      },
      {
        title: 'Suppliers',
        width: 150,
        dataIndex: 'suppliers',
        render: (supplier) => supplier.map(supplier => `${supplier.first_name} ${supplier.last_name}`).join(', ')
      },
      {
        title: 'Categories',
        width: 140,
        dataIndex: 'categories',
        render: (categories) => categories.map(cat => cat.title).join(', ')
      },
      isAdmin?
      {
        title: 'Actions',
        dataIndex: 'id',
        width: 250,
        align: 'right',
        render: (id, record) => <div>
          <Link to={`/admin/product-types/${id}/variants`}>Variants</Link>
          <Divider type="vertical" />
          <Link to={`/admin/product-types/edit/${id}`}>
            <Button size="small">
              <Icon type="edit" theme="twoTone" />
            </Button>
          </Link>  
          <Divider type="vertical" />
          <Popconfirm
            title="Confirm to delete this product type?"
            onConfirm={() => this.deleteProductType(record.id)}
            okText="Yes"
            placement="top"
            cancelText="No"
          >
            <Button size="small"><Icon type="delete" style={{ color: "red" }} /></Button>
          </Popconfirm>
          <Divider type="vertical" />
          <Popconfirm
            title={`Are you sure to ${record.state==="active"?"pause":"resume"} this product type?`}
            onConfirm={() => this.pauseOrResume(id, record.state==="active"?"paused":"active")}
            okText="Yes"
            placement="top"
            cancelText="No"
          >
            <Button style={{ color:"#ffc000" }} size="small" icon={record.state === 'active' ? 'pause' : 'step-forward'}></Button>

          </Popconfirm>
        </div>
      }:{}
    ]
    const tableWidth = _.sum(columns.map(c => c.width))
    const content = (
      <div style={{ width: 250 }}>
        <Select placeholder="Filter by status" style={{ width: '100%', marginBottom: 15}} onChange={this.handleChangeStatus}>
          <Option value="true">Active</Option>
          <Option value="false">Inactive</Option>
        </Select>
        <Select placeholder="Filter by status" style={{ width: '100%', marginBottom: 15}} showSearch optionFilterProp="children" onChange={this.handleChangeCategory}>
          <Option value={null}>All categories</Option>
          {
            categories.map(category =>
              <Option key={category.id} value={category.id}>{category.title}</Option>
            )
          }
        </Select>
        <Button onClick={this.resetButton} >Reset Filter</Button>          
      </div>
    );
    return (
      <Container>
        <div className="product-types">
          <div className="product-types-filter">
            <Input.Search
              value={filter.search}
              style={{
                minWidth: 250, 
                height: 'fit-content' 
              }} 
              placeholder="Search..." 
              onChange={this.handleChangeSearch}
              onSearch={this.handleChangeSearch}
              addonBefore={
                <Popover
                trigger="click"
                placement="bottomLeft"
                content={content}>
                  Filter <Icon type="filter"/>
                </Popover>
                }
            />
              {/* <Popover placement="bottomLeft" content={content} trigger="click">
                <Button>
                  Filter <Icon type="filter"></Icon>
                </Button>
              </Popover>
              <Input.Search 
                value={filter.search}
                style={{
                  minWidth: 250, 
                  width: '90%', 
                  height: 'fit-content' 
                }} 
                placeholder="Search..." 
                enterButton
                onChange={this.handleChangeSearch}
                onSearch={this.applyButton}
              /> */}
          </div>
          <div className="product-type-toolbar">
            <ProductTypeToolbar />
          </div>
        </div>
        <Table pagination={this.state.pagination} onChange={this.onChangeTable} dataSource={productTypes} loading={loading} columns={columns} rowKey={row => row.id} scroll={{ x: tableWidth }}/>
      </Container>
    )
  }
}

export default connect(state =>({
	currentUser: state.app.currentUser
}))(SupplierProductTypes)