import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, InputNumber, Radio, Row, Col } from 'antd'
import styled from 'styled-components'

const CarrierFormItem = styled.div`
.ant-form-item-control{
  line-height: 0px;
}
`

class VariantCarrierCost extends Component {
  state = {
    defaultVariantCarrier: null,
    defaultCarrier: null,
  }
  componentDidMount(){
    const { carriers, carrierCosts } = this.props
    for (let i = 0; i < carrierCosts.length; i++) {
      if(carrierCosts[i].default){
        this.setState({defaultVariantCarrier: carrierCosts[i].carrier_id})
      }
    }
    for (let i = 0; i < carriers.length; i++) {
      if(carriers[i].default){
        this.setState({defaultCarrier: carriers[i].carrier_id})
      }
    }
  }
  render() {
    const { carriers, carrierCosts } = this.props
    // console.log("carriers", carriers)
    // console.log("carrierCosts", carrierCosts)
    if (!carriers.length) {
      return null
    }

    let mapCostByCarrierId = {}
    let mapIdByCarrierId = {}
    let mapDefaultByCarrierId = {}
    let hasVariantDefault=false
    for (let i = 0; i < carrierCosts.length; i++) {
      mapCostByCarrierId[carrierCosts[i].carrier_id] = carrierCosts[i].cost
      mapIdByCarrierId[carrierCosts[i].carrier_id] = carrierCosts[i].id
      //mapIdByCarrierId[carrierCosts[i].carrier_id] = carrierCosts[i].id
      mapDefaultByCarrierId[carrierCosts[i].carrier_id] = carrierCosts[i].default
      if(carrierCosts[i].default){
        hasVariantDefault=true
      }
    }

    let data = carriers.map((carrier) => {
      const carrierId = carrier.carrier_id
      const id = mapIdByCarrierId[carrierId] ? mapIdByCarrierId[carrierId] : null
      let cost = null
      if(mapCostByCarrierId[carrierId]!==null && mapCostByCarrierId[carrierId] !==""  && mapCostByCarrierId[carrierId]>=0){
        cost = mapCostByCarrierId[carrierId]
      }
      return {
        id,
        carrier_id: carrierId,
        cost: cost,//mapCostByCarrierId[carrierId] ? mapCostByCarrierId[carrierId] : null,
        default: mapDefaultByCarrierId[carrierId] ? mapDefaultByCarrierId[carrierId] : hasVariantDefault?false: carrier.default
      }
    })
    return (
      <div className={'carrier-cost-container'}>
        {
          <Radio.Group style={{ width: '100%' }} value={this.state.defaultVariantCarrier ? this.state.defaultVariantCarrier : this.state.defaultCarrier} onChange={(e) => {
            this.setState({ defaultVariantCarrier: e.target.value })
            if (this.props.onChange) {
              data.map((item) => {
                if (item.carrier_id === e.target.value) {
                  return item.default = true
                }else{item.default=false}
              })
              this.props.onChange(data)
            }
            }}>
            {carriers.map((carrier, index) => {
              const carrierId = carrier.carrier_id
              let defaultCostValue = carrier.price
              if(mapCostByCarrierId[carrierId]!==null && mapCostByCarrierId[carrierId]!=="" && mapCostByCarrierId[carrierId]>=0){
                defaultCostValue=mapCostByCarrierId[carrierId]
              }
              // const defaultCostValue = mapCostByCarrierId[carrierId] >=0 ?
              //   mapCostByCarrierId[carrierId] : carrier.price ? carrier.price : 0
              return (
                <div key={index}>
                <div style={{ display: 'inline-block', width: '90%' }}>
                <Row gutter={24} type="flex" style={{alignItems:"center"}}>
                <Col span={10} style={{display:"flex",alignItems:"center"}}>
                  <Radio value={carrier.carrier_id} key={carrier.carrier_id} style={{ display: 'block' }}>
                  </Radio>
                  {carrier.carrier.name}
                  </Col>
                  <Col style={{ textAlign: 'left', paddingRight: 0}} span={14}>
                    <CarrierFormItem>
                      <Form.Item>
                          <InputNumber
                            min={0}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            onChange={(newValue) => {
                              if (this.props.onChange) {
                                data.map((item) => {
                                  if (item.carrier_id === carrierId) {
                                    return item.cost = newValue
                                  }
                                })
                                //console.log("data", data)
                                this.props.onChange(data)
                              }
                            }}
                            value={defaultCostValue}
                          />
                      </Form.Item>
                    </CarrierFormItem>
                  </Col>
                </Row>
              </div>
              </div>
              )
            }
            )}
          </Radio.Group>
        }
      </div>
    )
  }
}

VariantCarrierCost.propTypes = {
  carriers: PropTypes.array,
  carrierCosts: PropTypes.array,
  onChange: PropTypes.func,
}

export default VariantCarrierCost
