import React from 'react'
import {connect} from 'react-redux'
import { Select } from 'antd'
import {setCurrentSite} from '../../actions'

class SiteSelector extends React.Component{
  state = {
    sites: []
  }

  componentDidMount() {
    const {sites, setCurrentSite} = this.props
    let currentSiteID = localStorage.getItem("currentSiteID")
    if (currentSiteID && sites.find(site => site.id.toString() === currentSiteID.toString())){
      setCurrentSite(sites.find(site => site.status && site.id.toString() === currentSiteID.toString()))
    }else if (sites.length){
      setCurrentSite(sites[0])
    }
  }

  onSiteChange = site_id => {
    const {setCurrentSite, sites} = this.props
    setCurrentSite(sites.find(site => site.id === site_id))
  }

  render() {
    const {currentUser, currentSite, sites} = this.props
    if (!currentUser) return null
    if (!currentUser.roles.find(role => role.name === 'Seller')) return null
    return (
      <Select className="oppprint-site-select" style={{minWidth: 250}} onChange={this.onSiteChange} value={currentSite ? currentSite.id : null}>
        {sites.map(site => <Select.Option key={site.id} value={site.id}>{site.title}</Select.Option>)}
      </Select>
    )
  }
}

export default connect(
  state => {
    return {
      currentUser: state.app.currentUser,
      currentSite: state.app.currentSite,
      sites: state.app.sellerSites || []
    }
  },
  {setCurrentSite}
)(SiteSelector)