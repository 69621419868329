import React, { Component } from 'react'
import gql from '../../api/gql'
import _ from 'lodash'
import { Select, message } from 'antd'
const { Option } = Select;

class SupplierSelect extends Component {
  state = {
    value: [],
    users: [],
    loading: false,
  }

  componentDidMount() {
    this.setState({ value : this.props.value ? this.props.value : []})
    this.loadSupplier()
  }
  
  componentDidUpdate(prevProps) {
    if( prevProps.value !== this.props.value){
      this.setState({ value : this.props.value ? this.props.value : []})
    }
  }

  loadSupplier() {
    const query = `query users($filter: UserFilter){
      users(filter: $filter){
        hits{id first_name last_name email}
      }
    }`
    this.setState({ loading: true })
    gql.request(query, {
      filter: {
        role: "Supplier"
      }
    })
    .then(res => {
      this.setState({ users: res.users.hits || [] })
    })
    .catch(err => {
      message["error"](_.get(err, "[0].message"));
      this.setState({ loading: false })
    });
  }

  render() {
    const { users } = this.state
    return (
      <Select
        placeholder="Select supplier..."
        mode="multiple"
        value={this.state.value}
        optionFilterProp='children'
        onChange={value => this.setState({ value }, () => this.props.onChange(this.state.value))}
        dropdownRender={menu => (
          <div>
            {menu}
          </div>
        )}
      >
        {
          users.map(item => (
            <Option key={item.id}>{item.first_name} {item.last_name}</Option>
          ))
        }
      </Select>
    )
  }

}
export default SupplierSelect;
