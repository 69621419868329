import React, { Component } from 'react'
import { Form, Input, Button, notification, Switch, Skeleton, Row, Col, Card } from 'antd'
import gql from '../../api/gql'
import { history } from '../../history'
import _ from 'lodash'
import Wysiwyg  from '../../components/wysiwyg'
import styled from 'styled-components'

const Container = styled.div``

class UpdateNews extends Component{
  state={
    news: null,
    loading:false,
  }
  onhandleSubmit=(e)=>{
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { id } = this.props.match.params
         if (id) {
        const mutation = `mutation updateNews($id: Int!,$input:EditNews!){
          updateNews(id: $id,input: $input)
            {
              id 
              title 
              notification
              send_mail
              status
            }
          }`
         
  
        this.setState({ loading: true })
        gql.request(mutation, {
          input: values,
          id: id
        }).then(res => {
          this.setState({ loading: false })
          history.goBack()
        }).catch(err => {
          notification['error']({
            message: _.get(err, '[0].message')
          })
          this.setState({ loading: false })
        })
      }
    }
    })
  }
  componentDidMount() {
    this.getUpdateNews();
  }

  getUpdateNews() {
    this.setState({ loading: false })
    const { id } = this.props.match.params
    if (id) {
      const query = `query newsById{newsById(id:${id}){
        id
        title
        content
        notification
        send_mail
        }
      }`;
      this.setState({ loading: true })
      gql.request(query, {
      }).then(res => {
        this.setState({ news: res.newsById || {} });
      })
    }
  }

  render(){
    const { news } = this.state
    const { getFieldDecorator } = this.props.form
    const { id } = this.props.match.params
    if (id && !news) return <Skeleton />
    return(
      <Container>
        <Form onSubmit={this.onhandleSubmit}>
        <Row  gutter={20}>
          <Col span={24} lg={16}>
            <Card title="Detail" style={{ marginBottom: 20 }}>
              <Form.Item label="Title">
                {
                  getFieldDecorator('title', {
                    initialValue: news.title,
                    rules: [
                      {
                        required: true,
                        message: "Please enter title!"
                      }
                    ]
                  })(<Input style= {{width: 250}}/>)
                }
              </Form.Item>
              <Form.Item label="Content">
              {
              getFieldDecorator('content', {
                initialValue: news.content,
                rules: [
                  {
                    required: true,
                    message: "Please enter content!"
                  }
                ]
                })(<Wysiwyg />)
                }
              </Form.Item>
            </Card>
          </Col>
          <Col span={24} lg={8}>
            <Card title="Option" style={{ marginBottom: 20 }}>
              <Form.Item label="Notification">
              {
                getFieldDecorator('notification', {
                  initialValue: news.notification,
                  valuePropName: 'checked',
                  rules: [{
                    required: false
                  }]
                })(<Switch size="small" />)
              }
              </Form.Item>
              <Form.Item label="Send Mail">
                {
                  getFieldDecorator('send_mail', {
                    valuePropName: 'checked',
                    initialValue: news.send_mail,
                    rules: [{ required: false }]
                  })(
                    (<Switch size="small" />)
                  )}
              </Form.Item>
          </Card>  
          </Col>
        </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon="save">Save</Button>
            <Button icon="undo" style={{marginLeft: 10}} onClick={()=> history.goBack()} >Cancel</Button>
          </Form.Item>
        </Form>
      </Container>
    )
  }
}
export default Form.create({name:'form'})(UpdateNews)