import React, { Component } from 'react'
import { Spin, message } from 'antd'
import { history } from '../history'
import gql from '../api/gql'

export default class Verification extends Component {
  constructor(props) {
    super(props)
    const { params: { token } } = this.props.match
    this.state = { token: token }
  }
  verify = () => {
    const mutation = `mutation accountActivation($token: String!){
      accountActivation(token: $token)
    }`
    gql.request(mutation, { token: this.state.token })
      .then(res => {
        message['success']('Verify successfilly!')
        setTimeout(() => history.push("/login"), 1000)
        return
      }).catch(err => {
        message['error'](err[0].message)
        setTimeout(() => history.push("/login"), 1000)
      })
  }

  componentDidMount() {
    this.verify()
  }

  render() {
    return (
      <Spin size="large" style={{ height: "100vh", maxHeight: "unset" }} >
        <div style={{ height: "100vh", display: "flex" }}>
        </div>
      </Spin>
    )
  }
}
