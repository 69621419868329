import React, { Component } from 'react';
import ListCarrier from '../../components/carrier/ListCarrier'
class Carrier extends Component {
  render() {
    return (
      <div>
        <ListCarrier/>
      </div>
    );
  }
}

export default Carrier;