import React from 'react'
import { Form, notification, Input, Button,Checkbox } from 'antd'
import gql from '../../api/gql'
import _ from 'lodash'
import styled from 'styled-components'

const Container = styled.div`
  // .ant-form-item-label{
  //   margin-top:7px
  // }
  // .checkbox{
  //   margin: 0px 30px 10px
  // }
  `


class CustomerInfo extends React.Component {

  constructor(props){
    super(props);
    //const billing = this.props.order.billing
    //const shipping = this.props.order.shipping
    //const defaultChecked = shipping.id == billing.id
    this.state = {
      order: this.props.order,
      customer: this.props.order.customer,
      billing: this.props.order.billing,
      shipping: this.props.order.shipping,
      shippingSameBilling: this.props.order.billing.id === this.props.order.shipping.id,
      loading: false,
      //defaultChecked : defaultChecked,
    }
  }


  onClose = () => {
    this.props.onClose(false);
  }

  onUpdate = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const {b_address1,b_address2, b_state, b_state_code, b_zip, b_country, b_city, b_country_code,address1,address2, state, state_code, zip, country, city, country_code,email, firstName, lastName, phone } = values
        const billing ={
          address1: b_address1  ,
          address2: b_address2,
          state: b_state ,
          state_code: b_state_code  ,
          zip: b_zip,
          country: b_country ,
          city: b_city,
          country_code: b_country_code
        }
        const shipping = {
          address1  ,
          address2,
          city,
          zip ,
          state,
          state_code,
          country,
          country_code,
        }
        const customer_info = {
          customer:{
            first_name: firstName,
            last_name: lastName,
            email,
            phone: phone ? phone : ""
          },
          billing :{...billing}
        }
        if (!this.state.shippingSameBilling) {
          customer_info.shipping = shipping
        }
        const mutation = `mutation updateCustomerInfo($order_id: Int!, $customer_info: CustomerInfo!){
          updateCustomerInfo(order_id: $order_id, customer_info:$customer_info){id}
        }`
        this.setState({ loading: true })
        gql.request(mutation, {
          order_id: this.props.order.id,
          customer_info
        }).then(res => {
          notification['success']({ message: "Customer infor has been updated" })
          this.setState({ loading: false })
          this.props.onUpdate()
        }).catch(err => {
          notification['error']({
            message: _.get(err, '[0].message')
          })
          this.setState({ loading: false })
        })
      }
    })
  }


  render() {
    const { customer, billing, shipping, shippingSameBilling, order } = this.state
    const {currentUser} = this.props
    const isSupporter = currentUser && currentUser.roles.find(el => ['Support'].includes(el.name))
    const isAdmin = currentUser && currentUser.roles.find(role => ['Administrator'].includes(role.name))
    const isSeller = currentUser && currentUser.roles.find(role => ['Seller'].includes(role.name)) 
    const isEditAble = isAdmin || isSupporter || isSeller || ['pending', 'pending_design', 'processing', 'ready','sup_failed', 'in_production'].includes(order.status)
    const { getFieldDecorator } = this.props.form
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 6,
        },
      },
    };
    return (
      <Container>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onSubmit= {this.onUpdate}>
          <div >
            <h3>Customer:</h3>
            <Form.Item label="First Name">
              {
                getFieldDecorator('firstName', {
                  initialValue: customer ? customer.first_name: '',
                  rules: [
                    {
                      required: true,
                      message: "Please input first name"
                    }
                  ]
                })(<Input readOnly={!isEditAble}/>)
              }
            </Form.Item>
            <Form.Item label="Last Name">
              {
                getFieldDecorator('lastName', {
                  initialValue: customer ? customer.last_name: '',
                  rules: [
                    {
                      required: true,
                      message: "Please input last name"
                    }
                  ]
                })(<Input   readOnly={!isEditAble}/>)
              }
            </Form.Item>
            </div>
            <Form.Item label="Email">
              {
                getFieldDecorator('email', {
                  initialValue: customer ? customer.email : '',
                  rules: [
                    {
                      required: true,
                      message: "Please input email"
                    }
                  ]
                })(<Input  readOnly={!isEditAble}/>)
              }
            </Form.Item>
            <Form.Item label="Phone">
              {
                getFieldDecorator('phone', {
                  initialValue: customer ? customer.phone : '',
                })(<Input  readOnly={!isEditAble}/>)
              }
            </Form.Item>
          <div>
            <h3> Billing:</h3>
            
            <Form.Item label="Address 1">
              {
                getFieldDecorator('b_address1', {
                  initialValue: billing ? billing.address1 : '',
                })(<Input  readOnly={!isEditAble}/>)
              }
            </Form.Item>
            <Form.Item label="Address 2 ">
              {
                getFieldDecorator('b_address2', {
                  initialValue: billing ? billing.address2 : '',
                })(<Input  readOnly={!isEditAble}/>)
              }
            </Form.Item>
            <Form.Item label="City">
              {
                getFieldDecorator('b_city', {
                  initialValue: billing ? billing.city : '',
                })(<Input readOnly={!isEditAble}/>)
              }
            </Form.Item>
            <Form.Item label="State">
              {
                getFieldDecorator('b_state', {
                  initialValue: billing ? billing.state : '',
                })(<Input  readOnly={!isEditAble}/>)
              }
            </Form.Item>
            <Form.Item label="State Code">
              {
                getFieldDecorator('b_state_code', {
                  initialValue: billing ? billing.state_code : '',
                })(<Input readOnly={!isEditAble} />)
              }
            </Form.Item>
            <Form.Item label="Country">
              {
                getFieldDecorator('b_country', {
                  initialValue: billing ? billing.country : '',
                })(<Input readOnly={!isEditAble} />)
              }
            </Form.Item>
            <Form.Item label="Country code">
              {
                getFieldDecorator('b_country_code', {
                  initialValue: billing ? billing.country_code : '',
                })(<Input  readOnly={!isEditAble} />)
              }
            </Form.Item>
            <Form.Item label="Zip">
              {
                getFieldDecorator('b_zip', {
                  initialValue: billing ? billing.zip : '',
                })(<Input  readOnly={!isEditAble}/>)
              }
            </Form.Item>
          </div>
          <div style={{paddingBottom: 53}}>
            <h3> Shipping: </h3>
            <Form.Item {...tailFormItemLayout}>
                <Checkbox
                defaultChecked={shippingSameBilling}
                disabled={!isEditAble}
                onChange = {(e) =>{
                  this.setState({
                    shippingSameBilling: e.target.checked
                  })
                }}
                >
                Same as billing address
              </Checkbox>
            </Form.Item>
            <Form.Item label="Address 1">
              {
                getFieldDecorator('address1', {
                  initialValue: shipping ? shipping.address1 : "",
                })(<Input disabled={shippingSameBilling} readOnly={!isEditAble}/>)
              }
            </Form.Item>
            <Form.Item label="Address 2">
              {
                getFieldDecorator('address2', {
                  initialValue: shipping ? shipping.address2 : '',
                })(<Input disabled={shippingSameBilling} readOnly={!isEditAble}/>)
              }
            </Form.Item>
            <Form.Item label="City">
              {
                getFieldDecorator('city', {
                  initialValue: shipping ? shipping.city : '',
                })(<Input disabled={shippingSameBilling} readOnly={!isEditAble}/>)
              }
            </Form.Item>
            <Form.Item label="State">
              {
                getFieldDecorator('state', {
                  initialValue: shipping ? shipping.state : '',
                })(<Input disabled={shippingSameBilling} readOnly={!isEditAble}/>)
              }
            </Form.Item>
            <Form.Item label="State Code ">
              {
                getFieldDecorator('state_code', {
                  initialValue: shipping ? shipping.state_code : '',
                })(<Input disabled={shippingSameBilling} readOnly={!isEditAble}/>)
              }
            </Form.Item>
            <Form.Item label="Country">
              {
                getFieldDecorator('country', {
                  initialValue: shipping ? shipping.country : '',
                })(<Input disabled={shippingSameBilling} readOnly={!isEditAble}/>)
              }
            </Form.Item>
            <Form.Item label="Country code">
              {
                getFieldDecorator('country_code', {
                  initialValue: shipping ? shipping.country_code : '',
                })(<Input disabled={shippingSameBilling} readOnly={!isEditAble}/>)
              }
            </Form.Item>
            <Form.Item label="Zip ">
              {
                getFieldDecorator('zip', {
                  initialValue: shipping ? shipping.zip : '',
                })(<Input disabled={shippingSameBilling} readOnly={!isEditAble}/>)
              }
            </Form.Item>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
          >
            <Button onClick={() => this.props.onClose()} style = {{marginRight:10}}>Cancel</Button> 
            <Button type="primary" htmlType="submit" loading={this.state.loading}  disabled={!isEditAble}>Save</Button>
          </div>
        </Form>
      </Container>
    )
  }
}
export default Form.create({ form_id: 'customer_info_form' })(CustomerInfo)