import React from 'react'
import { Button, Row, Col, Form, Input } from 'antd'
import gql from '../../api/gql'

export default class CustomerFiler extends React.Component {
  state = {
    fetching: false,
    sites: [],
    products: [],
    filter: {
      search: null
    }
  }

  componentDidMount() {
    this.fetchSites()
  }

  fetchSites() {
    const query = `query sites{sites{id,title}}`
    gql.request(query).then(res => {
      this.setState({ sites: res.sites })
    })
  }

  fetchProducts = search => {
    this.setState({ fetching: true })
    const query = `query products($filter: ProductFilter){
      products(filter: $filter){
        hits{
          id, title
        }
      }
    }`
    gql.request(query, {
      filter: {
        limit: 20,
        search: search
      }
    }).then(res => {
      this.setState({
        fetching: false,
        products: res.products.hits || []
      })
    })
  }

  applyFilter = (e) => {
    e.preventDefault()
    const { onChange } = this.props
    if (typeof onChange === 'function') {
      onChange(this.state.filter)
    }
  }

  render() {
    const { filter } = this.state
    return (
      <Form onSubmit={this.applyFilter}>
        <Row type="flex" gutter={20}>
          <Col span={24} lg={6}>
            <Form.Item>
              <Input placeholder="Search" value={filter.search} onChange={e => this.setState({ filter: { ...filter, search: e.target.value } })} />
            </Form.Item>
          </Col>
          <Col span={24} lg={2}>
            <Form.Item>
              <Button onClick={this.applyFilter}>Apply</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}