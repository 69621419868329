import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Form, InputNumber} from 'antd'

class VariantSupplierCost extends Component {
  render() {
    const {suppliers, supplierCosts} = this.props

    if (!suppliers.length) {
      return null
    }
    let mapCostByUserId = {}
    let mapIdByUserId = {}
    for (let i = 0; i < supplierCosts.length; i++) {
      mapCostByUserId[supplierCosts[i].user_id] = supplierCosts[i].cost
      mapIdByUserId[supplierCosts[i].user_id] = supplierCosts[i].id
    }
    let data = suppliers.map((supplier) => {
      const userId = supplier.id
      const id = mapIdByUserId[userId] ? mapIdByUserId[userId] : null
      return {
        id,
        user_id: userId,
        cost: mapCostByUserId[userId] ? mapCostByUserId[userId] : null,
      }
    })

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '50% 50%'}}>
          {
            suppliers.map((supplier, index) => {
              const userId = supplier.id
              const defaultCostValue = mapCostByUserId[userId] ?
                  mapCostByUserId[userId] :
                  null
              return (
                <Form.Item key={index} label={`${supplier.first_name} ${supplier.last_name}`}>
                  <InputNumber
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g,',')}
                      onChange={(newValue) => {
                        if (this.props.onChange) {
                          data.forEach((item) => {
                            if (item.user_id === userId) {
                              return item.cost = newValue
                            }
                          })
                          this.props.onChange(data)
                        }
                      }}
                      value={defaultCostValue}
                      />
                </Form.Item>
              )
            })
          }
        </div>
    )
  }
}

VariantSupplierCost.propTypes = {
  suppliers: PropTypes.array,
  supplierCosts: PropTypes.array,
  onChange: PropTypes.func,
}

export default VariantSupplierCost
