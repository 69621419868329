import React from 'react'
import ReactQuill from "react-quill";
//import { ImageResize } from 'quill-image-resize-module'
import 'react-quill/dist/quill.snow.css';
import { Modal } from "antd";
import styled from 'styled-components';
import UploadImages from './UploadImages';
//window.Quill = Quill
//Quill.register('modules/imageResize', ImageResize)
const Container = styled.div`
.ql-toolbar.ql-snow .ql-formats {
	margin-right: 0px;
}
.ql-snow .ql-picker-label::before{
	line-height: 0px;
}
.ql-picker-label {
	outline: none;
}
.ql-editor{
	min-height: 300px;
}
line-height: 24px;
.ql-snow .ql-picker-options .ql-picker-item{
	outline: none;
}
`

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default class Wysiwyg extends React.Component {
  state = {
    text: this.props.value,
    imageUploadBox: false,
    mediaURL: null,
    previewVisible: false,
    previewImage: '',
    fileList: [],
  };

  componentDidMount() {
  }

  handleOk = () => {
    const { fileList, imageUploadBox } = this.state;
    if (fileList && !fileList.length) return;

    if (imageUploadBox) {
      fileList.map(file => {
        this.insertImage(file.url || file.response.url, 'image')
      })
    }

    this.handleUploadBoxCancel()
  };

  insertImage = (url, type) => {
    this.quillRef.getEditor().insertEmbed(this.quillRef.getEditor().getSelection(true).index, type, url, 'user')
    this.quillRef.focus()
  }

  handleUploadBoxCancel = () => {
    this.setState({
      imageUploadBox: false,
      fileList: []
    })
  }

  showImageUploadModal = () => {
    this.setState({ imageUploadBox: true })
  }

  /*
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, false] }],

        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['link', 'image', 'video'],
        ['clean'],
      ],
      handlers: {
        image: () => {
          this.showImageUploadModal();
        },
        // insertImage: this.insertImage,
      }
    }
  };

  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'image',
    'video',
  ];

  handleChange = value => {
    this.setState({ text: value });
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChangeList = ({ fileList }) => {
    this.setState({ fileList })
  }

  render() {
    const { imageUploadBox, previewVisible, previewImage, fileList } = this.state

    return (
      <Container>
        <ReactQuill
          ref={(ref) => this.quillRef = ref}
          theme="snow"
          value={this.state.text}
          onChange={this.handleChange}
          modules={this.modules}
          formats={this.formats}

        />
        <Modal visible={imageUploadBox} title="Upload Image" onCancel={this.handleUploadBoxCancel} onOk={this.handleOk}>
          <div className="clearfix">
            <UploadImages
              value={fileList}
              onChange={(list) => {
                this.setState({ fileList: list })
              }}
            />
            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </div>
        </Modal>
      </Container>
    )
  }
}