import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Form, Input} from 'antd'

class VariantSupplierProductVariant extends Component {
  render() {
    const {suppliers, supplierProductVariants} = this.props
    if (!suppliers.length) {
      return null
    }
    let mapSupplierVariantIdByUserId = {}
    let mapIdByUserId = {}
    for (let i = 0; i < supplierProductVariants.length; i++) {
      mapSupplierVariantIdByUserId[supplierProductVariants[i].user_id] = supplierProductVariants[i].supplier_product_variant_id
      mapIdByUserId[supplierProductVariants[i].user_id] = supplierProductVariants[i].id
    }
    let data = suppliers.map((supplier) => {
      const userId = supplier.id
      const id = mapIdByUserId[userId] ? mapIdByUserId[userId] : null
      return {
        id,
        user_id: userId,
        supplier_product_variant_id: mapSupplierVariantIdByUserId[userId] ? mapSupplierVariantIdByUserId[userId] : null,
      }
    })

    return (
        <div className={'supplier-cost-container'}>
          {
            suppliers.filter(s=>s.api_support).map((supplier, index) => {
              const userId = supplier.id
              const defaultSupplierVariantIdValue = mapSupplierVariantIdByUserId[userId] ?
              mapSupplierVariantIdByUserId[userId] :
                  null
              return (
                  <Form.Item
                      key={index}
                      label={`${supplier.first_name} ${supplier.last_name}`}>
                    <Input
                        onChange={(newValue) => {
                          if (this.props.onChange) {
                            data.map((item) => {
                              if (item.user_id === userId) {
                                console.log("newValue", newValue.target.value)
                                return item.supplier_product_variant_id = newValue.target.value
                              }
                            })
                            this.props.onChange(data)
                          }
                        }}
                        value={defaultSupplierVariantIdValue}
                    />
                  </Form.Item>
              )
            })
          }
        </div>
    )
  }
}

VariantSupplierProductVariant.propTypes = {
  suppliers: PropTypes.array,
  supplierProductVariants: PropTypes.array,
  onChange: PropTypes.func,
}

export default VariantSupplierProductVariant
