import React from 'react'
import ExportTemplates from '../../components/manager_files/ExportTemplates'

class ExportOrderTemplate extends React.Component{
  render(){
    return(
      <ExportTemplates/>
    )
  }
}
export default ExportOrderTemplate