import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Table, Tag} from 'antd'

class ProductTypeVariantSelectModal extends Component {
  state = {
    selected: [],
  }

  render() {
    const {variants, attributes} = this.props
    let columns = [
      {
        title: 'SKU',
        dataIndex: 'sku',
        render: (text) => {
          return (
              <strong>{text}</strong>
          )
        },
      },
    ]

    for (let i = 0; i < attributes.length; i++) {
      columns.push({
        title: attributes[i].name,
        dataIndex: attributes[i].slug,
        render: (_, record) => {
          const dataItem =
              typeof record.attributes[i] !== 'undefined'
                  ? record.attributes[i]
                  : null
          if (!dataItem) {
            return 'N/A'
          }
          return (
              <Tag>{dataItem.option}</Tag>
          )
        },
      })
    }

    return (
        <div>
          <Table
              rowKey={(row, index) => index}
              pagination={false}
              columns={columns}
              dataSource={variants}
              rowSelection={{
                onChange: (selectedRowKeys, selectedRows) => {
                  this.setState({
                    selected: selectedRowKeys,
                  }, () => {
                    const values = selectedRowKeys.map(v => {
                      return variants[v]
                    })
                    if (this.props.onChange) {
                      this.props.onChange(values)
                    }
                  })
                },
              }}
          />
        </div>
    )
  }
}

ProductTypeVariantSelectModal.propTypes = {
  attributes: PropTypes.array,
  variants: PropTypes.array,
  onChange: PropTypes.func,
}

export default ProductTypeVariantSelectModal
