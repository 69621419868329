import React from 'react'
import { Row, Col, Card, Progress, Modal, Input, message, } from 'antd'
import Upload from '../Upload'
import gql from '../../api/gql'
import PrintFilePreview from '../file/PrintFilePreview'
import styled from 'styled-components'

const Container = styled.div`
.ant-upload.ant-upload-select.ant-upload-select-text{
  width:100%;
}
`
const UploadContainer = styled.div`
  position: relative;
  height:0;
  padding-bottom:67%;
  .upload-wrapper{
    display: block;
    position: relative;
    height: 0;
    padding-bottom:67%;
    .upload-area{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
class UploadDesign extends React.Component {
  state = {
    file: this.props.file,
    uploading: false,
    percent: 0,
    open: false,
    newSKU: null,
  }

  onUpload = file => {
    let query = `mutation createFile($input: NewFile!){
      createFile(input: $input){id, sku}
    }`
    gql.request(query, {
      input: {
        source: 'aws',
        key: file.key,
        file_name: file.file.name,
        file_mime: file.file.type,
        file_size: file.file.size,
      }
    }).then(({ createFile }) => {
      const { file } = this.state
      this.setState({
        uploading: false,
        file: { ...file, file_id: createFile.id, sku: createFile.sku }
      }, () => this.props.onChange(this.state.file))
    }).catch(err => {
      this.setState({
        uploading: false,
        percent: 0
      })
    })
  }

  beforeUpload = (file, fileList) =>{
    var regex = new RegExp("^(\\d|\\w)+(.jpg|.jpeg|.png|.tiff|.tif|.psd)$")
    var result = regex.test(file.name);
    if (result===false){
      message["error"]("File name cannot contain empty space or any special characters")
      return false
    }else{
      return true
    }
  }

  render() {
    const { file, uploading, percent } = this.state
    return (
      <UploadContainer>
        <Upload
          beforeUpload={this.beforeUpload}
          onUpload={this.onUpload}
          onProcess={e => {
            this.setState({
              uploading: true,
              percent: Math.round(e.loaded / e.total * 100)
            })
          }}
          fileList={false}
          accept=".jpg, .png, .tiff, .psd"
          className="upload-wrapper">
          <div className="upload-area">
            {uploading ?
              <Progress type="circle" percent={percent} width={40} strokeColor="#1890ff" /> :
              (file.file_id ? <PrintFilePreview file_id={file.file_id} /> : <span style={{ textAlign: 'center', padding: '0 10px' }}>Missing, click or drag to upload</span>)
            }
          </div>
        </Upload>
      </UploadContainer>
    )
  }
}

export default class UploadDesigns extends React.Component {
  state = {
    product: null,
    printFiles: this.props.printFiles,
    loading: true,
    percent: 0,
    submitLoading: false,
    uploadLoading: {},
  }

  componentDidMount() {

  }
  changeSKU = (id) => {
    const mutation = `mutation updateFileUUID($file_id: ID!, $uuid: String!){
      updateFileUUID(file_id: $file_id, uuid: $uuid){
        id
        sku
      }
    }`
    gql.request(mutation, {file_id: id, uuid: this.state.newSKU})
    .then(res => {
      let list = this.state.printFiles.map(el => {
        if(el.file_id === id){
          el.sku = this.state.newSKU
        }
        return el
      })
      this.setState({printFiles: list, newSKU: null, open: false})
    })
    .catch( err => {
      message["error"]('SKU already existed. Please type other SKU.')
    })
  }

  render() {
    const { printFiles } = this.state
    //return JSON.stringify(printFiles)
    return (
      <Container>
        <Row type="flex" gutter={10}>
          {printFiles && printFiles.map((print_file, index) => (
            <Col key={index} span={6}>
              <Card
                hoverable
                cover={(
                  <UploadDesign file={print_file} onChange={file => {
                    const { printFiles } = this.state
                    printFiles[index] = file
                    this.setState({ printFiles }, () => this.props.onChange(this.state.printFiles))
                  }} />
                )}
              >
                <Card.Meta title={print_file.title}
                  description={`${print_file.width} x ${print_file.height}`} />
                {/* {print_file.sku && <Card.Meta description={<div>SKU: {print_file.sku} <Icon onClick={() => this.setState({ open: true })} type='edit' /></div>} />} */}
              </Card>
              <Modal
                visible={this.state.open}
                onCancel={() => this.setState({ open: false })}
                onOk={() => this.changeSKU(print_file.file_id)}
              >
                <Row
                style={{display: 'flex', marginTop: 20}}
                >
                <span style={{width: 50, lineHeight: '32px'}}>SKU :</span> 
                <Input onChange={(value) => this.setState({newSKU: value.target.value.replace(new RegExp(" ", 'g'), "-")})}style={{width: '100%'}} defaultValue={print_file.sku} />
                </Row>
              </Modal>
            </Col>
          ))}
        </Row>
      </Container>
    )
  }
}