import React from 'react'
import gql from '../../api/gql'
import { notification, Row, Col, Form, Button, Input, Card, Skeleton } from 'antd'
import _ from 'lodash'
import Wysiwyg from '../../components/wysiwyg'
import ProductImages from '../../components/products/EditProductImages'


class EditProduct extends React.Component{
  state = {
    loading: false,
    product: null,
  }
  

  
  componentDidMount() {
    const {id} = this.props.match.params
    const query = `query productById($id: String!){productByID(id: $id){
      id, title, description, images, tags,
      categories{id, title}
      variants{id, image, attributes{
        name, option
      }},
      sites{id, title}
    }}`
    gql.request(query, {id}).then(res => {
      this.setState({product: res.productByID || {}})
    }).catch(err => {
      notification['error']({
        message: _.get(err, '[0].message')
      })
    })
  }

  updateProduct = e => {
    e.preventDefault()
    const {product} = this.state
    this.setState({loading: true})
    const query = `mutation updateProduct($id: String!, $input: ProductUpdate!){
      updateProduct(id: $id, input: $input){
        id
      }
    }`
    gql.request(query, {
      id: product.id,
      input: {
        title: product.title
      }
    }).then(res => {
      this.setState({loading: false})
      notification['success']({
        message: `Product: ${product.title} has been updated`
      })
    }).catch(err => {
      this.setState({loading: false})
      notification['error']({
        message: _.get(err, '[0].message')
      })
    })
  }
 

  render() {
    
    const {product, loading} = this.state
    const {getFieldDecorator} = this.props.form
    if (!product) {
      return <Skeleton/>
    }
    return (
      <div>
        <Form onSubmit={this.updateProduct}>
          <Row gutter={20}>
            <Col span={24} lg={18}>
              <Form.Item>
                {getFieldDecorator('title', {
                  initialValue: product.title
                })(
                  <Input/>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('description', {
                  initialValue: product.description
                })(
                  <Wysiwyg/>
                )}
              </Form.Item>
              <Form.Item>
                <Card title="Images">
                  <ProductImages images={product.images}/>
                </Card>
              </Form.Item>
              <Form.Item>
                <Card title="Variants">
                  {JSON.stringify(product.variants)}
                </Card>
              </Form.Item>
            </Col>
            <Col span={24} lg={6}>
              <Form.Item>
                <Card title="Sites">
                  {JSON.stringify(product.sites)}
                </Card>
              </Form.Item>
              <Form.Item>
                <Card title="Categories">
                  {JSON.stringify(product.categories)}
                </Card>
              </Form.Item>
              <Form.Item>
                <Card title="Tags">
                  {JSON.stringify(product.tags)}
                </Card>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button type="primary" size="large" loading={loading} htmlType="submit" icon="save">Save</Button>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default Form.create({name: 'edit-product-form'})(EditProduct)