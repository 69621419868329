import React from 'react'
import {Icon} from 'antd'
import Upload from '../Upload'
import S3Image from '../S3Image'
import styled from 'styled-components'
const Container = styled.div`
.delete-action{
  position: relative;
  font-size: 20px;
  color: #f2f2f2;
  height: calc(100% - 15px);
  cursor: pointer;
  z-index: 1;
}
.icon-action-delete{
  position: absolute;
  right: 5px;
  width: 20px;
  background: rgba(0, 0, 0, 0.65);
  border-radius: 20px;
  height: 20px;
  top: 5px;
  svg{
    margin-top:5px;
    width: 10px;
    height: 10px;
  }
}
.img-item{
  padding: 5px;
}
.image-product{
  display: inline-block; 
  float: left;
  min-width: 33%;
}
.ant-upload.ant-upload-drag{
  background: none;
  position: inherit;
}
`
export default class Images extends React.Component {
  state = {
    value: this.props.value || [],
    loading: false
  }

  handleUpload = (e) =>{
    const{value} = this.state
    this.setState({
      loading: false,
      value:[...value, e.key]
    }, () => this.props.onChange(this.state.value))
  }

  handlePreview = (e) => {
    this.setState({previewVisible:true})
  }

  handleCancel = () => this.setState({ previewVisible: false })

  handleDeleteImage = (img) =>{
     this.setState({
        value: this.state.value.filter(url => url !== img)
      },() => this.props.onChange(this.state.value))
     } 

  render() {
    const {value, loading} = this.state
    const uploadButton = (
      <div className="ant-upload ant-upload-select ant-upload-select-picture-card"  style={{margin:5, width: 80, height: 80}}>
        <div className="ant-upload">
          <Icon type={loading ? 'loading' : 'plus'} />
          <div className="ant-upload-text">Add Images</div>
        </div>
      </div>
    )
    return (
      <Container>
        {value.map((img) => 
         <div className="image-product" style={  value.length > 2 ? {maxWidth: 'calc(100% / 3)'} : {maxWidth:'50%'} } key={img} >
         <div className="delete-action" size="small" onClick={() => this.handleDeleteImage(img)}>
           <Icon className="icon-action-delete" size="small" title="Delete file" type="close"></Icon>
         </div>
         <div className="img-item" >
           <S3Image src={img} style={{maxWidth:'100%',border:'1px solid #f2f2f2'}}/>
         </div>
       </div>
        )}
        <Upload onPreview={this.handlePreview} onProcess={e => {
          this.setState({loading: true})
        }} onUpload={this.handleUpload} fileList={false}>{uploadButton}</Upload>
      </Container>
    )
  }
}