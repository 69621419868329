export const INIT_APP = 'INIT_APP'
export const INIT_APP_2 = 'INIT_APP_2'
export const SET_DESTINATION = 'SET_DESTINATION'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_CURRENT_SITE = 'SET_CURRENT_SITE'
export const SET_ORDERS_FILTER = 'SET_ORDERS_FILTER'
export const RESET_ORDERS_FILTER = 'RESET_ORDERS_FILTER'
export const SET_SELLER_SITES = 'SET_SELLER_SITES'
export const SET_ORDER_ID = 'SET_ORDER_ID'
export const RESET_ORDER_ID = 'RESET_ORDER_ID'
export const SET_PRODUCT = 'SER_PRODUCT'