import React from 'react'
import { Form, message, List, Comment, Button, Tooltip, Avatar } from 'antd'
import gql from '../../api/gql'
import _ from 'lodash'
import TextArea from 'antd/lib/input/TextArea'
import moment from 'moment'
class OrderComments extends React.Component{
  state = {
    comments: [],
    submitting: false
  }

  componentDidMount() {
    this.loadComment()
  }

  loadComment(){
    const {order_id} = this.props
    const query = `query orderComment($order_id: Int!){
      OrderComments(order_id:$order_id){
        id created_at order_id author{first_name last_name} comment
      }
    }`
    gql.request(query,{order_id}).then(res =>{
      this.setState({
        comments: res.OrderComments || [],
      })
    })
    .catch(err =>{
      message["error"](_.get(err, "[0].message"));
    })
  }

  handleSubmit = (e) =>{
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values)=>{
      const {order_id} = this.props
      if(!err){
        if(values.comment !== ""){
        const mutation =`mutation createComment($order_id: Int!, $comment: String!){
          createComment(order_id: $order_id, comment: $comment){
            created_at order_id author{first_name last_name} comment
          }
        }`
        this.setState({submitting:true})
        gql.request(mutation,{
          order_id, comment: values.comment
        }).then(res =>{
          this.setState({submitting: false})
          this.props.form.resetFields()
          this.loadComment()
        })
      }}
    })
  }
  render () {
    const {comments, submitting} = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit}>
          <List
          className="comment-list"
          header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
          itemLayout="horizontal"
          dataSource={comments}
          renderItem={item =>(
            <li>
              <Comment
                author={`${item.author.first_name} ${item.author.last_name}`}
                avatar={item.avatar ? item.avatar : <Avatar style={{ backgroundColor: '#41c8f5' }}> {item.author.first_name.charAt(0)}{item.author.last_name.charAt(0)} </Avatar>  }
                datetime={
                  <Tooltip title={moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}>
                    <span>{moment(item.created_at).fromNow()}</span>
                  </Tooltip>
                }
                content={item.comment}
              />
            </li>
          )}
          />
          <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px 0px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Form.Item>
              {
                getFieldDecorator('comment',{
                  initialValue: ""
                })(<TextArea rows={3} />)
              }
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" loading={submitting} type="primary">
                Add Comment
              </Button>
            </Form.Item>
          </div>
      </Form>
    )
  }
}

export default Form.create({form_id: 'order_comments_form'})(OrderComments)