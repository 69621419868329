import React from "react";
import { Tabs } from 'antd';
import ContentToolGPT from "./ContentToolGPT";
import ImageToolGPT from "./ImageToolGPT";
const DashboardGPT = () => {
    const { TabPane } = Tabs;
  return (
      <div className="card-container">
          <Tabs type="card">
              <TabPane tab="Content" key="1">
                <ContentToolGPT />
              </TabPane>
              <TabPane tab="Images" key="2">
                <ImageToolGPT />
              </TabPane>
          </Tabs>
      </div>
  )
}

export default DashboardGPT;