import React from 'react'
import { Form, } from 'antd';

let id = 0
class ItemProduct extends React.Component {
  state = {
    data: [],
    items: []
  }
  remove = k => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { keys, names } = values;
        console.log('Received values of form: ', values);
        console.log('Merged values:', keys.map(key => names[key]));
      }
    });
  };

  handleOk = e => {
    console.log(e);
    // this.state.data
    console.log(this.state.data)
  };

  handleCancel = e => {
    console.log(e);
    // this.state.data

  };
  onSubmit = () => {
    const value = this.props.form.getFieldsValue(["name", "slug", "option"])
    if (this.props.onSubmit) {
      this.props.onSubmit(value)
    }
  }
  onChange = (value) => {
    console.log(`selected ${value}`);
  }

  // onBlur = () => {
  //   this.setState({ onShow: true })
  // }

  onFocus = () => {
    this.setState({ onShow: true })
  }
  onSearch = (val) => {
    console.log('search:', val);
  }

  addItem = () => {
    console.log('addItem');
    const { items } = this.state;
    this.setState({
      items: [...items, this.state.data],
      data: ""
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form
    getFieldDecorator('keys', { initialValue: [] });
    // const formItems = keys.map((k, index) => (
    //   <Form.Item
    //     {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
    //     label={index === 0 ? 'Choose' : ''}
    //     required={false}
    //     key={k}
    //   >
    //     {getFieldDecorator(`names[${k}]`, {
    //       validateTrigger: ['onChange', 'onBlur'],
    //       rules: [
    //         {
    //           required: true,
    //           whitespace: true,
    //           message: "Please input passenger's name or delete this field.",
    //         },
    //       ],
    //     })(<Input placeholder="passenger name" style={{ width: '60%', marginRight: 8 }} />)}
    //     {keys.length > 1 ? (
    //       <Icon
    //         className="dynamic-delete-button"
    //         type="minus-circle-o"
    //         onClick={() => this.remove(k)}
    //       />
    //     ) : null}
    //   </Form.Item>
    // ));
    return (
       <div>
         
      </div>
    );
  }
}

export default Form.create({ name: 'item-product-type-form' })(ItemProduct) 