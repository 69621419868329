import React, {useState} from "react";
import axios from "axios";
import { Input, Spin } from 'antd';
import {URL_API_GPT_API} from "../../config";
const ContentToolGPT = () => {
    const [content, setContent] = useState("");
    const [loading, setLoading] = useState(false);
    const { Search } = Input;
    const handleSearch = async (value) => {
        if(value !== ""){
            setLoading(true);
            await axios.post(
                `${URL_API_GPT_API}/chatgpt`,
                {
                    "text": value
                }
            ).then((res) => {
                setContent(res.data.message);
            }).catch((error) => {
                console.log(error);
            });

            setLoading(false);
        }
    }
    return (
        <div>
            <Search
                className={"gpt-input-search"}
                disabled={loading}
                placeholder="Enter the content to be processed."
                onSearch={handleSearch}
                style={{ width: "100%", height: "40px", fontSize: "20px" }}
                loading={loading}
                enterButton={"Submit"}
            />
            <Spin spinning={loading} tip="Please wait...">
            <div style={{
                marginTop: 20
            }}>
                <Input.TextArea placeholder={"Results are displayed here."} style={{
                    height: "70vh",
                }} value={content} />
            </div>
            </Spin>
        </div>
    )
}

export default ContentToolGPT;