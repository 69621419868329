import React from 'react'
import { connect } from 'react-redux'
import { Select, Divider, Icon, Modal, Form, Input, message } from 'antd'
import rest from '../../api/rest'

class Tags extends React.Component {
  state = {
    loading: false,
    hasError: false,
    addTag: false,
    tagName: '',
    tags: [],
    value: []
  }

  componentDidMount() {
    this.getTags()
  }

  getTags = (page = 1) => {
    const { currentSite } = this.props
    rest.request("get", currentSite.id, `/wp-json/wc/v3/products/tags?page=${page}&per_page=100`).then(tags => {
      this.setState({
        tags: [...this.state.tags, ...tags]
      }, () => {
        if (tags.length === 100) {
          this.getTags(page + 1)
        }
      })
    })
  }

  addTag = e => {
    e.preventDefault()
    const { tags, tagName } = this.state
    const { currentSite } = this.props
    if (tagName === '') {
      this.setState({ hasError: true })
      message['error']('Please input tag name')
      return
    }
    if (tags.find(tag => tag.name === tagName)) {
      message['warning']('Tag already exists')
      return
    }
    this.setState({ loading: true })
    rest.request("post", currentSite.id, "/wp-json/wc/v3/products/tags", {
      name: tagName
    }).then(newTag => {
      this.setState({
        tags: [...this.state.tags, newTag],
        addTag: false,
        loading: false,
        value: [...this.state.value, `${newTag.id}`]
      }, this.handleOnChange)
    })
  }

  handleOnChange = () => {
    const { onChange } = this.props
    if (typeof onChange === 'function') {
      onChange(this.state.value)
    }
  }

  render() {
    const { tags, loading, hasError, value } = this.state
    return (
      <div>
        <Select
          value={value}
          onChange={value => {
            this.setState({ value }, this.handleOnChange)
          }}
          placeholder="Select tags"
          showArrow={true}
          allowClear={true}
          style={{ width: '100%' }}
          mode="multiple"
          optionFilterProp="children"
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ padding: '4px 8px 8px', cursor: 'pointer' }}
                onMouseDown={e => e.preventDefault()}
                onClick={(e) => {
                  e.preventDefault()
                  this.setState({ addTag: true })
                }}>
                <Icon type="plus" /> Add item
              </div>
            </div>
          )}>
          {tags.map(tag => <Select.Option key={tag.id}>{tag.name}</Select.Option>)}
        </Select>
        <Modal
          visible={this.state.addTag}
          confirmLoading={loading}
          onCancel={() => this.setState({ addTag: false })}
          onOk={this.addTag}
          title="New Tag">
          <Form.Item label="Tag" className={hasError ? 'has-error' : ''}>
            <Input value={this.state.tagName} onChange={e => this.setState({ hasError: false, tagName: e.target.value })} />
          </Form.Item>
        </Modal>
      </div>
    )
  }
}

export default connect(state => ({
  currentSite: state.app.currentSite
}))(Tags)