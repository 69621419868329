import React, { Component } from 'react'
import gql from '../../api/gql'
import { Form, Select, Row, Col, Divider } from 'antd'
import ImportTemplateList from './ImportTemplateList';

class MappingOrderFileds extends Component {
  state = {
    loading: false,
    isShipping: true,
    settings: null,
    key: ""
  }

  onSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.key && this.state.key !== "order_import_setting_Woo") {
          this.updateSettings(values)
        }
        this.props.onSubmit(values)
      }
    })
  }
  updateSettings = (values) => {
    const { site_id } = this.props
    const { key } = this.state
    const mutation = `mutation CreateSetting($input: NewSetting!){
      createSiteSetting(input: $input){
        site_id
        name
        value
      }
    }`
    this.setState({ loading: true })
    gql.request(mutation, {
      input: {
        site_id: parseInt(site_id),
        name: key,
        value: JSON.stringify(values),
      }
    })
      .then(res => {
      })
      .catch(err => {
        console.log(err)
      })
  }
  mappingField = (fields) => {
    const { headers } = this.props
    const fieldList = fields.split(',')
    let result =null
    if (headers && headers.length > 0 && fieldList.length>0) {
      for (let i = 0; i < fieldList.length; i++) {
        const field = fieldList[i];
        const index = headers.indexOf(field)
        if (index >= 0) {
          result = index
          console.log("index: ", index, " Field: ", field)
          break
        }
      }
    }
    return result
  }
  render() {
    const { headers, site_id } = this.props
    const { settings } = this.state
    const { getFieldDecorator } = this.props.form
    console.log("settings", settings)
    return (
      <div>
        <Form id="mapping-order" onSubmit={this.onSubmit}>
          <Row gutter={24} type="flex">
            <Col className="gutter-row" span={24}>
              <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <div style={{ marginRight: 10, lineHeight: "28px" }}>Please select Template</div>
                <ImportTemplateList site_id={site_id} onSourceChange={template => {
                  if (template && template.length > 0) {
                    this.setState({ key: template[0].name })
                    if (template[0].value) {
                      const settings = JSON.parse(template[0].value)
                      this.setState({ settings: settings })
                    }
                  }
                }} />
              </div>
              <Divider type="horizontal" style={{ marginTop: 10, marginBottom: 10 }} />
            </Col>
            <Col className="gutter-row" span={10}>
              <h3>General Info</h3>
            </Col>
            <Col className="gutter-row" span={14} style={{ textAlign: "right" }}>
              <h4 style={{ color: "red" }}>* Please select field from CSV to map with system. Review carefully before clicking 'OK'</h4>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Order Id">
                {getFieldDecorator("origin_id", {
                  initialValue: settings ? headers[settings.origin_id] ? settings.origin_id : null : this.mappingField("Order Number"),
                  rules: [{
                    required: true,
                    message: "Please select order origin id field!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select order origin id field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              {/* <Form.Item label="Order Price">
                {getFieldDecorator("price", {
                  initialValue: settings ? headers[settings.price] ? settings.price : null : this.mappingField("Item Cost"),
                  rules: [{
                    required: true,
                    message: "Please select order price field!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select order price field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item> */}
              <Form.Item label="Order Item">
                {getFieldDecorator("item", {
                  initialValue: settings ? headers[settings.item] !== undefined ? settings.item : null : this.mappingField("Item #,Item"),
                  rules: [{
                    required: true,
                    message: "Please select order item field!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select order item field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Order Quantity">
                {getFieldDecorator("quantity", {
                  initialValue: settings ? headers[settings.quantity] ? settings.quantity : null : this.mappingField("Quantity,Qty"),
                  rules: [{
                    required: true,
                    message: "Please select order quantity field!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select order quantity field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
              <h3>Product Info</h3>
              </Form.Item>
              <Form.Item label="Product Id">
                {getFieldDecorator("product_origin_id", {
                  initialValue: settings ? headers[settings.product_origin_id] ? settings.product_origin_id : null : this.mappingField("Product Id,ProductID"),
                  rules: [{
                    required: true,
                    message: "Please select product field!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select product field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Product Title">
                {getFieldDecorator("product_title", {
                  initialValue: settings ? headers[settings.product_title] ? settings.product_title : null : this.mappingField("Product Name,Product Title"),
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select product field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Product Variation">
                {getFieldDecorator("product_variation", {
                  initialValue: settings ? headers[settings.product_variation] ? settings.product_variation : null : this.mappingField("Product Variation,Variations"),
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select product field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
              <h3>Customer Info</h3>
              </Form.Item>
              <Form.Item label="Customer Email">
                {getFieldDecorator("customer_email", {
                  initialValue: settings ? headers[settings.customer_email] ? settings.customer_email : null : this.mappingField("Email (Billing),Email"),
                  rules: [{
                    required: true,
                    message: "Please select email!"
                  }]
                })(
                  <Select style={{ width: 250, marginRight: 10 }} placeholder="Please select email field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Customer Phone">
                {getFieldDecorator("customer_phone", {
                  initialValue: settings ? headers[settings.customer_phone] ? settings.customer_phone : null : this.mappingField("Phone (Billing),Phone Number"),
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select phone field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              {/* <Form.Item label="Order Status">
                {getFieldDecorator("status", {
                  initialValue: settings ? headers[settings.status] ? settings.status : null : this.mappingField("Order Status"),
                  rules: [{
                    required: true,
                    message: "Please select order status field!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select order status field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item> */}
              <Form.Item label="Shipping Address1">
                {getFieldDecorator("shipping_address1", {
                  initialValue: settings ? headers[settings.shipping_address1] ? settings.shipping_address1 : null : this.mappingField("Address 1 (Shipping),Address 1&2 (Shipping),Address 1"),
                  rules: [{
                    required: true,
                    message: "Please select shipping address1!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select shipping address1 field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Shipping Address2">
                {getFieldDecorator("shipping_address2", {
                  initialValue: settings ? headers[settings.shipping_address2] ? settings.shipping_address2 : null : this.mappingField("Address 2 (Shipping),Address 2"),
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select shipping address2 field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Shipping city">
                {getFieldDecorator("shipping_city", {
                  initialValue: settings ? headers[settings.shipping_city] ? settings.shipping_city : null : this.mappingField("City (Shipping),City"),
                  rules: [{
                    required: true,
                    message: "Please select shipping city!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select shipping city field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Shipping country">
                {getFieldDecorator("shipping_country", {
                  initialValue: settings ? headers[settings.shipping_country] ? settings.shipping_country : null : this.mappingField("Country Name (Shipping),Country Code (Shipping),Country"),
                  rules: [{
                    required: true,
                    message: "Please select shipping country!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select shipping country field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Shipping Country Code">
                {getFieldDecorator("shipping_country_code", {
                  initialValue: settings ? headers[settings.shipping_country_code] ? settings.shipping_country_code : null : this.mappingField("Country Code (Shipping),Country"),
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select shipping country code field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Shipping First Name">
                {getFieldDecorator("shipping_first_name", {
                  initialValue: settings ? headers[settings.shipping_first_name] ? settings.shipping_first_name : null : this.mappingField("First Name (Shipping),Customer First Name"),
                  rules: [{
                    required: true,
                    message: "Please select shipping first name!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select shipping first name field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Shipping Last Name">
                {getFieldDecorator("shipping_last_name", {
                  initialValue: settings ? headers[settings.shipping_last_name] ? settings.shipping_last_name : null : this.mappingField("Last Name (Shipping),Customer Last Name"),
                  rules: [{
                    required: true,
                    message: "Please select shipping last name!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select shipping last name field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Shipping Zip">
                {getFieldDecorator("shipping_zip", {
                  initialValue: settings ? headers[settings.shipping_zip] ? settings.shipping_zip : null : this.mappingField("Postcode (Shipping),ZipCode"),
                  rules: [{
                    required: true,
                    message: "Please select shipping zip!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select shipping zip field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Shipping State">
                {getFieldDecorator("shipping_state", {
                  initialValue: settings ? headers[settings.shipping_state] ? settings.shipping_state : null : this.mappingField("State Name (Shipping),State Code (Shipping),State"),
                  rules: [{
                    required: true,
                    message: "Please select shipping state!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select shipping state field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Shipping State Code">
                {getFieldDecorator("shipping_state_code", {
                  initialValue: settings ? headers[settings.shipping_state_code] ? settings.shipping_state_code : null : this.mappingField("State Code (Shipping),State"),
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select shipping state code field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Shipping Company">
                {getFieldDecorator("shipping_company", {
                  initialValue: settings ? headers[settings.shipping_company] ? settings.shipping_company : null : this.mappingField("Company (Shipping),Company"),
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select shipping company field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            {/* <Col className="gutter-row" span={12}>
              <h3>Billing Address</h3>
              <Form.Item label="Billing Address1">
                {getFieldDecorator("billing_address1", {
                  initialValue: settings ? headers[settings.billing_address1] ? settings.billing_address1 : null : this.mappingField("Address 1 (Billing)"),
                  rules: [{
                    required: true,
                    message: "Please select billing address1!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select billing address1 field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Billing Address2">
                {getFieldDecorator("billing_address2", {
                  initialValue: settings ? headers[settings.billing_address2] ? settings.billing_address2 : null : this.mappingField("Address 2 (Billing)"),
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select billing address2 field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Billing city">
                {getFieldDecorator("billing_city", {
                  initialValue: settings ? headers[settings.billing_city] ? settings.billing_city : null : this.mappingField("City (Billing)"),
                  rules: [{
                    required: true,
                    message: "Please select billing city!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select billing city field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Billing country">
                {getFieldDecorator("billing_country", {
                  initialValue: settings ? headers[settings.billing_country] ? settings.billing_country : null : this.mappingField("Country Name (Billing),Country Code (Billing)"),
                  rules: [{
                    required: true,
                    message: "Please select billing country!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select billing country field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Billing Country Code">
                {getFieldDecorator("billing_country_code", {
                  initialValue: settings ? headers[settings.billing_country_code] ? settings.billing_country_code : null : this.mappingField("Country Code (Billing)"),
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select billing country code field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Billing First Name">
                {getFieldDecorator("billing_first_name", {
                  initialValue: settings ? headers[settings.billing_first_name] ? settings.billing_first_name : null : this.mappingField("First Name (Billing)"),
                  rules: [{
                    required: true,
                    message: "Please select billing first name!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select billing first name field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Billing Last Name">
                {getFieldDecorator("billing_last_name", {
                  initialValue: settings ? headers[settings.billing_last_name] ? settings.billing_last_name : null : this.mappingField("Last Name (Billing)"),
                  rules: [{
                    required: true,
                    message: "Please select billing last name!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select billing last name field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Billing Zip">
                {getFieldDecorator("billing_zip", {
                  initialValue: settings ? headers[settings.billing_zip] ? settings.billing_zip : null : this.mappingField("Postcode (Billing)"),
                  rules: [{
                    required: true,
                    message: "Please select billing zip!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select billing zip field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Billing State">
                {getFieldDecorator("billing_state", {
                  initialValue: settings ? headers[settings.billing_state] ? settings.billing_state : null : this.mappingField("State Name (Billing),State Code (Billing)"),
                  rules: [{
                    required: true,
                    message: "Please select billing state!"
                  }]
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select billing state field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Billing State Code">
                {getFieldDecorator("billing_state_code", {
                  initialValue: settings ? headers[settings.billing_state_code] ? settings.billing_state_code : null : this.mappingField("State Code (Billing)"),
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select billing state code field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="Billing Company">
                {getFieldDecorator("billing_company", {
                  initialValue: settings ? headers[settings.billing_company] ? settings.billing_company : null : this.mappingField("Company (Billing)"),
                })(
                  <Select showSearch optionFilterProp="children" style={{ width: 250, marginRight: 10 }} placeholder="Please select billing company field">
                    {headers && headers.map((header, index) => (
                      <Select.Option key={index} value={index}>{header}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col> */}
            {/* <Col className="gutter-row" span={12}>
              <h3>Shiping Address</h3>
              
            </Col> */}
          </Row>
        </Form>

      </div>
    )
  }
}

export default Form.create({ name: "mapping-order" })(MappingOrderFileds);