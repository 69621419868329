import React, {useEffect, useState} from "react";
import {Tooltip, Button, Divider, Icon, Select, Modal, message, Spin} from "antd";
import gql from "../../api/gql";
import Axios from "axios";
import { URL_API_GET_BRAND_NAME, URL_API_CLONE_PRODUCT_TO_V1 } from "../../config";
import { get } from "lodash";

const CloneProductV2ToV1 = ({currentUser, id}) => {
    const [visible, setVisible] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [site, setSite] = useState("");
    const [status, setStatus] = useState(false);
    // console.log(currentUser, id);

    const {Option} = Select;

    useEffect(() => {
        const roles = currentUser.roles.map(e => {return e.name});

        const findAdmin = roles.find(e => e === 'Administrator');

        if(findAdmin){
            setStatus(true);
        }else{
            const findSeller = roles.find(e => e === 'Seller');
            if(findSeller){
                setStatus(true);
            }else{
                setStatus(false);
            }
        }
    }, []);

    const showModal = async () => {
        setVisible(true);
        setLoading(true);
        const getBrandName = await Axios.get(URL_API_GET_BRAND_NAME).then(res => {
            const roles = currentUser.roles.map(e => {return e.name});

            const findAdmin = roles.find(e => e === 'Administrator');
            // setData([]);
            let brandNames = [];
            const payload = res.data.payload;
            if(findAdmin){
                payload.forEach((e, index) => {
                    e.brand_names.forEach(i => {
                        const item = {
                            name: i,
                            value: i
                        }
                        brandNames = [...brandNames, item];
                    });
                });
                setData(brandNames)
            }else{
                const findSeller = roles.find(e => e === 'Seller');
                if(findSeller){
                    const findBrandByEmail = payload.find(e => e.user_email === currentUser.email);
                    if(findBrandByEmail){
                        findBrandByEmail.brand_names.forEach(i => {
                            const item = {
                                name: i,
                                value: i
                            }
                            brandNames = [...brandNames, item];
                        });
                        setData(brandNames);
                    }
                }
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })

    }

    const handleOk = async () => {
        setLoadingSubmit(true);
        const token = localStorage.getItem('_token');
        const headers = { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };
        const reQuest = await Axios.post(URL_API_CLONE_PRODUCT_TO_V1, {
            "product_id": id,
            "brand_name": site
        }, {headers}).then(res => {
            setLoadingSubmit(false);
            setVisible(false);
            message.success(res.data.message);
        }).catch(error => {
            setLoadingSubmit(false);
            message.error('An error occurred, please try again!')
        })

    }

    const handleCancel = () => {
        setVisible(false);
    }

    const handleChangeSelectSite = (value) => {
        setSite(value)
    }


    return (
        <>
        <Modal
            title="Select Store Amazon"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={loadingSubmit}
            okButtonProps={{disabled: site == ""}}
        >
            <Spin spinning={loading} tip="Loading...">
            <Select style={{ width: "100%" }} onChange={handleChangeSelectSite}>
                {data.map((e, index) => (
                    <Option key={index} value={e.value}>{e.name}</Option>
                ))}
            </Select>
            </Spin>
        </Modal>
        {status && 
            <>
                <Tooltip title="Push to Amazon">
                    <Button onClick={showModal} size="small">
                        <Icon type="amazon" />
                    </Button>
                </Tooltip>
                <Divider type="vertical" />
            </>
        }
        </>
    )
}


export default CloneProductV2ToV1;