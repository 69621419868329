import React, { Component } from 'react';
import { Form, notification, Input, Button, Card, Divider } from 'antd'
import gql from '../../api/gql'
import _ from 'lodash'
import { history } from '../../history'
import { setCurrentUser } from "../../actions"
import TemplateConfigs from './TemplateConfigs';
import { connect } from 'react-redux';
import SupplierSelect from '../supplier/SupplierSelect';


class ExportTemplateForm extends Component {
  state = {
    loading: false,
    templateById: {},
    defaultExportColumns: []
  }

  componentDidMount() {
    const {id} = this.props.match.params
    id && this.templateById(id)
    const q = `query defaultExportColumns{defaultExportColumns{name type value}}`
    gql.request(q).then((res) => {
      this.setState({
        defaultExportColumns: res.defaultExportColumns,
      })
    })
  }

  templateById(id) {
    const q = `query exportTemplate{
      exportTemplate( id: ${id} ){
        name
        user_ids
        author_id
        data{
          name
          type
          value
        }
      }
    }`
    gql.request(q).then((res) => {
      this.setState({
        templateById: res.exportTemplate
      })
    })
  }

  onhandleSubmit = e => {
    const {id} = this.props.match.params
    const isClone = this.props.match.path === "/export-templates/clone/:id"
    this.props.form.validateFieldsAndScroll((err, values) => {
      values.user_ids = values.user_ids ? values.user_ids : []
      e.preventDefault()
      if (!err) {
        if (!id || isClone) {
          const mutation = `mutation createExportTemplate($input: ExportTemplateInput!){
            createExportTemplate(input: $input)
          }`
          this.setState({ loading: true })
          gql.request(mutation, {
            input: values
          }).then(res => {
            notification['success']({ message: isClone ? "Clone template successfully!" : "Export template successfully created!" })
            this.setState({
              loading: false
            })
            history.push('/export-templates')
          })
          .catch(err => {
            notification['error']({
              message: _.get(err, '[0].message')
            })
            this.setState({ loading: false })
          })
        } else {
          const mutation = `mutation updateExportTemplate($id: Int! $input: ExportTemplateInput!){
            updateExportTemplate(id: $id input: $input)
          }`
          this.setState({ loading: true })
          gql.request(mutation, {
            id,
            input: values
          }).then(res => {
            notification['success']({ message: "Export template successfully updated!" })
            this.setState({
              loading: false
            })
            history.push('/export-templates')
          })
          .catch(err => {
            notification['error']({
              message: _.get(err, '[0].message')
            })
            this.setState({ loading: false })
          })
        }
      }
    })
  }

  render() {
    const { loading, templateById, defaultExportColumns } = this.state
    const { currentUser } = this.props
    const {id} = this.props.match.params
    const { getFieldDecorator, } = this.props.form
    const supplierByTemplate = templateById.user_ids && templateById.user_ids.map(user_id => user_id.toString())
    const isSupplier = currentUser.roles.find(role => ['Supplier'].includes(role.name))
    templateById.data = id ? templateById.data : defaultExportColumns.map(item=>({...item}))
    return (
      <Form onSubmit={this.onhandleSubmit}>
        <Card title="Detail" style={{ marginBottom: 20}}>
          <Form.Item label="Template Name">
            {
              getFieldDecorator('name', {
                initialValue: templateById ? templateById.name : "",
                rules: [
                  {
                    required: true,
                    message: "Template name is required."
                  }
                ]
              })(<Input />)
            }
          </Form.Item>
          {!isSupplier &&
          <Form.Item label="Supplier">
            {
              getFieldDecorator('user_ids',{ initialValue: supplierByTemplate })
              (
                <SupplierSelect />
              )}
          </Form.Item>}
          <Form.Item label="Template configs">
            {
              getFieldDecorator('data',
              {
                rules: [
                  {
                    required: true,
                    message: "Template configs is required."
                  }
                ],
                initialValue: templateById.data
              })
              (
                <TemplateConfigs defaultExportColumns={defaultExportColumns} byId={ id }/>
              )}
          </Form.Item>
        </Card>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}> {id ? "Save" : "Create Template"}</Button>
          <Divider type="vertical"/>
          <Button onClick={() => { history.push('/export-templates') }}>Cancel</Button>
        </Form.Item>
      </Form>
    );
  }
}

export default connect(
  state => {
    return {
      currentUser: state.app.currentUser
    };
  },
  { setCurrentUser }
)(Form.create({ name: "form" })(ExportTemplateForm));