import React from 'react'
import ListUserLevel from '../../components/userlevel/ListUserLevel'

 class UserLevelPage extends React.Component{
  render(){
    return (
      <div>
        <ListUserLevel/>
      </div>
    )
  }
}
export default UserLevelPage