import React from 'react'
import gql from '../../api/gql'
import { notification } from 'antd'
import _ from 'lodash'

class DetailNews extends React.Component{
  state={
    news: null,
    loading: false,
  }
  componentDidMount(){
    const {id} = this.props.match.params
    const query = `query newsById{
      newsById(id: ${id}){
        id
        title
        content
      }
    }`
    this.setState({loading: true})
    gql.request(query)
    .then(res => this.setState({news: res.newsById, loading: false}))
    .catch(err => {
      notification['error']({
      message: 'ERROR',
      description: _.get(err, '[0].message')
    })
    this.setState({ loading: false })
  })
  }
  render(){
    const {news} = this.state
    return(
      <div>
        <h2>{news? news.title: ''}</h2>
        <div dangerouslySetInnerHTML={{
            __html: news? news.content : ''
          }}></div>
      </div>
    )
  }
}

export default DetailNews