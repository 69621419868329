import React, { Fragment } from 'react'
import { Form, Button, Alert } from 'antd'
import gql from '../../api/gql'
import _ from 'lodash'
import TextArea from 'antd/lib/input/TextArea'
class FetchOrders extends React.Component {
  state = {
    comments: [],
    submitting: false,
    successOrders: [],
    faildOrders: []
  }

  componentDidMount() {

  }
 
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      const { site_id } = this.props
      if (!err) {
        if (values.orderIds !== "") {
          const orderIds = values.orderIds.split(',')
          if (orderIds.length > 0) {
            let failOrders = []
            orderIds.forEach(id => {
              if (id) {
                const mutation = `mutation fetchOrders($site_id: Int!, $order_id: String!){
                  fetchOrders(site_id: $site_id, order_id: $order_id)
                }`
                this.setState({ submitting: true })
                gql.request(mutation, {
                  site_id: site_id,
                  order_id: id.trim()
                }).then(res => {
                  this.setState({ submitting: false })
                  this.props.form.resetFields()
                  this.setState({})
                }).catch(err => {
                  this.setState({ submitting: false })
                  const mes=`${id}: ${_.get(err, '[0].message')}`
                  failOrders.push(mes)
                  this.setState({faildOrders: failOrders})
                  //console.log("this.state.faildOrders", this.state.faildOrders)
                  // notification['error']({
                  //   message: _.get(err, '[0].message')
                  // })
                })
              }
            });
          }
        }
      }
    })
  }
  render() {
    const { submitting } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item help="Enter list of order ids separated by comma. For example: 123456,654321,456789,987654">
            {
              getFieldDecorator('orderIds', {
                initialValue: "",
                rules: [
                  {
                    required: true,
                    message: "Please input order ids"
                  }
                ]
              })(<TextArea rows={3} />)
            }
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" loading={submitting} type="primary">
              Fetch Orders
          </Button>
          </Form.Item>
        </Form>

        {this.state.faildOrders && this.state.faildOrders.map((mess, i) =>
          <Alert message={mess} type="error" key={i} style={{marginTop:10, marginBottom:10}}></Alert>)
        }

        <span><i>*After fetching orders, close the form to refresh the order list.</i></span>
      </Fragment>

    )
  }
}

export default Form.create({ form_id: 'order_fetchorders_form' })(FetchOrders)