import React from 'react'
import { Table, Input, InputNumber, Button, Icon, Progress } from 'antd'
import { DndProvider, DragSource, DropTarget } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import styled from 'styled-components'
import Upload from '../Upload'
import S3Image from '../S3Image'
import _ from 'lodash'
import DesignPositionVariantsSelect from './DesignPositionVariantsSelect'

const SortTable = styled.div`
  table tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }
  table tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
`

const UploadPreview = styled.div`
  width: 100%;
  height: 50px;
  border: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    max-width: 100%;
    max-height: 100%;
  }
`
let dragingIndex = -1;

class BodyRow extends React.Component {
  render() {
    const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
    const style = { ...restProps.style, cursor: 'move' };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    );
  }
}

const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

export default class ProductTypePrintFiles extends React.Component {
  state = {
    data: [],
    editing: false,
    percent: [],
    uploading:[],
  }

  componentDidUpdate(prevProps){
      if(prevProps.value !== this.props.value){
          this.setState({ data: this.props.value})
      }
  }

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
        },
      }),
    );
  };

  handleDelete = record => {
    const {data} = this.state
    const newData = data.filter(el => !_.isEqual(el, record))
    this.setState({ data: newData }, () => this.props.onChange(newData))
  }


  render() {
    const {data, uploading, percent} = this.state
    const {variantActive} = this.props
    const columns = [
      {
        title: 'Mockup',
        dataIndex: 'mockup',
        key: 'mockup',
        width: 100,
        render: (mockup, file, index) => {
          return <Upload multiple={false} fileList={false}
            onProcess={e =>{
              const {uploading, percent} = this.state
              uploading[index]= true
              percent[index] = Math.round(e.loaded/e.total * 100)
              this.setState({
                uploading,
                percent,
              })
            }}
            onUpload={file => {
              const {data,uploading} = this.state
              data[index].mockup = file.key
              uploading[index] = false
              this.setState({data,uploading}, ()=>this.props.onChange(data))
            }}
          >
            <UploadPreview>
              {uploading[index] ? <Progress type="circle" percent={percent[index]} width={40} strokeColor="#1890ff"/>:
              (mockup ? <S3Image src={mockup}/> : <Icon type="plus"/>)}
            </UploadPreview>
          </Upload>
        }
      },
      {
        width: 220,
        key: "print_files",
        dataIndex: 'variant_ids',
        title: "Print Files",
        render: (variant_ids, print_file ,index) => {
            return(
              <DesignPositionVariantsSelect
                variants = {variantActive}
                value={variant_ids}
                onChange={selected_variants => {
                  const {data} = this.state;
                  data[index].variant_ids = selected_variants;
                  this.setState({data}, () => {
                    this.props.onChange(data);
                  });
                }}
              />
            );
        }

      },
      {
        title: 'Name',
        dataIndex: 'title',
        key: 'name',
        width: 200,
        render: (name, file, index) => <Input placeholder="Name" value={name} onChange={e => {
          const {data} = this.state
          data[index].title = e.target.value
          this.setState({data}, () => this.props.onChange(data))
        }}/>
      },
      {
        title: 'Size',
        dataIndex: '',
        key: 'size',
        width: 240,
        render: (_, {width, height}, index) => <div style={{display:'grid', gridTemplateColumns:'auto 20px auto', alignItems:'center'}}>
            <InputNumber placeholder="Width" style={{width:'100%'}} value={width} onChange={v => {
              const {data} = this.state
              data[index].width = v
              this.setState({data}, () => this.props.onChange(data))
            }}/>
            <div style={{textAlign:'center'}}>x</div>
              <InputNumber 
                placeholder="Height" 
                style={{ width: '100%' }} value={height} 
                onChange={v => {
                  const {data} = this.state
                  data[index].height = v
                  this.setState({data}, () => this.props.onChange(data))
                }}
              />
          </div>
      },
      {
        title: 'Note',
        width: 180,
        dataIndex: 'note',
        key: 'note',
        render: (note, file, index) => <Input placeholder="Note" value={note} onChange={e=>{
          const {data} = this.state
          data[index].note = e.target.value
          this.setState({data}, () => this.props.onChange(data))
        }}/>
      },
      {
        title: '',
        width: 50,
        render: (record) =>
          <Button size="small" type="link" onClick={() => this.handleDelete(record)}>
            <Icon type="delete" style={{ color: 'red' }} />
          </Button>
      }
    ]
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <SortTable>
        <DndProvider backend={HTML5Backend}>
          <Table
            scroll={{x:tableWidth}}
            columns={columns}
            dataSource={this.state.data}
            components={this.components}
            onRow={(record, index) => ({
              index,
              moveRow: this.moveRow,
            })}
            pagination={false}
            footer={()=><Button type="primary" icon="plus" style={{alignContent:"end"}} onClick={e => {
              e.preventDefault()
              data.push({
                title: '',
                width: null,
                height: null,
                note: '',
                variant_ids: []
              })
              this.setState({data}, () => this.props.onChange(data))
            }}>Add</Button>}
            rowKey={(row, index) => index}
          />
        </DndProvider>
      </SortTable>
    )
  }
}
