import rest from '../api/rest'
import _ from 'lodash'
import shortuuid from 'short-uuid'

export const Woocommerce = {
  async pushProduct(site_id, productTypes, product) {
    var attributes = []
    if (productTypes.length > 1) {
      attributes.push({
        name: 'Type',
        visible: true,
        variation: true,
        options: productTypes.map(product_type => product_type.title)
      })
    }
    product.variants.forEach(variant => {
      variant.attributes.forEach(attribute => {
        let index = _.findIndex(attributes, attr => attr.name === attribute.name)
        if (index !== -1) {
          attributes[index].options = _.union(attributes[index].options, [attribute.option])
        } else {
          attributes.push({
            name: attribute.name,
            visible: true,
            variation: true,
            options: [attribute.option]
          })
        }
      })
    })

    return new Promise(async (resolve, reject) => {
      //Create master product
      var wpProduct = await rest.request("post", site_id, "/wp-json/wc/v3/products", {
        type: 'variable',
        name: product.title,
        slug: product.permalink?product.permalink:null,
        description: product.description,
        attributes: attributes,
        images: product.images.map(image => ({ src: image.url })),
        categories: product.categories.map(cat => ({ id: parseInt(cat) })),
        tags: product.tags.map(tag => ({ id: parseInt(tag) })),
        meta_data: [{ key: 'pod_product_types', value: JSON.stringify(productTypes.map(type => type.id.toString())) }]
      }).then(createProduct => {
        return createProduct
      })

      //Create variants
      let variants = product.variants.map(variant => {
        let variant_attributes = variant.attributes.map(attr => {
          return {
            id: 0,
            name: attr.name,
            option: attr.option
          }
        })
        if (productTypes.length > 1) {
          variant_attributes = [{
            id: 0,
            name: 'Type',
            option: productTypes.find(type => type.id === variant.product_type_id).title,
          }, ...variant_attributes]
        }
        let variantImageIndex = variant.image ? _.findIndex(product.images, image => image.key === variant.image.key) : null
        return {
          regular_price: `${variant.retail_cost}`,
          sale_price: `${variant.sale_cost}`,
          meta_data: [{ key: 'pod_product_type_variant_id', value: variant.id.toString() }],
          sku: `${variant.sku}:${shortuuid.generate()}`,
          attributes: variant_attributes,
          ...(variantImageIndex !== null ? { image: { id: wpProduct.images[variantImageIndex].id } } : null)
        }
      })
      await rest.request("POST", site_id, `/wp-json/wc/v3/products/${wpProduct.id}/variations/batch`, {
        create: variants
      }).then(() => resolve(wpProduct)).catch(err => {
        reject(err)
      })
    })
  },

  async mapProduct(site_id, origin_id, productTypes, variations) {
    //Create product attributes
    var attributes = []
    if (productTypes.length > 1) {
      attributes.push({
        name: 'Type',
        visible: true,
        variation: true,
        options: productTypes.map(product_type => product_type.title)
      })
    }
    variations.forEach(variant => {
      variant.attributes.forEach(attribute => {
        let index = _.findIndex(attributes, attr => attr.name === attribute.name)
        if (index !== -1) {
          attributes[index].options = _.union(attributes[index].options, [attribute.option])
        } else {
          attributes.push({
            name: attribute.name,
            visible: true,
            variation: true,
            options: [attribute.option]
          })
        }
      })
    })

    //Update master product with new attributes
    await rest.request("PUT", site_id, `/wp-json/wc/v3/products/${origin_id}`, {
      meta_data: [{ key: 'pod_product_types', value: productTypes.map(type => type.id) }],
      attributes: attributes,
    }).catch(err => Promise.reject(err))

    //Get old variants
    let update = await rest.request("GET", site_id, `/wp-json/wc/v3/products/${origin_id}/variations?per_page=100&status=publish`).then(variants => {
      return variants.map(v => ({ id: v.id, status: 'private' }))
    }).catch(err => {
      return Promise.reject(err)
    })
    let create = variations.map(variant => {
      let variant_attributes = variant.attributes.map(attr => {
        return {
          id: 0,
          name: attr.name,
          option: attr.option
        }
      })
      if (productTypes.length > 1) {
        variant_attributes = [{
          id: 0,
          name: 'Type',
          option: productTypes.find(type => type.id === variant.product_type_id).title,
        }, ...variant_attributes]
      }
      //let variantImageIndex = variant.image ? _.findIndex(product.images, image => image.key === variant.image.key) : null
      return {
        regular_price: `${variant.retail_cost}`,
        sale_price: `${variant.sale_cost}`,
        meta_data: [{ key: 'pod_product_type_variant_id', value: variant.id }],
        sku: `${variant.sku}:${shortuuid.generate()}`,
        attributes: variant_attributes
      }
    })
    //Batch update variants
    await rest.request("POST", site_id, `/wp-json/wc/v3/products/${origin_id}/variations/batch`, {
      create: create,
      update: update
    }).catch(err => { return Promise.reject(err) })
    return Promise.resolve(origin_id)
  }
}