import React from 'react'
import {connect} from 'react-redux'
import { Button } from 'antd'
import { history } from '../../history'

class ProductTypeToolbar extends React.Component{
  render(){
    const isAdmin= this.props.currentUser.roles.find(el => ['Administrator', 'Listing'].includes(el.name))
    return(
      <>
      {isAdmin&&(
        <Button type="primary" icon="plus" onClick={() => history.push('/admin/product-types/add')}>Add New</Button>
      )}
      </>
    )
  }
}

export default connect(
  state => ({
    currentUser: state.app.currentUser
  })
)(ProductTypeToolbar)