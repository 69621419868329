import React from 'react'
import gql from '../../api/gql'
import {Form, Input, notification, Select, Divider, Icon, Modal, message } from 'antd'
import _ from 'lodash'
const { Option } = Select;
class CategoriesSelect extends React.Component {
  state = {
    loading: false,
    categories: [],
    visible: false,
    value: this.props.value || []
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    this.getCategories()
  }

  getCategories = () => {
    const query = `query categories{
      categories(filter:{status:true, limit: 9999}){
        hits{
          id title
        }
      }
    }`
    gql.request(query).then(res => {
      this.setState({
        categories: res.categories.hits || []
      })
    }).catch(err => {
      message["error"](_.get(err, "[0].message"));
      this.setState({ loading: false })
    });
  }

  createCategory = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        var query = `mutation createCategory($input: NewCategory!){
        createCategory(input: $input){
          id title
        }
      }`
      }
      this.setState({ loading: true })
      gql.request(query, {
        input: {title: values.title}
      }).then(res => {
        notification['success']({
          message: 'Category created'
        })
        const {categories, value} = this.state
        this.setState({ 
          loading: false,  
          visible: false, 
          categories: [...categories, res.createCategory],
          value: [...value, res.createCategory.id]
        }, ()=>this.props.onChange(this.state.value))
      }).catch(err => {
        notification['error']({
          message: _.get(err, '[0].message')
        })
        this.setState({ loading: false })
      })
    })
  }

  render() {
    const { categories, loading } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <div>
        <Select
          placeholder="Select..."
          value={this.state.value}
          onChange={value => this.setState({value}, ()=>this.props.onChange([value]))}
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{ padding: '4px 8px', cursor: 'pointer' }}
                onMouseDown={e => e.preventDefault()}
                onClick={this.showModal}
              >
                <Icon type="plus" /> Add
              </div>
            </div>
          )}
        >
          {
            categories.map(item => (
              <Option key={item.id}>{item.title}</Option>
            ))
          }
        </Select>
        <Modal
          title="New Category"
          visible={this.state.visible}
          onOk={this.createCategory}
          okText="Save"
          onCancel={this.handleCancel}
          confirmLoading={loading}
        >
          <Form onSubmit={this.createCategory}>
            <Form.Item label="Name">
            {getFieldDecorator('title', {rules: [{required: true}]})(
              <Input placeholder="Category Name" onKeyPress={e=>{
                if (e.charCode === 13) {
                  e.preventDefault()
                  this.createCategory(e)
                }
              }}/>)
            }
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}
export default Form.create({ name: 'categoriesSelect' })(CategoriesSelect)