import React, {Component} from 'react'
import Customers from '../../components/customers/Customers'
import CustomerFiler from '../../components/customers/CustomerFilter'

export default class OrdersPage extends Component{
  state = {
    filter: null
  }

  render() {
    return (
      <div>
        <CustomerFiler onChange={filter => this.setState({filter})}/>
        <Customers filter={this.state.filter}/>
      </div>
    )
  }
}