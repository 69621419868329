import React from 'react'
import { Table, Icon, Button, Select, Input } from 'antd'
import { DndProvider, DragSource, DropTarget } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import styled from 'styled-components'
import _ from 'lodash'

const SortTable = styled.div`
  table tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }
  table tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
`

let dragingIndex = -1;

class BodyRow extends React.Component {

  render() {
    const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
    const style = { ...restProps.style, cursor: 'move' };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += ' drop-over-downward';
      }
      if (restProps.index < dragingIndex) {
        className += ' drop-over-upward';
      }
    }

    return connectDragSource(
      connectDropTarget(<tr {...restProps} className={className} style={style} />),
    );
  }
}


const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow),
);

export default class TemplateConfigs extends React.Component {

  state = {
    changeInput: null,
    defaultExportColumns: [],
    data: []
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ data: this.props.value })
    }
  }

  handleDelete = record => {
    const {data} = this.state
    const newData = data.filter(el => el.name !== record.name)
    this.setState({ data: newData }, () => this.props.onChange(newData))
  }

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  footer = () => {
    return(
      <Button
        icon="plus"
        onClick={(e)=>{
          e.preventDefault()
          const {data} = this.state
          data.push({name: "", type:"custom_value", value: ''})
          this.setState({data})
        }}
        type="primary"
      >
        Add Column
      </Button>
    )
  }

  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
        },
      }),
      () => {
        this.props.onChange(this.state.data)
      }
    );
  };

  changeValue = (value, index, column_name) => {
    const { data } = this.state;
    data[index][column_name] = value;
    this.setState({data});
    this.props.onChange(data);
  }

  render() {
    const { data } = this.state
    const { defaultExportColumns } = this.props
    const columns = [
      {
        title: '',
        key: 'drag',
        width: 30,
        render: () => <Icon type="drag" />
      },
      {
        title: 'Column Name',
        dataIndex: 'name',
        key: 'name',
        width: 220,
        render: (name, _, index) => <div key={index}>
          <Input
            placeholder="Name"
            value={name}
            onChange={e => this.changeValue(e.target.value, index, 'name')}
          />
        </div>
      },
      {
        title: "Type",
        dataIndex: 'type',
        width: 160,
        key: "type",
        render: (type, record, index) =>
        <Select key={index} value={type ? type : "defined_value" } onChange={(value) => this.changeValue(value, index, 'type')}>
          <Select.Option value="defined_value">Default Value</Select.Option>
          <Select.Option value="custom_value">Custom Value</Select.Option>
        </Select>
      },
      {
        title: 'Value',
        width: 220,
        dataIndex: 'value',
        key: 'value',
        render: (v,record,index) => {
          return (
            <div key={index}>
              {record.type === "custom_value" ?
              <Input
                value={v}
                onChange={e => this.changeValue(e.target.value, index, 'value')}
              /> :
              <Select value={v} onChange={(value) => this.changeValue(value, index, 'value')}>
                {defaultExportColumns.map(el => <Select.Option key={el.value} value={el.value}>{el.name}</Select.Option>)}
              </Select>
              }
            </div>
          )
        }
      },
      {
        title: '',
        width: 40,
        render: (record) =>
          <Button size="small" type="link" onClick={() => this.handleDelete(record)}>
            <Icon type="delete" style={{ color: 'red' }} />
          </Button>
      }
    ];
    const tableWidth = _.sum(columns.map(c => c.width))
    return (
      <div className="attribute">
        <SortTable>
          <DndProvider backend={HTML5Backend}>
            <Table
              scroll={{ x: tableWidth }}
              footer={this.footer}
              columns={columns}
              dataSource={ data }
              components={this.components}
              onRow={(row, index) => ({
                index,
                moveRow: this.moveRow,
              })}
              rowKey={(row, index) => index}
              pagination={false}

            />
          </DndProvider>
        </SortTable>
      </div>
    );
  }
}
