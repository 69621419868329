import React from 'react'
import { Form, Tabs, Card, Input, Button, notification } from 'antd'
import gql from '../../api/gql'
import * as _ from "lodash"

const settings = [
  {
    key: "welcome_email",
    title: "Welcome"
  },
  {
    key: "password_recovery",
    title: "Password"
  },
]
class AccountSettingsForm extends React.Component {
  state = {
    initSetting: []
  }
  componentDidMount = () => {
    const query = `query getAppSettings($input: [String!]){
      getAppSettings(input: $input){
        name
        value
      }
    }`
    const input = settings.map(el => el.key).reduce((init,item) => ([...init,item+"_subject",item+"_message"]),[])
    this.setState({loading: true})
    gql.request(query, {input: input})
      .then( res => 
        this.setState({initSetting: res.getAppSettings || [], loading: false}))
      .catch(err =>{
        notification["error"]({
          message: 'Error',
          description: _.get(err, "[0].message")
        })
        this.setState({loading: false})
      })
  }
  render() {
    const { loading, form, onSubmit } = this.props
    const { initSetting } = this.state
    const setting = initSetting&&initSetting.reduce((init,item)=> ({...init, [item.name]: item.value}),{})
    const { getFieldDecorator } = form
    return (
      <Form
      loading={loading}
        onSubmit = { e => {
          e.preventDefault()
          this.props.form.validateFields((err, values) => {
            if(!err){
              onSubmit(values)
            } else {
              notification.error({message: 'Form is invalid'})
            }
          })
        }}
      >
        <Tabs tabPosition='left'>
        {settings.map((el) => (
              <Tabs.TabPane tab={el.title} key={el.key}>
                <Card title={`${el.title} settings`}>
                <Form.Item label="Subject">
                  {getFieldDecorator(`${el.key}_subject`, {
                    rules: [
                      {
                        required: true,
                        message: "Please enter subject"
                      }
                    ],
                    initialValue: setting? setting[`${el.key}_subject`] : ''
                  })(<Input />)}
                </Form.Item>
                <Form.Item label="Message">
                  {getFieldDecorator(`${el.key}_message`, {
                    rules: [
                      {
                        required: true,
                        message: "Please enter message"
                      }
                    ],
                    initialValue: setting? setting[`${el.key}_message`] : ''
                  })(<Input.TextArea rows={5} />)}
                </Form.Item>
                </Card>
              </Tabs.TabPane>
            ))}
        </Tabs>
        <Form.Item style={{textAlign:'right', marginTop: 20}}>
          <Button icon="save" type='primary' htmlType='submit'>Save</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default Form.create({ name: 'form' })(AccountSettingsForm)